import { useEffect, useState } from 'react';
import { getEmployee, searchEmployees, updateAccessControl } from '../../actions/employeeAction';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../layout/layout';
import Dropdown from '../../components/dropdown';

const AccessControl = ({ user }) => {
  const dispatch = useDispatch();

  const employees = useSelector((state) => state?.employee?.searchEmployees) || [];
  const company = useSelector((state) => state.company?.company);
  const singleEmployee = useSelector((state) => state?.employee?.current);
  const mainUser = useSelector((state) => state?.employee);

  const [searchName, setSearchName] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [currentUserPermission, setCurrentPermission] = useState(singleEmployee?.permission || []);
  console.log('singleEmployee: ', singleEmployee);
  console.log('searchName: ', searchName);

  useEffect(() => {
    setCurrentPermission(singleEmployee?.permission)
  }, [singleEmployee])

  useEffect(() => {
    if (selectedBranch !== null) {
       dispatch(searchEmployees(selectedBranch));
    }
  }, [selectedBranch, dispatch]);

  const handleInputChange = (inputValue) => {
    setSearchName(inputValue); // Update searchName state
  };

  const handleBranchChange = (selectedOption) => {
    setSelectedBranch(selectedOption ? selectedOption.value : null);
    setSelectedEmployee(null);
    setSearchName('');
  };

  const handleDropdownChange = (selectedOption) => {
    console.log('selectedOption: ', selectedOption);
    if (selectedOption) {
      // resetFormState();
      // setIsFormVisible(false);
      setSelectedEmployee(selectedOption);
      dispatch(getEmployee(selectedOption.value));
    }
  };

  const handlePermissionChange = (permissionIndex, event) => {
    console.log(permissionIndex, event.target.checked);
    const isCheck = event.target.checked;

    if (isCheck) {
      setCurrentPermission([...currentUserPermission, permissionIndex]);
    } else {
      setCurrentPermission(currentUserPermission?.filter((index) => index !== permissionIndex));
    }
  };

  const handleApplyChanges = () => {
    const inputValue = {
      employeeId: singleEmployee?._id,
      permissions: currentUserPermission
    };
    dispatch(updateAccessControl(inputValue));
  };

  const filteredEmployeeOptions = employees
    .filter((employee) => employee.fullName?.toLowerCase().includes(searchName.toLowerCase()))
    .map((employee) => ({
      label: employee.fullName,
      value: employee._id
    }));

  const branchOptions = company?.branches?.map((branch) => ({
    label: branch?.branchName,
    value: branch?.branchCode
  })) || [];

  const employeeOptionsKey = filteredEmployeeOptions.map((option) => option.value).join('-');

  console.log('currentUserPermission: ', currentUserPermission);
  console.log('mainUser: ', mainUser);

  const permissionList = (pmsIndex) => {
    let permissionData = [];

    if (pmsIndex === 0) {
      permissionData = mainUser?.frontEnd;
    } else {
      permissionData = mainUser?.backEnd;
    }

    console.log('permissionData: ', permissionData);

    return permissionData?.map((p, index) => {
      return (
        <div key={index} className="w-full h-16 flex justify-between items-center border-b-2">
          {currentUserPermission && (
            <div className="flex justify-between h-full w-full items-center">
              <div className="text-lg font-medium text-black">{p?.label}</div>
              <input
                className="me-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-gray-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-gray-300 after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-violet-400 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                type="checkbox"
                onChange={(value) => handlePermissionChange(p.value, value)}
                checked={currentUserPermission.includes(p?.value)}
                role="switch"
                id="general"
              />
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <Layout pageTitle={'Access Control'} page={'Employees'} subPage={'Access Control'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full overflow-y-auto w-full">
        <div className="w-full">
          {/* <h3 className="text-4xl font-semibold text-black pb-5">Employees</h3> */}
          <div className="flex justify-between">
            <div className="flex w-3/4 gap-x-4">
              <Dropdown
                value={branchOptions.find((option) => option?.value === selectedBranch) || ''}
                onChange={handleBranchChange}
                options={branchOptions}
                isClearable={true}
                isSearchable={true}
                placeholder="Select Branch"
                name="branch"
                id="branch"
              />
              <Dropdown
                key={employeeOptionsKey}
                options={filteredEmployeeOptions}
                value={selectedEmployee}
                onChange={handleDropdownChange}
                onInputChange={handleInputChange}
                placeholder="Search Employees"
                name="employee"
                id="employee"
                menuPlacement="bottom"
                isClearable={true}
              />
            </div>
            <button
              type="button"
              onClick={handleApplyChanges}
              className="h-12 w-36 bg-primary hover:bg-primary/90 text-white font-semibold rounded-md cursor-pointer disabled:bg-gray-400"
              disabled={!selectedEmployee}
            >
              Apply Changes
            </button>
          </div>
          {selectedEmployee && (
            <div className="flex flex-wrap gap-3 mt-10 w-full">
              <div className=" flex justify-center items-center w-full">
                <div className="w-full px-10">
                  <div className="py-10 flex flex-col shadow-2xl rounded-xl p-5">
                    <div className='text-2xl font-bold text-black mb-5'>Permissions</div>
                    {permissionList(0)}
                    {permissionList(1)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AccessControl;
