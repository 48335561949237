import React from 'react';
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import RootLayout from '../layout/rootLayout';
import Login from '../screens/authentication/login/login';
import Company from '../screens/settings/company/company';
import IRASCallBackUrl from '../iras/irasCallBackUrl';
import Employee from '../screens/employees/employees/employees';
import AddEmployee from '../screens/employees/addEmployee/addEmployee';
import EditEmployee from '../screens/employees/editEmployee/editEmployee';
import Miscellaneous from '../screens/settings/miscellaneous/miscellaneous';
import ApplyLeave from '../screens/employees/applyLeave/applyLeave';
import LeaveApplications from '../screens/employees/leaveApplications/leaveApplications';
import GeneratePayroll from '../screens/payroll/generatePayroll/generatePayroll';
import ForgotPassword from '../screens/authentication/forgotPassword/forgotPassword';
import VerifyForgotPasswordOTP from '../screens/authentication/forgotPassword/verifyForgotPasswordOTP';
import CreateNewPassword from '../screens/authentication/forgotPassword/createNewPassword';
import PayrollEmployeeViewEdit from '../screens/payroll/generatePayroll/payrollEmployeeViewEdit';
import HistoryPayroll from '../screens/payroll/historyPayroll/historyPayroll';
import Payslip from '../screens/payroll/payslip/payslip';
import PayslipView from '../screens/payroll/payslip/payslipView';
import HistoryPayrollEmployeeView from '../screens/payroll/historyPayroll/historyPayrollEmployeeView';
import SubmitClaim from '../screens/employees/submitClaim/submitClaim';
import ClaimSubmissions from '../screens/employees/claimSubmissions/claimSubmissions';
import Timesheets from '../screens/payroll/timesheets/timesheets';
import AccessControl from '../screens/accessControl/accessControl';
import Companies from '../screens/company/companies';
import ManagerApplyLeave from '../screens/manager/leaves/managerApplyLeave';
import ManagerApplyMedicalLeave from '../screens/manager/medicalLeaves/managerApplyMedicalLeave';
import ManagerSubmitClaim from '../screens/manager/claims/managerSubmitClaim';
import ManagerPayslip from '../screens/manager/payslip/managerPayslip';
import ManagerPayslipView from '../screens/manager/payslip/managerPayslipView';
import ManagerLeaveApplications from '../screens/manager/employees/leaveApplications/managerLeaveApplications';
import ManagerClaimSubmissions from '../screens/manager/employees/claimApplications/managerClaimSubmissions';
import ApplyMedicalLeave from '../screens/employees/applyMedicalLeave/applyMedicalLeave';
import UserApplyLeave from '../screens/user/leaves/userApplyLeave';
import UserPayslip from '../screens/user/payslip/userPayslip';
import UserPayslipView from '../screens/user/payslip/userPayslipView';
import UserApplyMedicalLeave from '../screens/user/medicalLeaves/userApplyMedicalLeave';
import UserSubmitClaim from '../screens/user/claims/userSubmitClaim';
import MedicalApplications from '../screens/employees/medicalApplications/medicalApplications';
import ManagerMedicalApplications from '../screens/manager/employees/medicalApplications/managerMedicalApplications';
import Dashboard from '../screens/dashboard/dashboard';
import AdminRoute from './adminRoutes';
import ManagerRoute from './managerRoutes';
import UserRoute from './userRoutes';
import ManagerDashboard from '../screens/manager/dashboard/managerDashboard';
import UserDashboard from '../screens/user/dashboard/userDashboard';
import AccessDenied from '../components/accessDenied';
import ManagerTimesheets from '../screens/manager/timesheets/managerTimesheets';
import UserTimesheets from '../screens/user/timesheets/userTimesheets';
import ManagerTimesheetApplications from '../screens/manager/employees/timesheetApplications/managerTimesheetApplications';
import TimesheetApplications from '../screens/payroll/timesheetApplications/timesheetApplications';
import LeavesReport from '../screens/reports/leavesReport/leavesReport';
import MedicalsReport from '../screens/reports/medicalsReport/medicalsReport';
import ClaimsReport from '../screens/reports/claimsReport/claimsReport';
import TimesheetsReport from '../screens/reports/timesheetsReport/timesheetsReport';
import ApplyNoPayLeave from '../screens/employees/applyNoPayLeave/applyNoPayLeave';
import ManagerApplyNoPayLeave from '../screens/manager/noPayLeave/managerApplyNoPayLeave';
import UserApplyNoPayLeave from '../screens/user/noPayLeave/userApplyNoPayLeave';
import NoPayLeaveApplications from '../screens/employees/noPayLeaveApplications/noPayLeaveApplications';
import ManagerNoPayLeaveApplications from '../screens/manager/employees/noPayLeaveApplications/noPayLeaveApplications';
import NoPayLeavesReport from '../screens/reports/noPayLeavesReport/noPayLeavesReport';
import PastEmployeesReport from '../screens/reports/pastEmployeesReport/pastEmployeeReport';
import SuperAdminCreateOrganization from '../screens/superadmin/superadminCreateOrganization/superadminCreateOrganization';
import SuperAdminCreateCompany from '../screens/superadmin/superadminCreateCompany/superadminCreateCompany';
import SuperAdminCreateAdmin from '../screens/superadmin/superadminCreateAdmin/superadminCreateAdmin';
import SuperAdminLogin from '../screens/superadmin/superadminlogin/superadminLogin';
import SuperAdminRoute from './superadminRoutes';
import CurrentEmployeesReport from '../screens/reports/currentEmployeeReport/currentEmployeeReport';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-password-verify" element={<VerifyForgotPasswordOTP />} />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/superadmin-login" element={<SuperAdminLogin />} />
        

        <Route element={<SuperAdminRoute />}>
          <Route path="/superadmin/create-new-organization" element={<SuperAdminCreateOrganization />} />
          <Route path="/superadmin/create-new-company" element={<SuperAdminCreateCompany />} />
          <Route path="/superadmin/create-new-admin" element={<SuperAdminCreateAdmin />} />
        </Route>

        <Route element={<AdminRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings/companies" element={<Companies />} />
          <Route path="/settings/company" element={<Company />} />
          <Route path="/settings/company/:id" element={<Company />} />
          <Route path="/settings/miscellaneous" element={<Miscellaneous />} />
          <Route path="/employees" element={<Employee />} />
          <Route path="/employees/add-employee" element={<AddEmployee />} />
          <Route path="/employees/:id" element={<EditEmployee />} />
          <Route path="/ais_submission_callback" element={<IRASCallBackUrl />} />
          <Route path="/employees/apply-leave" element={<ApplyLeave />} />
          <Route path="/employees/leave-applications" element={<LeaveApplications />} />
          <Route path="/employees/no-pay-leave-applications" element={<NoPayLeaveApplications />} />
          <Route path="/employees/apply-medical-leave" element={<ApplyMedicalLeave />} />
          <Route path="/employees/medical-leave-applications" element={<MedicalApplications />} />
          <Route path="/employees/apply-no-pay-leave" element={<ApplyNoPayLeave />} />
          <Route path="/payroll/generate-payroll" element={<GeneratePayroll />} />
          <Route path="/payroll/edit-payroll-employee" element={<PayrollEmployeeViewEdit />} />
          <Route path="/payroll/history-payroll" element={<HistoryPayroll />} />
          <Route path="/payroll/history-payroll/view-employee" element={<HistoryPayrollEmployeeView />} />
          <Route path="/payroll/payslip" element={<Payslip />} />
          <Route path="/payroll/payslip-view" element={<PayslipView />} />
          <Route path="/employees/submit-claim" element={<SubmitClaim />} />
          <Route path="/employees/claim-submissions" element={<ClaimSubmissions />} />
          <Route path="/employees/submit-timesheet" element={<Timesheets />} />
          <Route path="/employees/timesheet-submissions" element={<TimesheetApplications />} />
          <Route path="/access-control" element={<AccessControl />} />
          <Route path="/reports/leaves-report" element={<LeavesReport />} />
          <Route path="/reports/no-pay-leaves-report" element={<NoPayLeavesReport />} />
          <Route path="/reports/medicals-report" element={<MedicalsReport />} />
          <Route path="/reports/claims-report" element={<ClaimsReport />} />
          <Route path="/reports/timesheets-report" element={<TimesheetsReport />} />
          <Route path="/reports/past-employees-report" element={<PastEmployeesReport />} />
          <Route path="/reports/current-employees-report" element={<CurrentEmployeesReport />} />
        </Route>


        <Route element={<ManagerRoute />}>
          <Route path="/manager/dashboard" element={<ManagerDashboard />} />
          <Route path="/manager/apply-leave" element={<ManagerApplyLeave />} />
          <Route path="/manager/apply-medical-leave" element={<ManagerApplyMedicalLeave />} />
          <Route path="/manager/submit-claim" element={<ManagerSubmitClaim />} />
          <Route path="/manager/apply-no-pay-leave" element={<ManagerApplyNoPayLeave />} />
          <Route path="/manager/payslip" element={<ManagerPayslip />} />
          <Route path="/manager/payslip-view" element={<ManagerPayslipView />} />
          <Route path="/manager/employees/leave-applications" element={<ManagerLeaveApplications />} />
          <Route path="/manager/employees/no-pay-leave-applications" element={<ManagerNoPayLeaveApplications/>} />
          <Route path="/manager/employees/claim-submissions" element={<ManagerClaimSubmissions />} />
          <Route path="/manager/employees/medical-applications" element={<ManagerMedicalApplications />} />
          <Route path="/manager/timesheets" element={<ManagerTimesheets />} />
          <Route path="/manager/employees/timesheet-submissions" element={<ManagerTimesheetApplications />} />
        </Route>

        <Route element={<UserRoute />}>
          <Route path="/user/dashboard" element={<UserDashboard />} />
          <Route path="/user/apply-leave" element={<UserApplyLeave />} />
          <Route path="/user/payslip" element={<UserPayslip />} />
          <Route path="/user/payslip-view" element={<UserPayslipView />} />
          <Route path="/user/apply-medical-leave" element={<UserApplyMedicalLeave />} />
          <Route path="/user/submit-claim" element={<UserSubmitClaim />} />
          <Route path="/user/apply-no-pay-leave" element={<UserApplyNoPayLeave />} />
          <Route path="/user/timesheets" element={<UserTimesheets />} />
        </Route>
      </Route>
    </>
  )
);

export default router;
