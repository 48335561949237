import { useState } from 'react';
import { Edit2 } from 'iconsax-react';
import EditPublicHoliday from './editPublicHoliday';
import { format } from 'date-fns';

const PublicHolidayDetails = ({ company }) => {

  const [editIndex, setEditIndex] = useState(null);

  // Sort the public holidays by start date (earliest to latest)
  const sortedHolidays = company?.publicHolidays?.slice().sort((a, b) => a.startDate - b.startDate) || [];

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-bold text-black text-2xl">Public Holidays</h3>
        </div>
        <div className="flex flex-wrap gap-3 mt-5">
          <div className={`p-6 relative bg-white shadow-lg flex-grow ${'basis-[450px]'} rounded-2xl`}>
            {!editIndex && (
              <div>
                {/* Display Public Holidays */}
                <div className="mt-5">
                  {/* <h4 className="text-base text-black font-bold mb-2">Public Holidays:</h4> */}
                  {sortedHolidays.length > 0 ? (
                    <ul className="list-disc pl-5">
                      {sortedHolidays.map((holiday, index) => (
                        <li key={index} className="mb-2">
                          <div className="text-sm font-normal text-black">
                            <strong>{holiday.ph}</strong>
                            <br />
                            <span>
                              Date: {format(new Date(holiday.startDate), 'dd MMM yyyy, EEEE')}
                            </span>
                            <br />
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="text-sm font-normal text-black/40">
                      No public holidays available.
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="flex flex-row gap-x-1 absolute right-4 top-6">
              <button
                onClick={() => setEditIndex(true)}
                type="button"
                className="hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center"
              >
                <Edit2 color="#16a34a" variant="Bold" size={20} />
              </button>
            </div>
            {editIndex && (
              <EditPublicHoliday
                company={company}
                onClose={() => setEditIndex(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicHolidayDetails;
