import axios from 'axios';
import { ToastBar } from '../components/toastbar';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const fetchNotificationsSuccess = (data) => ({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: data
});

export const markAsReadSuccess = (notification) => ({
    type: MARK_AS_READ_SUCCESS,
    payload: notification
});

export const fetchNotifications = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${apiUrl}/notification/get-notifications`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                dispatch(fetchNotificationsSuccess(response.data));
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
        }
    };
};

export const markAsRead = (id) => {
    console.log('read id: ', id);
    return async (dispatch) => {
        try {
            const response = await axios.patch(
                `${apiUrl}/notification/notifications/${id}`,
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            );
            if (response.status === 200) {
                dispatch(markAsReadSuccess(response.data));
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
        }
    };
};

export const createApplyLeaveNotification = (notification, leaveHistoryId) => {
    console.log('create apply leave noti');
    return async (dispatch) => {
        try {
            const requestBody = {
                ...notification,
                leaveHistoryId,
            };

            const response = await axios.post(`${apiUrl}/notification/create-apply-leave-notification`, requestBody, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createApproveLeaveNotification = (leaves) => {
    console.log('create approve leave noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-approve-leave-notification`, leaves, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createRejectLeaveNotification = (leaves) => {
    console.log('create reject leave noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-reject-leave-notification`, leaves, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

const formDataToObject = (formData) => {
    const object = {};
    formData.forEach((value, key) => {
        object[key] = value;
    });
    return object;
};

export const createApplyMedicalLeaveNotification = (notification, medicalHistoryId) => {
    console.log('create apply medical noti');
    return async (dispatch) => {

        try {

            const plainObjectNotification = formDataToObject(notification);

            const requestBody = {
                ...plainObjectNotification,
                medicalHistoryId,
            };

            const response = await axios.post(`${apiUrl}/notification/create-apply-medical-leave-notification`, requestBody, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createApproveMedicalLeaveNotification = (medicals) => {
    console.log('create approve medical noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-approve-medical-leave-notification`, medicals, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createRejectMedicalLeaveNotification = (medicals) => {
    console.log('create reject medical noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-reject-medical-leave-notification`, medicals, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createSubmitClaimNotification = (notification, claimHistoryId) => {
    console.log('create submit claim noti');
    return async (dispatch) => {

        try {

            const plainObjectNotification = formDataToObject(notification);

            const requestBody = {
                ...plainObjectNotification,
                claimHistoryId,
            };

            const response = await axios.post(`${apiUrl}/notification/create-submit-claim-notification`, requestBody, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createApproveClaimNotification = (medicals) => {
    console.log('create approve claim noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-approve-claim-notification`, medicals, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createRejectClaimNotification = (medicals) => {
    console.log('create reject medical noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-reject-claim-notification`, medicals, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createSubmitTimesheetNotification = (employeeId, companyId, notification, timesheetHistoryId) => {
    console.log('create timesheet noti');
    return async (dispatch) => {
        try {
            const requestBody = {
                ...notification,
                timesheetHistoryId,
                employeeId,
                companyId
            };

            const response = await axios.post(`${apiUrl}/notification/create-submit-timesheet-notification`, requestBody, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createApproveTimesheetNotification = (timesheets) => {
    console.log('create approve timesheet noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-approve-timesheet-notification`, timesheets, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createRejectTimesheetNotification = (timesheets) => {
    console.log('create reject timesheet noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-reject-timesheet-notification`, timesheets, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createApplyNoPayLeaveNotification = (notification, noPayLeaveHistoryId) => {
    console.log('create apply leave noti');
    return async (dispatch) => {
        try {
            const requestBody = {
                ...notification,
                noPayLeaveHistoryId,
            };

            const response = await axios.post(`${apiUrl}/notification/create-apply-no-pay-leave-notification`, requestBody, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createApproveNoPayLeaveNotification = (leaves) => {
    console.log('create approve leave noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-approve-no-pay-leave-notification`, leaves, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createRejectNoPayLeaveNotification = (leaves) => {
    console.log('create reject leave noti');
    return async (dispatch) => {
        try {

            const response = await axios.post(`${apiUrl}/notification/create-reject-no-pay-leave-notification`, leaves, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createCancelLeaveNotification = (notification) => {
    console.log('create cancel leave noti');
    return async (dispatch) => {
        try {
            const requestBody = notification

            console.log('requestBody: ', requestBody)

            const response = await axios.post(`${apiUrl}/notification/create-cancel-leave-notification`, requestBody, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            if (response.status === 200) {
                console.log(response.data);
                dispatch(fetchNotifications());
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const createCancelMedicalNotification = (notification) => {
  console.log('create cancel leave noti');
  return async (dispatch) => {
      try {
          const requestBody = notification

          console.log('requestBody: ', requestBody)

          const response = await axios.post(`${apiUrl}/notification/create-cancel-medical-notification`, requestBody, {
              headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
              }
          });
          if (response.status === 200) {
              console.log(response.data);
              dispatch(fetchNotifications());
          }
      } catch (error) {
          if (error.response && error.response.status === 500) {
              console.log(error.response.data.errorMsg[0]);
              ToastBar.error(error.response.data.errorMsg[0]);
          } else {
              // Handle other errors
              console.log(error.message);
              ToastBar.error(error.message);
          }
          return { error: error.message };
      }
  };
};

export const createCancelClaimNotification = (notification) => {
  console.log('create cancel claim noti');
  return async (dispatch) => {
      try {
          const requestBody = notification

          console.log('requestBody: ', requestBody)

          const response = await axios.post(`${apiUrl}/notification/create-cancel-claim-notification`, requestBody, {
              headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
              }
          });
          if (response.status === 200) {
              console.log(response.data);
              dispatch(fetchNotifications());
          }
      } catch (error) {
          if (error.response && error.response.status === 500) {
              console.log(error.response.data.errorMsg[0]);
              ToastBar.error(error.response.data.errorMsg[0]);
          } else {
              // Handle other errors
              console.log(error.message);
              ToastBar.error(error.message);
          }
          return { error: error.message };
      }
  };
};

export const createCancelNoPayLeaveNotification = (notification) => {
  console.log('create cancel leave noti');
  return async (dispatch) => {
      try {
          const requestBody = notification

          console.log('requestBody: ', requestBody)

          const response = await axios.post(`${apiUrl}/notification/create-cancel-no-pay-leave-notification`, requestBody, {
              headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
              }
          });
          if (response.status === 200) {
              console.log(response.data);
              dispatch(fetchNotifications());
          }
      } catch (error) {
          if (error.response && error.response.status === 500) {
              console.log(error.response.data.errorMsg[0]);
              ToastBar.error(error.response.data.errorMsg[0]);
          } else {
              // Handle other errors
              console.log(error.message);
              ToastBar.error(error.message);
          }
          return { error: error.message };
      }
  };
};

export const createDeleteTimesheetNotification = (notification) => {
  console.log('create cancel leave noti');
  return async (dispatch) => {
      try {
          const requestBody = notification

          console.log('requestBody: ', requestBody)

          const response = await axios.post(`${apiUrl}/notification/create-delete-timesheet-notification`, requestBody, {
              headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
              }
          });
          if (response.status === 200) {
              console.log(response.data);
              dispatch(fetchNotifications());
          }
      } catch (error) {
          if (error.response && error.response.status === 500) {
              console.log(error.response.data.errorMsg[0]);
              ToastBar.error(error.response.data.errorMsg[0]);
          } else {
              // Handle other errors
              console.log(error.message);
              ToastBar.error(error.message);
          }
          return { error: error.message };
      }
  };
};