import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications, markAsRead } from '../actions/notificationAction';
// import { SocketContextProvider } from '../context/socketContext';
import useListenNotifications from '../reducers/listenNotifications';
import NotificationsList from './notificationList';
import { useNavigate } from 'react-router-dom';

const Notifications = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [unreadCount, setUnreadCount] = useState(0);

  const notifications = useSelector((state) => state.notifications.notifications);

  useEffect(() => {
    const unreadCount = notifications?.filter((notification) => !notification.isRead).length;
    setUnreadCount(unreadCount);
  }, [notifications]);

  console.log('Notifications: ', notifications);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  useListenNotifications();

  const handleNotificationClick = (notification) => {
    console.log('Notification clicked:', notification);  // Add this
    console.log('notification:', notification);

    // if (notification.type == 'leave') {
        console.log("LEAVE")
      dispatch(markAsRead(notification?._id));
      const activityId = notification?.activityId
      navigate(notification?.link, { state: { activityId: activityId, senderId: notification?.senderId?._id, receiverId: notification?.receiverId } });
      onClose();
   // }
    // if (notification.type === 'interview') {
    //   console.log("INTERVIEW: ", notification)
    //   dispatch(markAsRead(notification._id));
    //   const jobId = notification?.jobId
    //   navigate(notification?.link, { state: { jobId } });
    //   //navigate
    // }
    // if (notification.type === 'applyJob') {
    //   dispatch(markAsRead(notification._id));
    //   navigate(notification?.link, { state: { candidateId: notification?.senderId?._id } });
    // }
    // if (notification.type === 'message') {
    //   dispatch(markAsRead(notification._id));
    //   navigate(notification?.link, { state: { receiverID: notification?.senderId?._id } });
    // }
  };

  console.log(unreadCount);

  return (
    <div className="fixed top-0 right-0 mt-16 bg-white rounded-lg shadow-lg w-[350px] md:w-96 z-50">
      <div className="px-4 py-2 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-lg font-bold">Notifications</h2>
        <button className="text-gray-500" onClick={onClose}>
          &times;
        </button>
      </div>
      <div className="max-h-[400px] overflow-y-auto w-full">
        <NotificationsList notifications={notifications} onNotificationClick={handleNotificationClick} />
      </div>
    </div>
  );
};

export default Notifications;
