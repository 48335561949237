import { useEffect, useRef, useState } from 'react';
import { addBulkEmployee, getAllEmployees } from '../../../actions/employeeAction';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeList from './employeeList';
import SkeletonLoader from '../../../components/skeletonLoader';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../layout/layout';
import { DocumentUpload, SearchNormal1 } from 'iconsax-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import EmployeePersonalDetails_Template from '../../../assets/templates/EmployeePersonalDetails_Template.xlsx';
import * as XLSX from 'xlsx';
import { employeeDetailsMapping } from '../../../constant/excelMappingConst';
import CompanyDropdown from '../../../components/companyDropdown';
import empty from '../../../assets/images/empty.png';

const Employee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const company = useSelector((state) => state.company?.company);
  const companies = useSelector((state) => state.company?.companies || []);
  const allEmployees = useSelector((state) => state?.employee?.allEmployees);

  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(company?.companyName || '');
  const [companyValues, setCompanyValues] = useState('');

  //console.log(companies)
  console.log('company: ', company);

  const fileInputRef = useRef(null);

  useEffect(() => {
    dispatch(getAllCompanies());
    dispatch(getAllEmployees({ companyId: company?._id }));
    setLoading(false);
  }, [dispatch]);

  const companyOptions =
    companies?.map((company) => ({
      companyId: company?._id,
      label: company?.companyName,
      value: company?.companyName
    })) || [];

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    setCompanyValues(selectedOption);
    if (selectedValue) {
      setSelectedCompany(selectedValue);
      console.log(selectedOption?.companyId);
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
      dispatch(getAllEmployees({ companyId: selectedOption?.companyId }));
    }
  };

  const excelDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const local_date = new Date(date_info.getTime() + date_info.getTimezoneOffset() * 60000);

    return local_date;
  };

  const mapKeys = (obj, keyMap) => {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = keyMap[key] || key;
      let value = obj[key];

      if (newKey.toLowerCase().includes('date') || newKey.toLowerCase().includes('dob')) {
        if (typeof value === 'number') {
          value = excelDateToJSDate(value);
        }
      }

      acc[newKey] = value;
      return acc;
    }, {});
  };

  const uploadFileHandle = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const convertedData = jsonData.map((item) => {
          let mappedItem = mapKeys(item, employeeDetailsMapping);

          mappedItem.salaryDetails = {
            basicSalary: mappedItem.basicSalary,
            grossSalary: mappedItem.grossSalary,
            hourlyRate: mappedItem.hourlyRate,
            currency: mappedItem.currency,
            employeeCPF: mappedItem.employeeCPF,
            employeeCPFPercentage: mappedItem.employeeCPFPercentage,
            employerCPF: mappedItem.employerCPF,
            employerCPFPercentage: mappedItem.employerCPFPercentage,
            cpf: mappedItem.cpf === 'Yes' ? 1 : 0,
            ot: mappedItem.ot === 'Yes' ? 1 : 0,
            bank: mappedItem.bank,
            bankAccountNum: mappedItem.bankAccountNum,
            payMethod: mappedItem.payMethod,
            totalCPFContribution: parseFloat(mappedItem.employeeCPF || 0) + parseFloat(mappedItem.employerCPF || 0)
          };

          mappedItem.workingHourDetails = {
            workHoursType: mappedItem?.workHoursType
          }

          if (mappedItem?.workHoursType === 'Hourly' && mappedItem?.salaryDetails){
            delete mappedItem.salaryDetails.basicSalary
          } else if (mappedItem?.workHoursType === 'Fixed' && mappedItem?.salaryDetails){
            delete mappedItem.salaryDetails.hourlyRate
          }

          delete mappedItem.basicSalary;
          delete mappedItem.grossSalary;
          delete mappedItem.hourlyRate;
          delete mappedItem.currency;
          delete mappedItem.employeeCPF;
          delete mappedItem.employeeCPFPercentage;
          delete mappedItem.employerCPF;
          delete mappedItem.employerCPFPercentage;
          delete mappedItem.cpf;
          delete mappedItem.ot;
          delete mappedItem.bank;
          delete mappedItem.bankAccountNum;
          delete mappedItem.workHoursType;

          return mappedItem;
        });

        console.log('convertedData: ', convertedData);

        dispatch(addBulkEmployee(convertedData, company._id));
      };

      reader.readAsBinaryString(file);
    }
  };

  console.log('allEmployees: ', allEmployees);

  return (
    <Layout pageTitle={'Employee Details'} page={'Employees'} subPage={'Employees'}>
      <div className="flex flex-col justify-center items-start w-full overflow-auto">
        {loading ? (
          <SkeletonLoader length={8} />
        ) : (
          <div className="w-full space-y-4">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Select Company</label>
              {companyOptions.length > 0 && (
                <CompanyDropdown
                  value={companyOptions.find((option) => option.value === selectedCompany)}
                  onChange={handleCompanyChange}
                  options={companyOptions}
                  isClearable={false}
                  placeholder="Select Company"
                  name="allowanceType"
                  id="allowanceType"
                />
              )}
            </div>
            {company?.companyName ? (
              <div className="bg-white p-6 rounded-2xl">
                <div className="pb-5 flex flex-row justify-between">
                  <h3 className="text-2xl font-bold text-black pb-5">Employee Details</h3>
                  <div className="flex flex-wrap gap-x-3 items-center">
                    <div onClick={() => fileInputRef.current.click()} className="cursor-pointer" title="Upload file">
                      <DocumentUpload color="#555555" variant="Bold" size={40} />
                    </div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={uploadFileHandle}
                      style={{ display: 'none' }}
                    />
                    <a
                      href={EmployeePersonalDetails_Template}
                      download="EmployeePersonalDetails_Template.xlsx"
                      className="cursor-pointer"
                      title="Download template"
                    >
                      <FontAwesomeIcon icon={faFileExcel} size="2xl" color="#3CA455" />
                    </a>
                    <div className="relative max-w-xs h-12">
                      <input
                        type="text"
                        // value={value}
                        // onChange={onChange}
                        placeholder="Search Employees"
                        className="w-full pl-10 pr-4 h-12 border border-transparent rounded-3xl focus:outline-none focus:border-primary bg-grayLight shadow-lg"
                      />
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        {/* <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                        <SearchNormal1 color="#000000" variant="Outline" size={15} />
                      </div>
                    </div>
                    <button
                      onClick={() => navigate('/employees/add-employee', { state: { company: company } })}
                      className="h-12 w-36 bg-purple1 hover:bg-purple1/90 text-whiten font-semibold rounded-xl text-sm"
                    >
                      + Add Employee
                    </button>
                  </div>
                </div>
                <div className="w-full bg-white">
                  {allEmployees?.length > 0 ? (
                    <EmployeeList allEmployees={allEmployees} company={company} />
                  ) : (
                    <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                      <img src={empty} alt="empty data" />
                      <div className="mt-5 text-purple1 font-bold text-xl">No Employees</div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                <img src={empty} alt="empty data" />
                <div className="mt-5 text-purple1 font-bold text-xl">No Company Selected</div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Employee;
