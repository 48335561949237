import { useFormik } from 'formik';
import { employeeBonusSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import DateDropdown3 from '../../../components/dateDropdown3';
import { monthOptions } from '../../../constant/dropdownConstOptions';
import { updateBonus } from '../../../actions/bonusActions';

const EditBonus = ({ bonus, bonusOptions, onClose, employee }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      _id: bonus?._id,
      bonusId: bonus?.bonusId || '',
      bonusType: bonus?.bonusType || '',
      bonusCode: bonus?.bonusCode || '',
      bonusAmount: bonus?.bonusAmount || '',
      month: bonus?.month || '',
      year: bonus?.year || '',
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      status: bonus?.status || 1
    },
    validationSchema: employeeBonusSchema,
    onSubmit: async (values) => {
      console.log('update value: ', values);
      dispatch(updateBonus(values, onClose));
    }
  });

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = [
    { label: currentYear.toString(), value: currentYear },
    { label: (currentYear + 1).toString(), value: currentYear + 1 }
  ];

  const handleMonthChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    formik.setFieldValue('month', selectedValue);
  };

  const handleYearChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    formik.setFieldValue('year', selectedValue);
  };

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Bonus Type</label>
              <Dropdown
                value={bonusOptions.find((option) => option.bonusType === formik.values.bonusType)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    formik.setValues({
                      ...formik.values,
                      bonusType: selectedValue.value,
                      bonusCode: selectedValue.bonusCode,
                      bonusId: selectedValue.bonusId
                    });
                  }
                }}
                options={bonusOptions}
                isClearable={false}
                placeholder="Select Bonus"
                name="bonusType"
                id="bonusType"
                isDisabled
              />
              {formik.touched.bonusType && formik.errors.bonusType ? (
                <div className="text-red-500 text-sm">{formik.errors.bonusType}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2 flex lg:flex-row gap-x-2">
              <div className="w-1/2">
                <label className="mb-2.5 block text-black font-bold">Month</label>
                <div className="font-bold text-lg text-black flex">
                  <DateDropdown3
                    value={monthOptions.find((option) => option.value === formik.values?.month)}
                    onChange={handleMonthChange}
                    options={monthOptions}
                    isClearable={false}
                    placeholder="Month"
                    name="month"
                    id="month"
                  />
                </div>
              </div>
              <div className="w-1/2">
                <label className="mb-2.5 block text-black font-bold">Year</label>
                <div className="font-bold text-lg text-black flex">
                  <DateDropdown3
                    value={yearOptions.find((option) => option.value === formik.values?.year)}
                    onChange={handleYearChange}
                    options={yearOptions}
                    isClearable={false}
                    placeholder="Year"
                    name="year"
                    id="year"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Amount ({employee?.salaryDetails?.currency})</label>
              <input
                type="number"
                placeholder="Amount"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="bonusAmount"
                onChange={formik.handleChange}
                onBlur={(e) => handleFloatChange(e.target.value, 'bonusAmount')}
                value={formik.values.bonusAmount}
              />
              {formik.touched.bonusAmount && formik.errors.bonusAmount ? (
                <div className="text-red-500 text-sm">{formik.errors.bonusAmount}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2"></div>
          </div>
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditBonus;
