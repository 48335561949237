import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { loginSuperAdminSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import Banner from '../../../assets/images/loginbanner.jpeg';
import HRLogo from '../../../assets/logo/hrLogo.png';
import { useState } from 'react';
import { Eye, EyeSlash } from 'iconsax-react';
import { superAdminLogin } from '../../../actions/superadminAction';

const SuperAdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: loginSuperAdminSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(superAdminLogin(values, navigate));
    }
  });

  return (
    <div className="min-w-[320px] w-full h-screen flex flex-col lg:flex-row justify-center items-center bg-purple1 lg:bg-white overflow-y-hidden">
      <div className="w-full lg:w-1/2 flex items-center justify-end h-full relative">
        <div className="w-full flex flex-col items-center justify-center">
          <img src={HRLogo} alt="logo" className="lg:hidden flex w-28 mb-10" />
          <h4 className="text-3xl font-bold mb-10 text-white lg:text-black">Super Admin</h4>

          <form onSubmit={formik.handleSubmit} className="max-w-[310px] md:max-w-[400px] w-full">
            <div className="w-full">
              <div className="mb-4">
                <label className="mb-1 block text-white lg:text-black font-medium text-sm">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Enter Username"
                  className="w-full rounded-2xl border-[1px] placeholder:text-gray-400 border-grayDark bg-transparent py-3 px-5 text-white lg:text-black outline-none transition lg:focus:border-primary lg:active:border-primary disabled:cursor-default disabled:bg-whiter"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="text-red-500 text-sm">{formik.errors.username}</div>
                ) : null}
              </div>

              <div className="relative">
                <label className="mb-1 block text-white lg:text-black font-medium text-sm">Password</label>
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  className="w-full rounded-2xl border-[1px] placeholder:text-gray-400 border-grayDark bg-transparent py-3 px-5 text-white lg:text-black outline-none transition lg:focus:border-primary lg:active:border-primary disabled:cursor-default disabled:bg-whiter"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <div
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-12 right-4 flex items-center cursor-pointer"
                >
                  {passwordVisible ? <Eye size="24" color="gray" /> : <EyeSlash size="24" color="gray" />}
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm">{formik.errors.password}</div>
                ) : null}
              </div>
              <button
                type="submit"
                className="mt-8 flex w-full justify-center rounded-2xl bg-white hover:bg-gray-100 lg:bg-purple1 p-3 font-bold text-purple1 lg:text-white lg:hover:bg-purple1/90"
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-4 text-xs text-center w-full text-white/80 lg:text-black/40 font-medium">
          © 2024 HRSimplify. All Rights Reserved.
        </div>
      </div>
      <div className="w-full lg:w-1/2 lg:h-full relative">
        <img
          src={Banner}
          alt="rightsidebanner"
          className="hidden lg:flex w-full h-full rounded-bl-[200px] object-cover object-left"
        />
        <img
          src={HRLogo}
          alt="logo"
          className="hidden lg:flex absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-48"
        />
        <div className="hidden lg:flex absolute z-50 bottom-0 right-5 mb-4 gap-x-6">
          <button type='button' className="text-sm font-medium text-white">License</button>
          <button type='button' className="text-sm font-medium text-white">Terms of Use</button>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminLogin;