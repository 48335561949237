import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import TreeNodeComponent from './treeNodeComponent';
import defaultPic from '../assets/images/Default_profilepic.png';

const OrganizationChart = ({ nodes, manager }) => {
  return (
    <Tree label={<TreeNodeComponent label={manager?.fullName} imageSrc={manager?.avatar ? manager?.avatar : defaultPic} status={manager?.officeStatus}/>}>
      {nodes?.map((node, index) => (
        <TreeNode key={index} label={<TreeNodeComponent label={node?.label} imageSrc={node?.imageSrc ? node?.imageSrc : defaultPic} status={node?.status}/>} />
      ))}
    </Tree>
  );
};

export default OrganizationChart;
