import { medicalLeaveSchema } from '../../../validations/validationSchemas';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { updateMedicalLeave } from '../../../actions/companyAction';

const EditMedicalLeave = ({ company, medical, onClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      companyId: company?._id,
      _id: medical?._id,
      medicalType: medical?.medicalType || '',
      medicalCode: medical?.medicalCode || ''
    },
    validationSchema: medicalLeaveSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(updateMedicalLeave(values, onClose));
    }
  });

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Medical Leave Type</label>
              <input
                type="text"
                placeholder="Leave Type"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="medicalType"
                onChange={formik.handleChange}
                value={formik.values.medicalType}
              />
              {formik.touched.medicalType && formik.errors.medicalType ? (
                <div className="text-red-500 text-sm">{formik.errors.medicalType}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Medical Leave Code</label>
              <input
                type="text"
                placeholder="Leave Code"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="medicalCode"
                onChange={formik.handleChange}
                value={formik.values.medicalCode}
              />
              {formik.touched.medicalCode && formik.errors.medicalCode ? (
                <div className="text-red-500 text-sm">{formik.errors.medicalCode}</div>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditMedicalLeave;
