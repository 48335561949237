import { useFormik } from 'formik';
import { employeeDeductionSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import { updateDeduction } from '../../../actions/deductionAction';

const EditDeduction = ({ deduction, deductionOptions, onClose, employee }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      _id: deduction?._id,
      deductionId: deduction?.deductionId || '',
      deductionType: deduction?.deductionType || '',
      deductionCode: deduction?.deductionCode || '',
      deductionAmount: deduction?.deductionAmount || '',
      deductionFrequency: deduction?.deductionFrequency || 'Monthly',
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      status: deduction?.status
    },
    validationSchema: employeeDeductionSchema,
    onSubmit: async (values) => {
      console.log('update value: ', values);
      dispatch(updateDeduction(values, onClose));
    }
  });

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Deduction Type</label>
              <Dropdown
                value={deductionOptions.find((option) => option.deductionType === formik.values.deductionType)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    formik.setValues({
                      ...formik.values,
                      deductionType: selectedValue.value,
                      deductionCode: selectedValue.deductionCode,
                      deductionId: selectedValue.deductionId
                    });
                  }
                }}
                options={deductionOptions}
                isClearable={false}
                placeholder="Select Deduction"
                name="deductionType"
                id="deductionType"
                isDisabled
              />
              {formik.touched.deductionType && formik.errors.deductionType ? (
                <div className="text-red-500 text-sm">{formik.errors.deductionType}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">deductionAmount ({employee?.salaryDetails?.currency})</label>
              <input
                type="number"
                placeholder="deductionAmount"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="deductionAmount"
                onChange={formik.handleChange}
                onBlur={(e) => handleFloatChange(e.target.value, 'deductionAmount')}
                value={formik.values.deductionAmount}
              />
              {formik.touched.deductionAmount && formik.errors.deductionAmount ? (
                <div className="text-red-500 text-sm">{formik.errors.deductionAmount}</div>
              ) : null}
            </div>
          </div>
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Deduction Frequency</label>
              <input
                placeholder="Frequency"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="deductionFrequency"
                onChange={formik.handleChange}
                value={formik.values.deductionFrequency}
                disabled={true}
              />
              {formik.touched.deductionFrequency && formik.errors.deductionFrequency ? (
                <div className="text-red-500 text-sm">{formik.errors.deductionFrequency}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2"></div>
          </div>
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditDeduction;
