import React, { useState } from 'react';
import Header from '../components/header';
import UserSidebar from '../components/userSideBar/userSideBar';

const UserLayout = ({ children, page, subPage, pageTitle }) => {
  const storedSidebar = localStorage.getItem('sidebar') === 'true'; 
  const [sidebarOpen, setSidebarOpen] = useState(storedSidebar);

  return (
    <div className="flex min-h-screen">
      <div className={`fixed top-0 left-0 z-50 h-full ${sidebarOpen ? 'w-[310px]' : 'w-[75px]'} bg-gray-800`}>
        <UserSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      </div>

      <div className={`flex-1 h-full ${sidebarOpen ? 'ml-[310px]' : 'ml-[75px]'}`}>
        <div className={`fixed top-0 left-0 z-40 w-full ${sidebarOpen ? 'pl-[310px]' : 'pl-[75px]'} bg-white`}>
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            page={page}
            subPage={subPage}
            pageTitle={pageTitle}
          />
        </div>

        <div className="mt-20 mx-auto p-4 md:p-6 2xl:p-10 h-full">
          <div className="min-w-[1500px] overflow-x-auto">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default UserLayout;

// <div className="">
//   <div className="flex flex-row h-screen overflow-hidden items-start">
//     <UserSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
//     <div className="flex flex-col w-full h-full">
//       <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} page={page} subPage={subPage} pageTitle={pageTitle} />
//       <div className="mx-auto p-4 md:p-6 2xl:p-10 overflow-y-auto w-full h-full">{children}</div>
//     </div>
//   </div>
// </div>
