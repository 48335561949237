import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'iconsax-react';
import HeaderProfileDropdown from './headerProfileDropdown';
import useListenNotifications from '../reducers/listenNotifications';
import Notifications from '../notifications/notifications';
import { getCurrentEmployee } from '../actions/employeeAction';
import { fetchNotifications } from '../actions/notificationAction';

const Header = ({ page, subPage, pageTitle }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.employee?.user);
  const notifications = useSelector((state) => state.notifications.notifications);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const unreadCount = notifications?.filter((notification) => !notification.isRead).length;

  useListenNotifications();

  useEffect(() => {
    dispatch(getCurrentEmployee(user?._id));
    dispatch(fetchNotifications());
  }, [])

  // console.log("Notifications", notifications)
  console.log("unreadCount", unreadCount)

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    console.log("OPEN")
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <header className="sticky top-0 z-[40] flex w-full bg-grayLight drop-shadow-1">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex flex-col items-start justify-start">
          {page && (
            <div className="text-sm text-black/40">
              {page} / {subPage}
            </div>
          )}
          <div className="text-2xl sm:text-[32px] font-[900] mt-2 text-black">{pageTitle}</div>
        </div>

        <div className="flex items-center gap-3">
          <div className="relative flex h-11 w-11 items-center justify-center rounded-full bg-white shadow-lg cursor-pointer" ref={dropdownRef}>
            <div className="relative" onClick={toggleDropdown}>
              <Notification color="#000000" variant="Bold" size={20} />
              {unreadCount > 0 && (
                <span className="absolute -top-2 -right-2 z-1 flex items-center justify-center h-5 w-5 rounded-full bg-red-500 text-white text-xs font-bold">
                  {unreadCount}
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-500 opacity-75"></span>
                </span>
              )}
            </div>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2">
                <Notifications onClose={closeDropdown} />
              </div>
            )}
          </div>
          {/* <div className="flex items-center justify-start gap-x-2 bg-white h-11 pl-2 pr-3 rounded-full shadow-lg" to="#">
            <span className="h-8 w-8 rounded-full">
              <img src={user?.avatar ? user?.avatar : defaultPic} alt="User" className='w-full h-full rounded-full object-cover'/>
            </span>

            <span className="hidden text-right lg:block">
              <span className="block text-sm font-medium text-black/40">{user?.fullName}</span>
            </span>

            <ArrowRight2 color="rgba(0, 0, 0, 0.4)" variant='TwoTone' size={14} />
          </div> */}
          <HeaderProfileDropdown user={user} />
        </div>
      </div>
    </header>
  );
};

export default Header;
