import { createContext, useState, useEffect, useContext } from "react";
import { useSelector } from 'react-redux';
import io from "socket.io-client";
import { useAuthContext } from "./authContext";


const SocketContext = createContext();
const socketUrl = process.env.REACT_APP_SOCKET_APIURL;


export const useSocketContext = () => {
    return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {

    const [socket, setSocket] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const { authUser } = useAuthContext();


    useEffect(() => {
        console.log("authUser " + authUser?.id)
        if (authUser) {
            console.log("authUser " + authUser.id)
            console.log(socketUrl)
            const socket = io(socketUrl, {
                query: {
                    userId: authUser.id,
                },
                transports: ['websocket'],
            });

            setSocket(socket);
            // socket.on() is used to listen to the events. can be used both on client and server side
            socket.on("getOnlineUsers", (users) => {
                // setOnlineUsers(users);
            });

            socket.on('error', (error) => {
                console.error("Socket error: ", error);
            });

            socket.on('disconnect', (reason) => {
                console.log("Socket disconnected: ", reason);
            });


            return () => {
                socket.close();
            };
        } else {
            if (socket) {
                socket.close();
                setSocket(null);
            }
        }
    }, [authUser]);

    return (
        <SocketContext.Provider value={{ socket, onlineUsers }}>
            {children}
        </SocketContext.Provider>
    );
};


// import { createContext, useState, useEffect, useContext } from "react";
// import { useSelector } from 'react-redux';
// import io from "socket.io-client";
// import { useAuthContext } from "./authContext";

// const SocketContext = createContext();
// const socketUrl = process.env.REACT_APP_SOCKET_APIURL;

// export const useSocketContext = () => {
//     return useContext(SocketContext);
// };

// export const SocketContextProvider = ({ children }) => {
//     const isEmployerLogin = useSelector((state) => state.employerLogin);
//     const [socket, setSocket] = useState(null);
//     const [onlineUsers, setOnlineUsers] = useState([]);
//     const { authUser } = useAuthContext();

//     useEffect(() => {
//         if (authUser) {
//             console.log("authUser ID:", authUser.id);
//             console.log("Socket URL:", socketUrl);

//             const socket = io(socketUrl, {
//                 query: {
//                     userId: authUser.id,
//                 },
//                 transports: ['websocket'], // Ensure that only WebSocket is used
//                 reconnectionAttempts: 3, // Attempt to reconnect up to 3 times
//                 timeout: 20000, // Set connection timeout to 20 seconds
//             });

//             socket.on("connect", () => {
//                 console.log("WebSocket connected:", socket.id);
//             });

//             socket.on("disconnect", (reason) => {
//                 console.log("WebSocket disconnected:", reason);
//             });

//             socket.on("connect_error", (error) => {
//                 console.error("WebSocket connection error:", error);
//             });

//             socket.on("reconnect_attempt", (attemptNumber) => {
//                 console.log("WebSocket reconnection attempt:", attemptNumber);
//             });

//             socket.on("getOnlineUsers", (users) => {
//                 setOnlineUsers(users);
//             });

//             setSocket(socket);

//             return () => {
//                 socket.close();
//             };
//         } else {
//             if (socket) {
//                 socket.close();
//                 setSocket(null);
//             }
//         }
//     }, [authUser]);

//     return (
//         <SocketContext.Provider value={{ socket, onlineUsers }}>
//             {children}
//         </SocketContext.Provider>
//     );
// };