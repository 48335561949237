import { useEffect, useState, useMemo } from 'react';
import {
  applyNoPayLeave,
  cancelNoPayLeave
} from '../../../actions/nopayLeaveAction';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import DatePicker from '../../../components/DatePicker';
import { ToastBar } from '../../../components/toastbar';
import { useFormik } from 'formik';
import { applyNoPayLeaveSchema } from '../../../validations/validationSchemas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import { format } from 'date-fns';
import { Edit2, MinusCirlce } from 'iconsax-react';
import { CancelNoPayLeaveModal } from './cancelNoPayLeaveModal';
import EditNoPayLeave from './editNoPayLeave';
import empty from '../../../assets/images/empty.png';
import ManagerLayout from '../../../layout/managerLayout';
import { searchEmployeesByDepartment } from '../../../actions/managerAction';
import { fetchEmployeeSuccess, getEmployee } from '../../../actions/employeeAction';
import { useLocation } from 'react-router-dom';

const ManagerApplyNoPayLeave = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const senderId = location.state?.senderId || null;
  const activityId = location.state?.activityId || null;

  const [searchName, setSearchName] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [editingLeave, setEditingLeave] = useState(null);
  const [leaveToCancel, setLeaveToCancel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const employees = useSelector((state) => state?.employee?.searchEmployees) || [];
  const company = useSelector((state) => state.company?.company);
  const singleEmployee = useSelector((state) => state?.employee?.current);
  const user = useSelector((state) => state?.employee?.user);

  const [sortConfig, setSortConfig] = useState({
    key: 'appliedDate',
    direction: 'descending'
  });

  const formik = useFormik({
    initialValues: {
      noPayLeaveType: '',
      fromDate: '',
      toDate: '',
      dailyRate: 0,
      noPayLeaveAmount: 0
    },
    validationSchema: applyNoPayLeaveSchema,
    onSubmit: (values) => {
      if(singleEmployee?.approver?.approverId){
        handleApplyLeave(values);
      } else {
        ToastBar.warning('Approver Not Assigned.');
        return;
      }
    }
  });

  useEffect(() => {
    dispatch(fetchEmployeeSuccess([]));
  }, []);

  useEffect(() => {
    if (senderId !== null) {
      console.log('receiverId inside: ', senderId);
      const employee = employees?.find(emp => emp._id === senderId);
      console.log('employee inside: ', employee);
      if (employee) {
        setSelectedEmployee({ label: employee.fullName, value: employee._id });
        dispatch(getEmployee(employee._id));
      }
    }
  }, [senderId, employees, dispatch]);


  useEffect(() => {
    if (user?.workingHourDetails?.workHoursType === 'Fixed') {
      setSelectedEmployee({ label: user?.fullName, value: user?._id });
      dispatch(getEmployee(user?._id));
    }
  }, [user]);

  useEffect(() => {
    const inputValue = {
      companyId: company?._id,
      employeeId: user?._id,
      departmentCode: user?.employmentDetails?.departmentCode
    };
    if (company && user) dispatch(searchEmployeesByDepartment(inputValue));
  }, [company, user, dispatch]);

  console.log('selectedEmployee: ', selectedEmployee);

  const calculateDailyRate = () => {
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    const currentMonthDays =
      singleEmployee?.workingHourDetails?.monthlyDays.find((month) => month.month === currentMonth)?.days || 0;
    const dailyRate = singleEmployee?.salaryDetails?.basicSalary / currentMonthDays;
    console.log('dailyRate: ', dailyRate);

    if (singleEmployee?.workingHourDetails?.workHoursType === 'Fixed' && dailyRate) {
      const formattedValue = parseFloat(dailyRate).toFixed(2);
      formik.setFieldValue('dailyRate', formattedValue);
    } else {
      formik.setFieldValue('dailyRate', 0);
    }
  };

  useEffect(() => {
    if (formik.values.fromDate && formik.values.toDate) {
      calculateNumberOfDays(formik.values.fromDate, formik.values.toDate);
    }
  }, [formik.values.fromDate, formik.values.toDate, formik.values.dailyRate]);

  const handleInputChange = (inputValue) => {
    setSearchName(inputValue); // Update searchName state
  };

  const handleDropdownChange = (selectedOption) => {
    console.log('selectedOption: ', selectedOption);
    if (selectedOption) {
      resetFormState();
      setIsFormVisible(false);
      setSelectedEmployee(selectedOption);
      dispatch(getEmployee(selectedOption.value));
    }
  };

  const calculateNumberOfDays = (fromDate, toDate) => {
    if (!fromDate || !toDate) return;

    const start = new Date(fromDate);
    const end = new Date(toDate);

    if (start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear()) {
      ToastBar.warning('Start date and end date must be in the same month.');
      return;
    }

    let currentDate = new Date(fromDate);
    const endDate = new Date(toDate);
    let dayCount = 0;

    // Get the list of public holiday dates as an array of startDates
    const publicHolidayDates =
      company?.publicHolidays?.map((holiday) => new Date(holiday.startDate).toDateString()) || [];

    // Loop through each date in the range
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();

      // Check if the current date is a weekend or a public holiday
      if (dayOfWeek !== 0 && dayOfWeek !== 6 && !publicHolidayDates.includes(currentDate.toDateString())) {
        dayCount++;
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }
    const noPayLeaveAmount = formik.values.dailyRate * dayCount;
    const formattedValue = parseFloat(noPayLeaveAmount).toFixed(2);
    formik.setFieldValue('noPayLeaveAmount', formattedValue);

    setNumberOfDays(dayCount);
  };

  const handleApplyLeave = async (values) => {
    const { noPayLeaveType, fromDate, toDate, dailyRate, noPayLeaveAmount } = values;

    const leave = singleEmployee.noPayLeaveDetails.noPayLeaves.find((leave) => leave.noPayLeaveType === noPayLeaveType);

    if (!leave) {
      ToastBar.error('Please select a valid leave type.');
      return;
    }

    const start = new Date(fromDate);
    const end = new Date(toDate);

    if (start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear()) {
      ToastBar.warning('Start date and end date must be in the same month.');
      return;
    }

    const noPayLeaveApplication = {
      noPayLeaveType,
      noPayLeaveCode: leave.noPayLeaveCode,
      fromDate,
      toDate,
      numberOfDays,
      dailyRate,
      noPayLeaveAmount,
      status: 2,
      companyId: singleEmployee?.company?._id,
      employeeId: singleEmployee?._id
    };

    console.log('applyNoPayLeave ', noPayLeaveApplication);
    const result = await dispatch(applyNoPayLeave(noPayLeaveApplication));
    console.log(result);
    if (result.success) {
      setIsFormVisible(false);
    }
  };

  const resetFormState = () => {
    formik.resetForm();
    setEditingLeave(null);
    setNumberOfDays(0);
  };

  const toggleFormVisibility = () => {
    resetFormState();
    calculateDailyRate();
    setIsFormVisible(!isFormVisible);
  };

  const handleEditClick = (leave) => {
    resetFormState();
    setEditingLeave(leave);
    setIsFormVisible(true);
  };

  const handleCancelLeave = (leave) => {
    setLeaveToCancel(leave);
  };

  const confirmCancelLeave = () => {
    if (leaveToCancel) {
      const updatedLeaveToCancel = {
        ...leaveToCancel,
        companyId: singleEmployee?.company?._id,
        employeeId: singleEmployee._id
      };
      dispatch(cancelNoPayLeave(updatedLeaveToCancel));
      setLeaveToCancel(null);
    }
  };

  const closeModal = () => {
    setLeaveToCancel(null);
  };

  const filteredEmployeeOptions = employees
    .filter(
      (employee) =>
        employee.fullName?.toLowerCase().includes(searchName.toLowerCase()) &&
        employee.workingHourDetails?.workHoursType === 'Fixed'
    )
    .map((employee) => ({
      label: employee.fullName,
      value: employee._id
    }));

  console.log('filteredEmployeeOptions: ', filteredEmployeeOptions);

  const departmentOptions =
    company?.departments?.map((department) => ({
      label: department?.departmentName,
      value: department?.departmentCode
    })) || [];

  const noPayLeaveOptions = singleEmployee?.noPayLeaveDetails?.noPayLeaves.map((leave) => ({
    label: leave.noPayLeaveType,
    value: leave.noPayLeaveCode
  }));

  const sortedLeavesHistory = useMemo(() => {
    if (!singleEmployee || !singleEmployee.noPayLeaveDetails || !singleEmployee.noPayLeaveDetails.noPayLeavesHistory) {
      return [];
    }

    let sortableLeaves = [...singleEmployee.noPayLeaveDetails.noPayLeavesHistory];
    sortableLeaves.sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.key) {
        case 'noPayLeaveType':
          aValue = a.noPayLeaveType.toLowerCase();
          bValue = b.noPayLeaveType.toLowerCase();
          break;
        case 'noPayLeaveCode':
          aValue = a.noPayLeaveCode.toLowerCase();
          bValue = b.noPayLeaveCode.toLowerCase();
          break;
        case 'fromDate':
          aValue = new Date(a.fromDate).getTime();
          bValue = new Date(b.fromDate).getTime();
          break;
        case 'toDate':
          aValue = new Date(b.toDate).getTime();
          bValue = new Date(a.toDate).getTime();
          break;
        case 'numberOfDays':
          aValue = a.numberOfDays;
          bValue = b.numberOfDays;
          break;
        case 'appliedDate':
          aValue = new Date(a.appliedDate).getTime();
          bValue = new Date(b.appliedDate).getTime();
          break;
        case 'status':
          aValue = a.status.toLowerCase();
          bValue = b.status.toLowerCase();
          break;
        default:
          return 0;
      }

      if (sortConfig.direction === 'ascending') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });

    return sortableLeaves;
  }, [singleEmployee, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const employeeOptionsKey = filteredEmployeeOptions.map((option) => option.value).join('-');

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 4:
        return { label: 'Processing', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 2:
        return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
      case 3:
        return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
      case 5:
        return { label: 'Confirmed', bgColor: 'bg-blue-500', textColor: 'text-blue-500' };
      default:
        return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedLeavesHistory.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedLeavesHistory.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  const shouldHighlightRow = (leave) => {
    return activityId && activityId === leave._id;
  };

  return (
    <ManagerLayout pageTitle={'Apply No Pay Leave'} page={'Employees'} subPage={'Apply No Pay Leave'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full overflow-y-auto">
        <div>
          <div className="flex justify-between">
            <div className="flex w-3/4 gap-x-4">
              <Dropdown
                value={departmentOptions?.find((option) => option.value === user?.employmentDetails?.departmentCode)}
                options={departmentOptions}
                isClearable={true}
                isSearchable={true}
                placeholder="Select Department"
                name="branch"
                id="branch"
                isDisabled={true}
              />
              <Dropdown
                key={employeeOptionsKey}
                options={filteredEmployeeOptions}
                value={filteredEmployeeOptions.find((option) => option.value === selectedEmployee?.value)}
                onChange={handleDropdownChange}
                onInputChange={handleInputChange}
                placeholder="Search Employees"
                name="employee"
                id="employee"
                menuPlacement="bottom"
                isClearable={true}
              />
            </div>
            <button
              onClick={toggleFormVisibility}
              disabled={!singleEmployee || !noPayLeaveOptions?.length > 0}
              className={
                isFormVisible
                  ? 'h-12 w-36 font-bold rounded-xl border-2 bg-red1 text-white hover:bg-red1/90 disabled:bg-gray-400'
                  : 'h-12 w-48 bg-purple1 hover:bg-purple1/90 text-whiten font-bold rounded-xl disabled:bg-gray-400'
              }
            >
              {isFormVisible ? 'Cancel' : 'Apply No Pay Leave'}
            </button>
          </div>
          <div className="flex flex-wrap gap-3 mt-5">
            {singleEmployee?.noPayLeaveDetails?.noPayLeaves?.map((leave, index) => {
              return (
                <div className="p-6 relative bg-white shadow-lg w-[32%] rounded-2xl" key={index}>
                  <div className="text-base text-black font-bold underline">{leave?.noPayLeaveType}</div>
                  <div className="text-sm font-normal text-black">
                    <span className="font-semibold">No Pay Leave Code:</span> {leave?.noPayLeaveCode}
                  </div>
                </div>
              );
            })}
          </div>
          {/* Edit Leave Application Form */}
          {editingLeave && isFormVisible && (
            <EditNoPayLeave
              singleEmployee={singleEmployee}
              leave={editingLeave}
              onCancel={() => {
                resetFormState();
                setIsFormVisible(false);
              }}
              employeeId={singleEmployee._id}
              companyId={singleEmployee?.company?._id}
              company={company}
            />
          )}

          {/* Leave Application Form */}
          {singleEmployee && noPayLeaveOptions?.length > 0
            ? !editingLeave &&
              isFormVisible &&
              singleEmployee && (
                <div className="bg-white p-6 rounded-2xl shadow-lg mt-5">
                  <h4 className="text-2xl font-bold mb-5 text-black">Apply No Pay Leave</h4>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                      <div className="w-full lg:w-1/2">
                        <label className="mb-2.5 block text-black font-bold">Select No Pay Leave Type</label>
                        <Dropdown
                          options={noPayLeaveOptions || []}
                          value={noPayLeaveOptions?.find((option) => option.label === formik.values.noPayLeaveType)}
                          onChange={(option) => {
                            formik.setFieldValue('noPayLeaveType', option.label);
                            formik.setFieldTouched('noPayLeaveType', true, false);
                          }}
                          placeholder="Select Leave Type"
                          name="noPayLeaveType"
                          id="noPayLeaveType"
                        />
                        {formik.touched.noPayLeaveType && formik.errors.noPayLeaveType ? (
                          <div className="text-red-500 text-sm">{formik.errors.noPayLeaveType}</div>
                        ) : null}
                      </div>
                      <div className="w-full lg:w-1/2"></div>
                    </div>
                    <div className="mb-7 flex flex-col gap-6 lg:flex-row">
                      <div className="w-full lg:w-1/2">
                        <label className="mb-2.5 block text-black font-bold">From Date</label>
                        <DatePicker
                          value={formik.values.fromDate}
                          onChange={(date) => {
                            formik.setFieldValue('fromDate', date);
                            formik.setFieldTouched('fromDate', true, false);
                          }}
                        />
                        {formik.touched.fromDate && formik.errors.fromDate ? (
                          <div className="text-red-500 text-sm">{formik.errors.fromDate}</div>
                        ) : null}
                      </div>

                      <div className="w-full lg:w-1/2">
                        <label className="mb-2.5 block text-black font-bold">To Date</label>
                        <DatePicker
                          value={formik.values.toDate}
                          onChange={(date) => {
                            formik.setFieldValue('toDate', date);
                            formik.setFieldTouched('toDate', true, false);
                          }}
                        />
                        {formik.touched.toDate && formik.errors.toDate ? (
                          <div className="text-red-500 text-sm">{formik.errors.toDate}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mb-7 flex flex-col gap-6 lg:flex-row">
                      <div className="w-full lg:w-1/2">
                        <label className="mb-2.5 block text-black font-bold">
                          Daily Rate ({singleEmployee?.salaryDetails?.currency})
                        </label>
                        <input
                          type="number"
                          placeholder="Daily Rate"
                          className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                          name="dailyRate"
                          onChange={formik.handleChange}
                          onBlur={(e) => handleFloatChange(e.target.value, 'dailyRate')}
                          value={formik.values.dailyRate}
                        />
                        {formik.touched.dailyRate && formik.errors.dailyRate ? (
                          <div className="text-red-500 text-sm">{formik.errors.dailyRate}</div>
                        ) : null}
                      </div>
                      <div className="w-full lg:w-1/2"></div>
                    </div>
                    <div className="mb-6">
                      <div className="block text-base text-black font-bold mb-2">
                        Number of Days: <span className="font-normal">{numberOfDays}</span>
                      </div>
                      <div className="block text-base text-black font-bold">
                        Total Amount: <span className="font-normal">{formik.values.noPayLeaveAmount}</span>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="bg-red1 hover:bg-red1/90 text-white font-semibold rounded-md px-4 py-2 mr-2"
                        onClick={toggleFormVisibility}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-purple1 hover:bg-purple1/90 text-white font-bold rounded-lg px-4 py-2"
                      >
                        Submit Leave
                      </button>
                    </div>
                  </form>
                </div>
              )
            : singleEmployee && (
                <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                  <img src={empty} alt="empty data" />
                  <div className="mt-5 text-purple1 font-bold text-xl">No Pay Leave For This Employee</div>
                </div>
              )}

          {(!company?._id || !singleEmployee) && (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Employee Selected</div>
            </div>
          )}
          {currentEntries.length > 0 ? (
            <div>
              <div className="flex justify-end items-center  my-5">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
                    <th>Full Name</th>
                    <th onClick={() => handleSort('noPayLeaveType')} className="cursor-pointer">
                      Leave Type
                      <FontAwesomeIcon icon={getSortIcon('noPayLeaveType')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('fromDate')} className="cursor-pointer">
                      From Date <FontAwesomeIcon icon={getSortIcon('fromDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('toDate')} className="cursor-pointer">
                      To Date <FontAwesomeIcon icon={getSortIcon('toDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('numberOfDays')} className="cursor-pointer">
                      Num of Days <FontAwesomeIcon icon={getSortIcon('numberOfDays')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('appliedDate')} className="cursor-pointer">
                      Applied Date <FontAwesomeIcon icon={getSortIcon('appliedDate')} size="sm" className="ml-1" />
                    </th>
                    <th>Modified By</th>
                    <th>A / R</th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4">
                      Status <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
                    </th>
                    <th className="rounded-r-xl">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((leave, index) => {
                    const formattedFromDate = !Number.isNaN(new Date(leave?.fromDate).getTime())
                      ? format(new Date(leave?.fromDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedToDate = !Number.isNaN(new Date(leave?.toDate).getTime())
                      ? format(new Date(leave?.toDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedAppliedDate = !Number.isNaN(new Date(leave?.appliedDate).getTime())
                      ? format(new Date(leave?.appliedDate), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date';

                    const { label, textColor, bgColor } = getStatusLabel(leave?.status);

                    const isValidToCancel =
                      new Date() <= new Date(new Date(leave?.fromDate).getTime() + 24 * 60 * 60 * 1000);

                    return (
                      <tr className={`h-20 text-sm ${shouldHighlightRow(leave) ? 'bg-green-100' : ''}`} key={index}>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={singleEmployee?.avatar ? singleEmployee?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center">{singleEmployee?.fullName}</td>
                        <td className="h-20 text-center">{leave?.noPayLeaveType}</td>
                        <td className="h-20 text-center">{formattedFromDate}</td>
                        <td className="h-20 text-center">{formattedToDate}</td>
                        <td className="h-20 text-center">{leave?.numberOfDays}</td>
                        <td className="h-20 text-center">{formattedAppliedDate}</td>
                        <td className="h-20 text-center">{leave?.updatedBy}</td>
                        <td className="h-20 text-center">{leave?.approvedBy}</td>
                        <td className="h-20 w-26">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-center gap-x-2 items-center">
                            {(leave?.status === 2 || leave?.status === 0) && (
                              <div
                                className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-green-200 shadow-md"
                                onClick={() => handleEditClick(leave)}
                                title="Edit"
                              >
                                <Edit2 color="#16a34a" variant="Bold" size={20} className="cursor-pointer" />
                              </div>
                            )}
                            {(leave?.status === 2 || (leave?.status === 1 && isValidToCancel)) && (
                              <div
                                className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-100 shadow-md"
                                onClick={() => handleCancelLeave(leave)}
                                title="Cancel"
                              >
                                <MinusCirlce color="#C84040" variant="Bold" size={20} className="cursor-pointer" />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            singleEmployee &&
            noPayLeaveOptions?.length > 0 && (
              <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                <img src={empty} alt="empty data" />
                <div className="mt-5 text-purple1 font-bold text-xl">No Pay Leave History</div>
              </div>
            )
          )}
          {leaveToCancel && <CancelNoPayLeaveModal onCancel={closeModal} onConfirm={confirmCancelLeave} />}
        </div>
        {currentEntries.length > 0 && (
          <div className="flex">
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedLeavesHistory.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${
                          pageNumber === currentPage
                            ? 'bg-purple1 text-white rounded-xl font-bold'
                            : 'text-black bg-oppty-green-5'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </ManagerLayout>
  );
};

export default ManagerApplyNoPayLeave;
