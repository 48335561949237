import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { approveClaimByApprover, getPendingClaimsByApprover, rejectClaimByApprover } from '../../../../actions/claimAction';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import empty from '../../../../assets/images/empty.png';
import { format } from 'date-fns';
import { ApproveClaimModal } from './approveClaimModal';
import { RejectClaimModal } from './rejectClaimModal';
import { Eye } from 'iconsax-react';
import { ViewDocumentModal } from './viewDocumentModal';
import ManagerLayout from '../../../../layout/managerLayout';
import defaultPic from '../../../../assets/images/Default_profilepic.png';
import { ToastBar } from '../../../../components/toastbar';

const ManagerClaimSubmissions = () => {
  const dispatch = useDispatch();

  const pendingClaims = useSelector((state) => state?.claim?.pendingClaims) || [];

  const location = useLocation();
  const activityId = location.state?.activityId || null;

  const [sortConfig, setSortConfig] = useState({
    key: 'createdDate',
    direction: 'descending'
  });
  const [selectedClaims, setselectedClaims] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToView, setFileToView] = useState(null);

  useEffect(() => {
    dispatch(getPendingClaimsByApprover()); //userId can get in token.
  }, [dispatch]);

  const handleView = (file) => {
    setFileToView(file);
    setIsModalOpen(true);
  };

  const closeViewModal = () => {
    setIsModalOpen(false);
    setFileToView(null);
  };

  const sortedpendingClaims = useMemo(() => {
    if (!pendingClaims.length) {
      return [];
    }

    let sortableClaims = pendingClaims.flatMap((claim) =>
      claim.pendingClaims.map((pendingClaim) => ({
        ...pendingClaim,
        employee: claim.employee
      }))
    );

    sortableClaims.sort((a, b) => {
      const fieldA = sortConfig.key.split('.').reduce((obj, key) => obj[key], a);
      const fieldB = sortConfig.key.split('.').reduce((obj, key) => obj[key], b);

      const valueA = typeof fieldA === 'string' ? fieldA.toLowerCase() : String(fieldA || '');
      const valueB = typeof fieldB === 'string' ? fieldB.toLowerCase() : String(fieldB || '');

      if (sortConfig.direction === 'ascending') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    return sortableClaims;
  }, [pendingClaims, sortConfig]);

  useEffect(() => {
    if (activityId) {
      const matchingClaim = sortedpendingClaims.find(claim => claim._id === activityId);
      if (matchingClaim) {
        setselectedClaims([matchingClaim]);
      }
    }
  }, [activityId, sortedpendingClaims]);

  useEffect(() => {
    if (selectedClaims.length === sortedpendingClaims.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedClaims, sortedpendingClaims]);

  const handleCheckboxChange = (claim) => {
    setselectedClaims((prevSelected) =>
      prevSelected.includes(claim) ? prevSelected.filter((selected) => selected !== claim) : [...prevSelected, claim]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setselectedClaims([]);
    } else {
      setselectedClaims(sortedpendingClaims);
    }
    setSelectAll(!selectAll);
  };

  const handleApproveClaim = () => {
    setShowApproveModal(true);
  };

  const handleRejectClaim = () => {
    if (selectedClaims?.length > 1) {
      ToastBar.warning('Only Reject 1 application at a time');
    } else {
      setShowRejectModal(true);
    }
  };

  const confirmApproveClaim = () => {
    console.log(selectedClaims);
    dispatch(approveClaimByApprover(selectedClaims));
    setShowApproveModal(false);
    setselectedClaims([]);
    setSelectAll(false);
  };

  const onChangeReason = (rejectReason) => {
    const updatedClaims = [...selectedClaims];
  
    if (updatedClaims.length > 0) {
      updatedClaims[0] = {
        ...updatedClaims[0],
        rejectReason: rejectReason,
      };
    }
  
    setselectedClaims(updatedClaims);
  };

  const confirmRejectClaim = () => {
    console.log(selectedClaims);
    dispatch(rejectClaimByApprover(selectedClaims));
    setShowRejectModal(false);
    setselectedClaims([]);
    setSelectAll(false);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 4:
        return { label: 'Processing', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 2:
        return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
      case 3:
        return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
      case 5:
        return { label: 'Confirmed', bgColor: 'bg-blue-500', textColor: 'text-blue-500' };
      default:
        return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedpendingClaims.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedpendingClaims.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <ManagerLayout pageTitle={'Pending Claims'} page={'Employees'} subPage={'Pending Claims'}>
      {/* <h3 className="text-4xl font-semibold text-black pb-5">Pending Leave Applications</h3> */}
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full overflow-y-auto">
        <div>
          <div className="flex flex-row justify-end items-end gap-x-3 pb-5">
            <button
              type="button"
              onClick={handleApproveClaim}
              className="h-12 w-36 bg-green1 hover:bg-green1/90 text-white font-semibold rounded-md cursor-pointer disabled:bg-gray-400"
              disabled={selectedClaims.length === 0 || sortedpendingClaims.length < 0}
            >
              Approve Claim
            </button>
            <button
              type="button"
              onClick={handleRejectClaim}
              className="h-12 w-36 bg-red1 hover:bg-red1/90 text-white font-semibold rounded-md cursor-pointer disabled:bg-gray-400"
              disabled={selectedClaims.length === 0 || sortedpendingClaims.length < 0}
            >
              Reject Claim
            </button>
          </div>

          {sortedpendingClaims.length > 0 ? (
            <div>
              <div className="flex justify-end items-center  my-5">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-10 rounded-s-xl">
                      <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                    </th>
                    <th className="h-20 text-center w-16">Photo</th>
                    <th onClick={() => handleSort('employee.fullName')} className="cursor-pointer">
                      Full Name <FontAwesomeIcon icon={getSortIcon('employee.fullName')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('claimType')} className="cursor-pointer">
                      Claim Type
                      <FontAwesomeIcon icon={getSortIcon('claimType')} size="sm" className="ml-1" />
                    </th>
                    {/* <th onClick={() => handleSort('claimCode')} className="cursor-pointer">
                      Claim Code
                      <FontAwesomeIcon icon={getSortIcon('claimCode')} size="sm" className="ml-1" />
                    </th> */}
                    <th onClick={() => handleSort('claimDate')} className="cursor-pointer">
                      Claim Date <FontAwesomeIcon icon={getSortIcon('claimDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('claimAmount')} className="cursor-pointer">
                      Claim Amount <FontAwesomeIcon icon={getSortIcon('claimAmount')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('createdDate')} className="cursor-pointer">
                      Created Date <FontAwesomeIcon icon={getSortIcon('createdDate')} size="sm" className="ml-1" />
                    </th>
                    <th>Remark</th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4">
                      Status <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
                    </th>
                    <th className="px-4 rounded-e-xl">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((claim, index) => {
                    const formattedClaimDate = !Number.isNaN(new Date(claim?.claimDate).getTime())
                      ? format(new Date(claim?.claimDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedCreatedDate = !Number.isNaN(new Date(claim?.createdDate).getTime())
                      ? format(new Date(claim?.createdDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const { label, textColor, bgColor } = getStatusLabel(claim?.status);

                    return (
                      <tr className={`h-20 text-sm text-black ${selectedClaims.includes(claim) ? 'bg-green-100' : '' }`} key={index}>
                        <td className="h-20 text-center">
                          <input
                            type="checkbox"
                            checked={selectedClaims.includes(claim)}
                            onChange={() => handleCheckboxChange(claim)}
                          />
                        </td>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={claim?.employee?.avatar ? claim?.employee?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center" title={claim?.employee?.fullName}>
                          {claim?.employee?.fullName}
                          {/* {claim?.employee?.fullName?.slice(0, 12)}
                          {claim?.employee?.fullName?.length > 12 ? '...' : ''} */}
                        </td>
                        <td className="h-20 text-center">{claim?.claimType}</td>
                        {/* <td className="h-20 text-center">{claim?.claimCode}</td> */}
                        <td className="h-20 text-center">{formattedClaimDate}</td>
                        <td className="h-20 text-center">
                          {new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(claim?.claimAmount)}
                        </td>
                        <td className="h-20 text-center">{formattedCreatedDate}</td>
                        <td className="h-20 text-center cursor-pointer">
                          <div className="relative group">
                            <div className="w-28 truncate cursor-pointer">{claim?.claimRemark}</div>
                            <div className="absolute left-0 top-0 w-60 p-3 bg-grayLight shadow-xl text-black rounded hidden group-hover:block z-50">
                              {claim?.claimRemark}
                            </div>
                          </div>
                        </td>
                        <td className="h-20 w-26">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                        <td className="h-20 w-26 flex justify-center items-center">
                          <div
                            className="flex justify-center items-center w-8 h-8 p-1.5 rounded-md hover:bg-blue-400 shadow-md"
                            onClick={() => handleView(claim?.claimFile)}
                            title="View"
                          >
                            <Eye color="#6b7280" variant="Bold" size={20} className="cursor-pointer" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Pending Claims</div>
            </div>
          )}
        </div>
        {currentEntries.length > 0 && (
          <div className="flex">
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedpendingClaims.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${
                          pageNumber === currentPage
                            ? 'bg-purple1 text-white rounded-xl font-bold'
                            : 'text-black bg-oppty-green-5'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}

        {showApproveModal && (
          <ApproveClaimModal onCancel={() => setShowApproveModal(false)} onConfirm={confirmApproveClaim} />
        )}
        {showRejectModal && (
          <RejectClaimModal onCancel={() => setShowRejectModal(false)} onConfirm={confirmRejectClaim} selectedClaim={selectedClaims[0]} onChangeReason={onChangeReason} />
        )}
      </div>
      {isModalOpen && <ViewDocumentModal fileToView={fileToView} closeViewModal={closeViewModal} />}
    </ManagerLayout>
  );
};

export default ManagerClaimSubmissions;
