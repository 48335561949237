import { format } from 'date-fns';
import defaultPic from '../../../assets/images/defaultCompany.png';

const CompanyList = ({ allCompanies }) => {
  return (
    <table className="table-auto rounded-2xl w-full">
      <thead>
        <tr className="h-20 bg-grayLight rounded-e-xl">
          <th className="rounded-s-xl">Company Logo</th>
          <th>Company Name</th>
          <th>Created By</th>
          <th>Created Date</th>
          <th className="rounded-e-xl">Status</th>
        </tr>
      </thead>
      <tbody>
        {allCompanies?.map((company, index) => {
          const formattedCreatedDate = !Number.isNaN(new Date(company?.createdDate).getTime())
            ? format(new Date(company?.createdDate), 'dd-MM-yyyy HH:mm')
            : 'Invalid Date';
          return (
            <tr className={`h-20`} key={index}>
              <td className="h-20 w-40">
                <div className="flex justify-center items-center">
                  <img
                    src={company?.avatar ? company?.avatar : defaultPic}
                    alt="profile pic"
                    className="w-8 h-8 rounded-full object-cover"
                  />
                </div>
              </td>
              <td className="text-sm text-center text-black">{company?.companyName}</td>
              <td className="text-sm text-center text-black">{company?.createdBy}</td>
              <td className="text-sm text-center text-black">{formattedCreatedDate}</td>
              <td className="h-20 w-24">
                <div className="flex justify-center">
                  <div className="bg-grayLight flex items-center justify-center w-fit px-1 rounded-full">
                    {company?.status === 1 ? (
                      <div className="flex items-center gap-x-1">
                        <div className="w-2.5 h-2.5 rounded-full bg-green1"></div>
                        <span className="text-xs text-green1">Active</span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-x-1">
                        <div className="w-2.5 h-2.5 rounded-full bg-gray-500"></div>
                        <span className="text-xs text-gray-400">Inactive</span>
                      </div>
                    )}
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CompanyList;
