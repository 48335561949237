import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Edit2, StopCircle } from 'iconsax-react';
import { stopEmployee } from '../../../actions/employeeAction';
import { useDispatch } from 'react-redux';
import { ConfirmStopEmployeeModal } from './confirmStopEmployeeModal';
import { WarningStopEmployeeModal } from './warningStopEmployeeModal';

const EmployeeList = ({ allEmployees, company }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sortConfig, setSortConfig] = useState({
    key: 'empId',
    direction: 'descending'
  });
  const [employeeToStop, setEmployeeToStop] = useState(null);
  const [warningRemove, setWarningRemove] = useState(null);

  const sortedEmployees = useMemo(() => {
    if (!allEmployees) return [];

    let sortableEmployees = [...allEmployees];
    sortableEmployees.sort((a, b) => {
      let aValue, bValue;

      if (sortConfig.key === 'fullName') {
        aValue = `${a.fullName}`.toLowerCase();
        bValue = `${b.fullName}`.toLowerCase();
      } else if (sortConfig?.key === 'employmentDetails.designationName') {
        aValue = `${a.employmentDetails?.designationName || ''}`.toLowerCase();
        bValue = `${b.employmentDetails?.designationName || ''}`.toLowerCase();
      } else if (sortConfig?.key === 'employmentDetails.departmentName') {
        aValue = `${a.employmentDetails?.departmentName || ''}`.toLowerCase();
        bValue = `${b.employmentDetails?.departmentName || ''}`.toLowerCase();
      } else {
        return 0;
      }

      if (sortConfig.direction === 'ascending') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    return sortableEmployees;
  }, [allEmployees, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };


  const confirmStopEmployee = (employeeId) => {
    if (employeeToStop) {
      const inputData = {
        employeeId: employeeId,
        companyId: company?._id
      }
      dispatch(stopEmployee(inputData))
      setEmployeeToStop(null)
    }
  };

  const handleRemoveEmployee = (employee) => {
    if(employee?.employmentDetails?.endDate){
      setEmployeeToStop(employee)
    } else {
      setWarningRemove(employee)
    }
  }

  const closeModal = () => {
    setEmployeeToStop(null);
  };

  const closeWarningModal = () => {
    setWarningRemove(null);
  };

  return (
    <table className="table-auto rounded-2xl w-full">
      <thead>
        <tr className="h-20 bg-grayLight">
          <th className="rounded-s-xl text-base font-bold text-black">ID</th>
          <th className='text-base font-bold text-black'>Photo</th>
          <th onClick={() => handleSort('fullName')} className="cursor-pointer text-base font-bold text-black">
            Name
            <FontAwesomeIcon icon={getSortIcon('fullName')} size="sm" className="ml-1" />
          </th>
          <th
            onClick={() => handleSort('employmentDetails.designationName')}
            className="cursor-pointer text-base font-bold text-black"
          >
            Designation
            <FontAwesomeIcon icon={getSortIcon('employmentDetails.designationName')} size="sm" className="ml-1" />
          </th>
          <th onClick={() => handleSort('employmentDetails.departmentName')} className="text-base font-bold text-black cursor-pointer">
            Department <FontAwesomeIcon icon={getSortIcon('employmentDetails.departmentName')} />
          </th>
          <th className="text-base font-bold text-black">Status</th>
          <th className="rounded-e-xl text-base font-bold text-black">Actions</th>
        </tr>
      </thead>
      <tbody>
        {sortedEmployees?.map((employee, index) => {
          return (
            <tr className={`h-20`} key={index}>
              <td className="text-sm text-center text-black">{employee?.empId}</td>
              <td className="h-20">
                <div className="flex justify-center items-center">
                  <img
                    src={employee?.avatar ? employee?.avatar : defaultPic}
                    alt="profile pic"
                    className="w-8 h-8 rounded-full object-cover"
                  />
                </div>
              </td>
              <td className="h-20 text-sm text-center text-black">
                <span>{employee?.fullName}</span>
              </td>
              <td className="h-20 text-center text-sm text-black">{employee?.employmentDetails?.designationName}</td>
              <td className="h-20 text-center text-sm text-black">{employee?.employmentDetails?.departmentName}</td>
              <td className="h-20 w-24">
                <div className="flex justify-center">
                  <div className="bg-grayLight flex items-center justify-center w-fit px-1 rounded-full">
                    {employee?.status === 1 ? (
                      <div className="flex items-center gap-x-1">
                        <div className="w-2.5 h-2.5 rounded-full bg-green1"></div>
                        <span className="text-xs text-green1">Active</span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-x-1">
                        <div className="w-2.5 h-2.5 rounded-full bg-gray-500"></div>
                        <span className="text-xs text-gray-400">Inactive</span>
                      </div>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div className="flex justify-center gap-x-2 items-center">
                  <div
                    className="hover:bg-green-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md cursor-pointer"
                    onClick={() => navigate(`/employees/${employee?._id}`)}
                    title="Edit"
                  >
                    <Edit2 color="#16a34a" variant="Bold" size={20} />
                  </div>
                  <div
                    onClick={() => handleRemoveEmployee(employee)}
                    className="hover:bg-red-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md cursor-pointer"
                    title="Stop"
                  >
                    <StopCircle color="#ef4444" variant="Bold" size={20} />
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
      {employeeToStop && <ConfirmStopEmployeeModal onCancel={closeModal} onConfirm={() => confirmStopEmployee(employeeToStop?._id)}/>}
      {warningRemove && <WarningStopEmployeeModal onCancel={closeWarningModal} />}
    </table>
  );
};

export default EmployeeList;
