import { useEffect, useState } from 'react';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../layout/layout';
import Dropdown from '../../../components/dropdown';
import empty from '../../../assets/images/empty.png';
import { getPastEmployeesReport } from '../../../actions/reportAction';
import EmployeeList from './employeeList';

const PastEmployeesReport = () => {
  const dispatch = useDispatch();

  const [companyOptions, setCompanyOptions] = useState([]);

  const companies = useSelector((state) => state.company?.companies || []);
  const company = useSelector((state) => state.company?.company);
  const pastEmployees = useSelector((state) => state.reports?.pastEmployees) || [];

  console.log('pastEmployees: ', pastEmployees);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    const inputValue = {
      companyId: company?._id
    };
    dispatch(getPastEmployeesReport(inputValue));
  }, [company]);

  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    if (selectedValue) {
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };

  return (
    <Layout pageTitle={'Past Employees Report'} page={'Reports'} subPage={'Past Employees Report'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full min-h-[70vh] overflow-y-auto">
        <div>
          <div className="flex flex-col gap-4 gap-x-4 w-full">
            <div className="w-full lg:w-1/2">
              <label className="block text-black font-semibold mb-0.5">Select Company</label>
              {companyOptions.length > 0 && (
                <Dropdown
                  value={companyOptions?.find((option) => option.value === company?.companyName)}
                  onChange={handleCompanyChange}
                  options={companyOptions}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Select Company"
                  name="company"
                  id="company"
                />
              )}
            </div>
            <div>
              {pastEmployees?.length > 0 ? (
                <EmployeeList allEmployees={pastEmployees} company={company} />
              ) : (
                company?.companyName && (
                  <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                    <img src={empty} alt="empty data" />
                    <div className="mt-5 text-purple1 font-bold text-xl">No Past Employees</div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PastEmployeesReport;
