import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { fetchUserLogout } from './userAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_ALL_ORGANIZATION = 'FETCH_ALL_ORGANIZATION';
export const FETCH_ALL_COMPANY = 'FETCH_ALL_COMPANY';
export const FETCH_CURRENT_SUPER_ADMIN = 'FETCH_CURRENT_SUPER_ADMIN';
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';
export const SET_CURRENT_COMPANY= 'SET_CURRENT_COMPANY';
export const FETCH_ALL_ADMINS= 'FETCH_ALL_ADMINS';

export const fetchAllOrganizations = (data) => ({
  type: FETCH_ALL_ORGANIZATION,
  payload: data
});

export const fetchAllCompanies = (data) => ({
  type: FETCH_ALL_COMPANY,
  payload: data
});

export const fetchAllAdmins = (data) => ({
  type: FETCH_ALL_ADMINS,
  payload: data
});

export const fetchCurrentSuperAdmin = (data) => ({
  type: FETCH_CURRENT_SUPER_ADMIN,
  payload: data
});

export const setCurrentOrganization = (data) => ({
  type: SET_CURRENT_ORGANIZATION,
  payload: data
});

export const setCurrentCompany = (data) => ({
  type: SET_CURRENT_COMPANY,
  payload: data
});

export const superAdminLogin = (inputData, navigate) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/superadmin/login`, inputData);

      if (response.status === 200) {
        console.log('res: ', response);
        localStorage.setItem('token', response.data.token);
        //here I assume I am saving current superadmin state //if dont want, can change.
        await dispatch(fetchCurrentSuperAdmin(response.data)); 
        ToastBar.success(response.data.successMsg[0]);
        navigate('/superadmin/create-new-organization');
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const superAdminLogout = (navigate) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/superadmin/logout`, {}, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }
      );

      if (response.status === 200) {
        console.log('res: ', response);
        ToastBar.success(response.data.successMsg[0]);
        navigate('/superadmin-login');
        setTimeout(() => {
          dispatch(fetchUserLogout());
        }, 500);
        localStorage.removeItem('token');
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getAllAdmins = (inputData) => {
  console.log("get-all-admin-employees-by-company")
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/superadmin/get-all-admin-employees-by-company`, {
        params: { companyId: inputData },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('api res: ', response.data);
        dispatch(fetchAllAdmins(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getAllOrganization = () => {
  console.log("get-all-organizations")
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/superadmin/get-all-organizations`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('api res: ', response.data);
        dispatch(fetchAllOrganizations(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const addOrganization = (inputData, setAdd, resetForm) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/superadmin/create-new-organization`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        setAdd(false);
        resetForm()
        dispatch(getAllOrganization())
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getAllCompaniesByOrganization = (inputData) => {
  console.log(inputData)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/superadmin/get-all-companies-by-organization`, {
        params: { organizationId: inputData },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('api res: ', response.data);
        dispatch(fetchAllCompanies(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const addCompany = (inputData, setAdd, resetForm) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/superadmin/create-new-company`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        setAdd(false);
        resetForm()
        dispatch(getAllCompaniesByOrganization(inputData?.organizationId))
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const addAdmin = (inputData, setAdd, resetForm, companyId) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/superadmin/create-new-admin`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        setAdd(false);
        dispatch(getAllAdmins(companyId));
        resetForm()
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

