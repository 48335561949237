export const WarningStopEmployeeModal = ({ onCancel }) => {
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
        <div className="bg-white flex flex-col w-[600px] p-12 rounded-lg shadow-lg justify-center items-center">
          <h2 className="text-base text-center font-bold text-orange-500">Please fill up End Date in Employment Details for Employee before Removal.</h2>
          <div className="flex gap-5 mt-5">
            <button
              type="button"
              onClick={() => onCancel()}
              className="bg-red1 hover:bg-red1/90 text-white text-sm font-bold h-12 w-32 rounded-xl"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
