import { useDispatch, useSelector } from 'react-redux';
import LayoutWM from '../../layout/layoutWM';
import { useEffect, useState } from 'react';
import { getAllCompanies, getCompanyById, getCompanyDashboard } from '../../actions/companyAction';
import CompanyDropdown from '../../components/companyDropdown';
import empty from '../../assets/images/empty.png';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const company = useSelector((state) => state.company?.company);
  const dashboardDetails = useSelector((state) => state.company?.dashboardDetails);
  const companies = useSelector((state) => state.company?.companies || []);

  const [selectedCompany, setSelectedCompany] = useState(company?.companyName || '');
  const [companyValues, setCompanyValues] = useState('');

  console.log('dashboardDetails: ', dashboardDetails);

  useEffect(() => {
    dispatch(getCompanyDashboard({ companyId: company?._id }));
  }, [company]);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  const companyOptions =
    companies?.map((company) => ({
      companyId: company?._id,
      label: company?.companyName,
      value: company?.companyName
    })) || [];

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    setCompanyValues(selectedOption);
    if (selectedValue) {
      setSelectedCompany(selectedValue);
      console.log(selectedOption?.companyId);
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };
  return (
    <LayoutWM pageTitle={'Dashboard'} page={'Dashboard'} subPage={'Dashboard'}>
      <div className="w-1/2 mb-3">
        <label className="mb-2.5 block text-black font-bold">Select Company</label>
        {companyOptions.length > 0 && (
          <CompanyDropdown
            value={companyOptions.find((option) => option.value === selectedCompany)}
            onChange={handleCompanyChange}
            options={companyOptions}
            isClearable={false}
            placeholder="Select Company"
            name="allowanceType"
            id="allowanceType"
          />
        )}
      </div>
      <div className="min-h-96 bg-white h-full w-full p-6 rounded-2xl flex flex-col gap-y-2 relative">
        {company && Object.keys(company).length > 0 ? (
          <div className="flex flex-wrap gap-11">
            <div
              className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
              onClick={() => navigate('/employees')}
            >
              <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                {dashboardDetails?.totalEmployees}
              </div>
              <div className="text-black font-semibold group-hover:underline">Total Employees</div>
            </div>
            <div
              className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
              onClick={() => navigate('/employees/leave-applications')}
            >
              <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                {dashboardDetails?.pendingLeavesCount}
              </div>
              <div className="text-black font-semibold group-hover:underline">Pending Leaves</div>
            </div>
            <div
              className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
              onClick={() => navigate('/employees/no-pay-leave-applications')}
            >
              <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                {dashboardDetails?.pendingNoPayLeavesCount}
              </div>
              <div className="text-black font-semibold group-hover:underline">Pending No Pay Leaves</div>
            </div>
            <div
              className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
              onClick={() => navigate('/employees/medical-leave-applications')}
            >
              <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                {dashboardDetails?.pendingMedicalLeavesCount}
              </div>
              <div className="text-black font-semibold group-hover:underline">Pending Medical Leaves</div>
            </div>
            <div
              className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
              onClick={() => navigate('/employees/claim-submissions')}
            >
              <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                {dashboardDetails?.pendingClaimsCount}
              </div>
              <div className="text-black font-semibold group-hover:underline">Pending Claims Count</div>
            </div>
            <div
              className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
              onClick={() => navigate('/employees/timesheet-submissions')}
            >
              <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
                {dashboardDetails?.pendingTimesheetsCount}
              </div>
              <div className="text-black font-semibold group-hover:underline">Pending Timesheets</div>
            </div>
          </div>
        ) : (
          <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
            <img src={empty} alt="empty data" />
            <div className="mt-5 text-purple1 font-bold text-xl">No Company Selected</div>
          </div>
        )}
      </div>
    </LayoutWM>
  );
};

export default Dashboard;
