import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/dropdown';
import { applyNoPayLeaveSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import { ToastBar } from '../../../components/toastbar';
import { editNoPayLeave } from '../../../actions/nopayLeaveAction';

const EditNoPayLeave = ({ leave, singleEmployee, onCancel, employeeId, companyId, company }) => {
  console.log('edit leave: ', leave);
  const dispatch = useDispatch();

  const leaveDetails = singleEmployee.noPayLeaveDetails.noPayLeaves.find(
    (item) => item.noPayLeaveType === leave.noPayLeaveType
  );
  console.log('LeaveDetails: ', leaveDetails);

  const [numberOfDays, setNumberOfDays] = useState(0);

  const formik = useFormik({
    initialValues: {
      noPayLeaveType: leave?.noPayLeaveType || '',
      fromDate: leave?.fromDate || '',
      toDate: leave?.toDate || '',
      dailyRate: leave?.dailyRate || 0,
      noPayLeaveAmount: leave?.noPayLeaveAmount || 0
    },
    validationSchema: applyNoPayLeaveSchema,
    onSubmit: (values) => {
      handleEditLeave(values);
    }
  });

  // useEffect(() => {
  //   calculateDailyRate();
  // }, []);

  // const calculateDailyRate = () => {
  //   const currentMonth = new Date().toLocaleString('default', { month: 'long' });
  //   const currentMonthDays =
  //     singleEmployee?.workingHourDetails?.monthlyDays.find((month) => month.month === currentMonth)?.days || 0;
  //   const dailyRate = singleEmployee?.salaryDetails?.basicSalary / currentMonthDays;
  //   console.log('dailyRate: ', dailyRate);

  //   if (singleEmployee?.workingHourDetails?.workHoursType === 'Fixed' && dailyRate) {
  //     const formattedValue = parseFloat(dailyRate).toFixed(2);
  //     formik.setFieldValue('dailyRate', formattedValue);
  //   } else {
  //     formik.setFieldValue('dailyRate', 0);
  //   }
  // };

  useEffect(() => {
    if (formik.values.fromDate && formik.values.toDate) {
      calculateNumberOfDays(formik.values.fromDate, formik.values.toDate);
    }
  }, [formik.values.fromDate, formik.values.toDate, formik.values.dailyRate]);

  const calculateNumberOfDays = (fromDate, toDate) => {
    if (!fromDate || !toDate) return;

    const start = new Date(fromDate);
    const end = new Date(toDate);

    if (start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear()) {
      ToastBar.warning('Start date and end date must be in the same month.');
      return;
    }

    let currentDate = new Date(fromDate);
    const endDate = new Date(toDate);
    let dayCount = 0;

    const publicHolidayDates =
      company?.publicHolidays?.map((holiday) => new Date(holiday.startDate).toDateString()) || [];

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6 && !publicHolidayDates.includes(currentDate.toDateString())) {
        dayCount++;
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    const noPayLeaveAmount = formik.values.dailyRate * dayCount;
    const formattedValue = parseFloat(noPayLeaveAmount).toFixed(2);
    formik.setFieldValue('noPayLeaveAmount', formattedValue);

    setNumberOfDays(dayCount);
  };

  const handleEditLeave = async (values) => {
    const start = new Date(values.fromDate);
    const end = new Date(values.toDate);

    if (start.getMonth() !== end.getMonth() || start.getFullYear() !== end.getFullYear()) {
      ToastBar.warning('Start date and end date must be in the same month.');
      return;
    }

    const leaveApplication = {
      noPayLeaveType: values.noPayLeaveType,
      noPayLeaveCode: leave.noPayLeaveCode,
      fromDate: values.fromDate,
      toDate: values.toDate,
      numberOfDays,
      dailyRate: values.dailyRate,
      noPayLeaveAmount: values.noPayLeaveAmount,
      status: leave.status,
      _id: leave._id,
      companyId: companyId,
      employeeId: employeeId
    };

    console.log(leaveApplication, employeeId);

    const result = await dispatch(editNoPayLeave(leaveApplication));
    if (result.success) {
      onCancel();
    }
  };

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  return (
    <div className="bg-white p-6 rounded-2xl shadow-lg">
      <h4 className="text-2xl font-bold mb-5 text-black">Edit No Pay Leave</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-5 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Select No Pay Leave Type</label>
            <Dropdown
              options={[{ label: leave.noPayLeaveType, value: leave.noPayLeaveCode }]}
              value={{ label: formik.values.noPayLeaveType, value: leave.noPayLeaveCode }}
              onChange={(option) => {
                formik.setFieldValue('noPayLeaveType', option.label);
                formik.setFieldTouched('noPayLeaveType', true, false);
              }}
              placeholder="Select No Pay Leave Type"
              name="noPayLeaveType"
              id="noPayLeaveType"
            />
            {formik.touched.noPayLeaveType && formik.errors.noPayLeaveType ? (
              <div className="text-red-500 text-sm">{formik.errors.noPayLeaveType}</div>
            ) : null}
          </div>
          <div className="w-full lg:w-1/2"></div>
        </div>
        <div className="mb-7 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">From Date</label>
            <DatePicker
              value={formik.values.fromDate}
              onChange={(date) => {
                formik.setFieldValue('fromDate', date);
                formik.setFieldTouched('fromDate', true, false);
              }}
            />
            {formik.touched.fromDate && formik.errors.fromDate ? (
              <div className="text-red-500 text-sm">{formik.errors.fromDate}</div>
            ) : null}
          </div>

          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">To Date</label>
            <DatePicker
              value={formik.values.toDate}
              onChange={(date) => {
                formik.setFieldValue('toDate', date);
                formik.setFieldTouched('toDate', true, false);
              }}
            />
            {formik.touched.toDate && formik.errors.toDate ? (
              <div className="text-red-500 text-sm">{formik.errors.toDate}</div>
            ) : null}
          </div>
        </div>
        <div className="mb-7 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">
              Daily Rate ({singleEmployee?.salaryDetails?.currency})
            </label>
            <input
              type="number"
              placeholder="Daily Rate"
              className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
              name="dailyRate"
              onChange={formik.handleChange}
              onBlur={(e) => handleFloatChange(e.target.value, 'dailyRate')}
              value={formik.values.dailyRate}
            />
            {formik.touched.dailyRate && formik.errors.dailyRate ? (
              <div className="text-red-500 text-sm">{formik.errors.dailyRate}</div>
            ) : null}
          </div>
          <div className="w-full lg:w-1/2"></div>
        </div>
        <div className="mb-6">
          <div className="block text-base text-black font-bold mb-2">
            Number of Days: <span className="font-normal">{numberOfDays}</span>
          </div>
          <div className="block text-base text-black font-bold">
            Total Amount: <span className="font-normal">{formik.values.noPayLeaveAmount}</span>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-red1 hover:bg-red1/90 text-white font-semibold rounded-md px-4 py-2 mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button type="submit" className="bg-purple1 hover:bg-purple1/90 text-white font-bold rounded-lg px-4 py-2">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditNoPayLeave;
