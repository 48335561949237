import { combineReducers } from 'redux';
import generalReducer from './generalReducer';
import employeeReducer from './employeeReducer';
import companyReducer from './companyReducer';
import accountReducer from './accountReducer';
import claimReducer from './claimReducer';
import payrollReducer from './payrollReducer';
import notificationsReducer from './notificationsReducer';
import reportsReducer from './reportsReducer';
import superAdminReducer from './superadminReducer';

const rootReducer = combineReducers({
  general: generalReducer,
  employee: employeeReducer,
  company: companyReducer,
  account: accountReducer,
  claim: claimReducer,
  payroll: payrollReducer,
  user: payrollReducer,
  notifications: notificationsReducer,
  reports: reportsReducer,
  superAdmin: superAdminReducer,
});

export default rootReducer;
