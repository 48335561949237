import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { fetchPendingTimesheets, getCurrentEmployee, getEmployee } from './employeeAction';
import { createApproveTimesheetNotification, createDeleteTimesheetNotification, createRejectTimesheetNotification, createSubmitTimesheetNotification } from './notificationAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const addTimesheet = (employeeId, companyId, inputData, toggleFormVisibility, resetForm) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/add-timesheet`, { employeeId, companyId, inputData },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(employeeId));
        dispatch(createSubmitTimesheetNotification(employeeId, companyId, inputData, response.data.timesheetHistoryId));
        toggleFormVisibility();
        resetForm();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateTimesheet = (employeeId, companyId, inputData, onClose, resetForm) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/update-timesheet`, { employeeId, companyId, inputData },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(employeeId));
        onClose();
        resetForm();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteTimesheet = (inputData, setIsConfirmationModalOpen) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/delete-timesheet`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputData?.employeeId));
        dispatch(createDeleteTimesheetNotification(inputData))
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingTimesheets = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/timesheet/get-pending-timesheets`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingTimesheets res: ', response.data);
        dispatch(fetchPendingTimesheets(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingTimesheetsByApprover = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/timesheet/get-pending-timesheets-by-approver`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingleaves res: ', response.data);
        dispatch(fetchPendingTimesheets(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveTimesheet = (timesheets, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/approve-timesheet`, timesheets, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingTimesheets(companyId));
        dispatch(createApproveTimesheetNotification(timesheets));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectTimesheet = (timesheets, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/reject-timesheet`, timesheets, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingTimesheets(companyId));
        dispatch(createRejectTimesheetNotification(timesheets));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveTimesheetByApprover = (timesheets) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/approve-timesheet`, timesheets, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingTimesheetsByApprover());
        dispatch(createApproveTimesheetNotification(timesheets));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectTimesheetByApprover = (timesheets) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/reject-timesheet`, timesheets, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingTimesheetsByApprover());
        dispatch(createRejectTimesheetNotification(timesheets));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const addTimesheetByUser = (employeeId, companyId, inputData, toggleFormVisibility, resetForm) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/user-add-timesheet`, { employeeId, companyId, inputData },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        toggleFormVisibility();
        resetForm();
        dispatch(createSubmitTimesheetNotification(employeeId, companyId, inputData, response.data.timesheetHistoryId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateTimesheetByUser = (employeeId, companyId, inputData, onClose, resetForm) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/user-update-timesheet`, { employeeId, companyId, inputData },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        onClose();
        resetForm();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteTimesheetByUser = (inputData, setIsConfirmationModalOpen) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/timesheet/delete-timesheet`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(inputData?.employeeId));
        dispatch(createDeleteTimesheetNotification(inputData))
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};
