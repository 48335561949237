import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SuperAdminRoute = () => {
    const superAdmin = useSelector((state) => state?.superAdmin?.superAdmin);

    if (!superAdmin) {
        return <Navigate to="/login" />;
    }

    return superAdmin?.role === "SuperAdmin" ? <Outlet /> : <Navigate to="/access-denied" />;
};

export default SuperAdminRoute;