import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { addCompany } from '../../actions/companyAction';
import { addCompanySchema } from '../../validations/validationSchemas';
import { useNavigate } from 'react-router-dom';

const AddCompany = ({ setAddCompanyModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentCompany = useSelector((state) => state?.company?.company);

  const formik = useFormik({
    initialValues: {
      organizationId: currentCompany?.organization,
      companyName: ''
    },
    validationSchema: addCompanySchema,
    onSubmit: (values) => {
      dispatch(addCompany(values, setAddCompanyModal, navigate));
    }
  });

  const onCancel = () => {
    formik.resetForm();
    setAddCompanyModal(false);
  };

  return (
    <div className="bg-white p-6 rounded-2xl shadow-lg mb-5">
      <h4 className="text-2xl font-bold mb-5 text-black">Add Company</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Company Name</label>
              <input
                type="text"
                placeholder="Company Name"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="companyName"
                onChange={formik.handleChange}
                value={formik.values.companyName}
              />
              {formik.touched.organizationId && formik.errors.organizationId ? (
                <div className="text-red-500 text-sm">{formik.errors.organizationId}</div>
              ) : null}
              {formik.touched.companyName && formik.errors.companyName ? (
                <div className="text-red-500 text-sm">{formik.errors.companyName}</div>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end mt-6 gap-x-2">
            <button
              type="button"
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-32 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Add Company
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCompany;
