import { useFormik } from 'formik';
import { useState, useRef, useEffect } from 'react';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/dropdown';
import { submitClaimSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import { MinusCirlce, DocumentUpload } from 'iconsax-react';
import { editClaim } from '../../../actions/claimAction';
import { ToastBar } from '../../../components/toastbar';

const EditClaim = ({ claim, onCancel, employee, companyId }) => {
  console.log('edit claim: ', claim);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [claimFile, setClaimFile] = useState(claim?.claimFile || null);

  const claimDetails = employee.claimDetails.claims.find((item) => item.claimType === claim.claimType);
  console.log('claimDetails: ', claimDetails);

  const [balanceClaim, setBalanceClaim] = useState(claimDetails?.claimBalance);
  const [isClaimAmountChanged, setIsClaimAmountChanged] = useState(false);
  const [isInitialized, setInitialized] = useState(false);

  const formik = useFormik({
    initialValues: {
      claimType: claim?.claimType || '',
      claimDate: claim?.claimDate || '',
      claimAmount: claim?.claimAmount || '',
      claimFile: claim?.claimFile || '',
      claimRemark: claim?.claimRemark || ''
    },
    validationSchema: submitClaimSchema,
    onSubmit: (values) => {
      handleEditClaim(values);
    }
  });

  console.log('balanceClaim: ', balanceClaim);

  useEffect(() => {
    if (isInitialized && claim?.status === 2) {
      setBalanceClaim(balanceClaim + claim.claimAmount);
    }
  }, [employee, isInitialized]);

  useEffect(() => {
    if (!isClaimAmountChanged) {
      setIsClaimAmountChanged(true);
    }

    if (!isInitialized) {
      setInitialized(true);
    }
  }, []);

  console.log('formik: ', formik.values);

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setClaimFile(file);
      formik.setFieldValue('claimFile', file);
    }
  };

  const handleClearFile = () => {
    setClaimFile(null);
    formik.setFieldValue('claimFile', null);
    fileInputRef.current.value = '';
  };

  // const handleEditClaim = async (values) => {
  //   const claimApplication = {
  //     claimType: values.claimType,
  //     claimCode: claim.claimCode,
  //     claimDate: values.claimDate,
  //     claimAmount: values.claimAmount,
  //     claimFile: values.claimFile,
  //     status: 'Pending',
  //     _id: claim._id,
  //     companyId: companyId,
  //     employeeId: employeeId
  //   };

  //   console.log(claimApplication, employeeId)

  //   const result = await dispatch(editClaim(claimApplication));
  //   if (result.success) {
  //     onCancel(); // Close the edit form
  //   }
  // };

  const handleEditClaim = async (values) => {
    if (claimDetails.isLimit === 1 && formik.values.claimAmount > balanceClaim) {
      ToastBar.warning('Claim Amount Exceeds Claim Balance');
      return;
    }

    let formData;

    if (claimFile instanceof File) {
      formData = new FormData();
      formData.append('claimType', values.claimType);
      formData.append('claimCode', claim.claimCode);
      formData.append('claimAmount', values.claimAmount);
      formData.append('claimDate', values.claimDate);
      formData.append('claimRemark', values.claimRemark);
      formData.append('_id', claim._id);
      formData.append('status', claim.status);
      formData.append('claimId', claim.claimId);
      formData.append('companyId', companyId);
      formData.append('employeeId', employee?._id);
      formData.append('file', claimFile, `claimFile_${employee?._id}`);
    } else {
      formData = {
        claimType: values.claimType,
        claimCode: claim.claimCode,
        claimDate: values.claimDate,
        claimAmount: values.claimAmount,
        claimRemark: values.claimRemark,
        status: claim.status,
        _id: claim._id,
        companyId: companyId,
        employeeId: employee?._id,
        claimFile: claimFile || '' // Use the existing file URL
      };
    }

    const result = await dispatch(editClaim(formData, employee?._id));
    if (result.success) {
      onCancel(); // Close the edit form
    }
  };

  return (
    <div className="bg-white p-6 rounded-2xl shadow-lg">
      <h4 className="text-2xl font-bold mb-5 text-black">Edit Claim</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-5 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Select Claim Type</label>
            <Dropdown
              options={[{ label: claim.claimType, value: claim.claimCode }]}
              value={{ label: formik.values.claimType, value: claim.claimCode }}
              onChange={(option) => {
                formik.setFieldValue('claimType', option.label);
                formik.setFieldTouched('claimType', true, false);
              }}
              placeholder="Select Claim Type"
              name="claimType"
              id="claimType"
            />
            {formik.touched.claimType && formik.errors.claimType ? (
              <div className="text-red-500 text-sm">{formik.errors.claimType}</div>
            ) : null}
          </div>
          <div className="w-full lg:w-1/2"></div>
        </div>
        <div className="mb-7 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Claim Date</label>
            <DatePicker
              value={formik.values.claimDate}
              onChange={(date) => {
                formik.setFieldValue('claimDate', date);
                formik.setFieldTouched('claimDate', true, false);
              }}
            />
            {formik.touched.claimDate && formik.errors.claimDate ? (
              <div className="text-red-500 text-sm">{formik.errors.claimDate}</div>
            ) : null}
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <label className="mb-2.5 block text-black font-bold">Amount ({employee?.salaryDetails?.currency})</label>
          <input
            type="number"
            placeholder="Amount"
            className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
            name="claimAmount"
            onChange={formik.handleChange}
            onBlur={(e) => {
              if (claimDetails.isLimit === 1 && e.target.value > balanceClaim) {
                ToastBar.warning('Claim Amount Exceeds Claim Balance');
              }
              formik.setFieldValue('claimAmount', parseFloat(e.target.value).toFixed(2));
            }}
            value={formik.values.claimAmount}
          />
          {formik.touched.claimAmount && formik.errors.claimAmount ? (
            <div className="text-red-500 text-sm">{formik.errors.claimAmount}</div>
          ) : null}
        </div>

        <div className="w-full lg:w-1/2 mt-5">
          <label className="mb-2.5 block text-black font-bold">Upload Document</label>
          <div className="relative flex items-center">
            <input
              ref={fileInputRef}
              type="file"
              accept=".jpeg, .jpg, .png, .pdf"
              onChange={handleUploadFile}
              style={{ display: 'none' }}
            />
            <input
              type="text"
              placeholder="No file chosen"
              className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 pr-12 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
              value={claimFile ? claimFile.name || claimFile : ''}
              readOnly
              onClick={() => fileInputRef.current.click()}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              {claimFile ? (
                <MinusCirlce
                  onClick={handleClearFile}
                  color="#C84040"
                  variant="Bold"
                  size={20}
                  className="cursor-pointer"
                  title="Clear"
                />
              ) : (
                <DocumentUpload
                  onClick={() => fileInputRef.current.click()}
                  color="#555555"
                  variant="Bold"
                  size={20}
                  className="cursor-pointer"
                  title="Upload file"
                />
              )}
            </div>
          </div>
          {formik.touched.claimFile && formik.errors.claimFile ? (
            <div className="text-red-500 text-sm">{formik.errors.claimFile}</div>
          ) : null}
        </div>
        <div className="w-full lg:w-1/2 mt-5">
          <label className="mb-2.5 block text-black font-bold">Remark</label>
          <textarea
            type="text"
            placeholder="Remark"
            className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
            name="claimRemark"
            rows={6}
            onChange={formik.handleChange}
            value={formik.values.claimRemark}
          />
          {formik.touched.claimRemark && formik.errors.claimRemark ? (
            <div className="text-red-500 text-sm">{formik.errors.claimRemark}</div>
          ) : null}
        </div>
        <div className="flex justify-end mt-6">
          <button
            type="button"
            className="bg-red1 hover:bg-red1/90 text-white font-semibold rounded-md px-4 py-2 mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button type="submit" className="bg-purple1 hover:bg-purple1/90 text-white font-bold rounded-lg px-4 py-2">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditClaim;
