// src/hooks/useListenNotifications.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSocketContext } from '../context/socketContext';
import { useAuthContext } from '../context/authContext';
import { fetchNotifications } from '../actions/notificationAction';
import { ToastBar } from '../components/toastbar';


const useListenNotifications = () => {
    const { socket } = useSocketContext();
    const { authUser } = useAuthContext();
    const dispatch = useDispatch();

    useEffect(() => {
        if (socket) {
            const handleNewNotification = (notification) => {
               
                if (authUser.id == notification.receiverId) {
                    dispatch(fetchNotifications());
                    ToastBar.success("New Notification!");
                }
            };

            socket.on('newNotification', handleNewNotification);

            return () => {
                socket.off('newNotification', handleNewNotification);
            };
        }
    }, [socket, dispatch]);
};

export default useListenNotifications;
