import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrowLeft.svg';
import { Building, Buildings2, LogoutCurve, User } from 'iconsax-react';
import HRLogo from '../../assets/logo/hrLogo2.png';
import { useDispatch } from 'react-redux';
import { superAdminLogout } from '../../actions/superadminAction';

const SuperAdminSidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  const handleLogout = () => {
    dispatch(superAdminLogout(navigate));
  };

  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
    localStorage.setItem('sidebar', !sidebarOpen);
  };

  return (
    <aside
      className={`left-0 top-0 z-[9999] flex h-full ${
        sidebarOpen ? 'w-[310px]' : 'w-[75px]'
      } flex-col overflow-y-hidden bg-[#313131]`}
    >
      <div className="flex items-center px-4 pt-10 gap-x-4 relative">
        <div onClick={handleToggle} className="cursor-pointer flex-shrink-0 ml-1">
          <img
            src={HRLogo}
            alt="company logo"
            className="w-8 h-8 flex-shrink-0"
          />
        </div>

        {sidebarOpen && (
          <div className="text-lg text-white font-bold text-left max-w-48">
            HRSimplify
          </div>
        )}
        {sidebarOpen && (
          <div className="absolute right-5">
            <button onClick={handleToggle} aria-controls="sidebar" aria-expanded={sidebarOpen} className="block">
              <ArrowLeft />
            </button>
          </div>
        )}
      </div>

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear justify-between h-full">
        <nav className={`py-4 mt-5 ${sidebarOpen ? 'px-2' : 'px-2'}`}>
          <div>
            <ul className="mb-6 flex flex-col gap-3">
              <li>
                <NavLink
                  to="/superadmin/create-new-organization"
                  className={({ isActive }) =>
                    'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                    (isActive && '!text-white')
                  }
                >
                  <Buildings2 />
                  {sidebarOpen && <span>Create New Organization</span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/superadmin/create-new-company"
                  className={({ isActive }) =>
                    'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                    (isActive && '!text-white')
                  }
                >
                  <Building />
                  {sidebarOpen && <span>Create New Company</span>}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/superadmin/create-new-admin"
                  className={({ isActive }) =>
                    'group relative flex items-center gap-2.5 h-7 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                    (isActive && '!text-white')
                  }
                >
                  <User />
                  {sidebarOpen && <span>Create New Admin Employee</span>}
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
        <div>
          <NavLink
            to="#"
            className={`group relative flex items-center gap-2.5 h-10 rounded-sm pb-12 px-7 font-medium text-bodydark1 duration-300 ease-in-out`}
            onClick={() => handleLogout()}
          >
            <LogoutCurve color="rgba(255, 255, 255, 0.9)" variant="Outline" size={22} className="-ml-1.5" />
            {sidebarOpen && <span>Log out</span>}
          </NavLink>
        </div>
      </div>
    </aside>
  );
};

export default SuperAdminSidebar;
