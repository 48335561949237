import React from 'react';
import { getStatusLabel } from '../constant/functions';
import { format } from 'date-fns';

const AllTimesheetViewModal = ({ isOpen, onClose, timesheets, currency }) => {
  if (!isOpen) return null;

  console.log('timesheet view: ', timesheets);
  const convertMinutesToTimeFormat = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12; // Converts 0 to 12 for midnight
    const formattedMinutes = minutes.toString().padStart(2, '0'); // Adds leading zero if necessary

    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  const convertMinutesToHours = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const hoursText = hours === 1 ? '1 hour' : `${hours} hours`;
    const minutesText = minutes === 1 ? '1 minute' : `${minutes} minutes`;

    return `${hoursText} ${minutesText}`;
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-start z-[9999] p-5 overflow-y-auto">
        <div className="bg-white flex flex-col w-full lg:w-[800px] min-h-[500px] p-10 rounded-lg shadow-lg justify-start items-center">
          <div className="flex flex-wrap justify-between w-full items-center mb-5">
            <h2 className="text-2xl font-bold text-black">All Timesheets</h2>
            <button
              type="button"
              onClick={() => onClose()}
              className="hover:bg-red1 text-black hover:text-white text-sm font-bold h-8 w-8 rounded-xl"
            >
              X
            </button>
          </div>
          <div className="flex flex-col gap-y-5 w-full items-center">
            {timesheets?.map((timesheet, index) => {
              const formattedCreatedDate = !Number.isNaN(new Date(timesheet?.createdDate).getTime())
                ? format(new Date(timesheet?.createdDate), 'dd-MM-yyyy HH:mm')
                : 'Invalid Date';

              const formattedDate = !Number.isNaN(new Date(timesheet?.date).getTime())
                ? format(new Date(timesheet?.date), 'dd-MM-yyyy HH:mm')
                : 'Invalid Date';

              const { label, textColor, bgColor } = getStatusLabel(timesheet?.status);

              return (
                <div className="w-11/12 lg:w-[600px] shadow-lg p-5" key={index}>
                  <h1 className="text-sm text-black mt-5">
                    <span className="font-bold">Date: </span>
                    {formattedDate}
                  </h1>
                  <h1 className="text-sm text-black mt-2">
                    <span className="font-bold">Start Time: </span>
                    {convertMinutesToTimeFormat(timesheet?.startTime)}
                  </h1>
                  <h1 className="text-sm text-black mt-2">
                    <span className="font-bold">End Time: </span>
                    {convertMinutesToTimeFormat(timesheet?.endTime)}
                  </h1>
                  <h1 className="text-sm text-black mt-2">
                    <span className="font-bold">Hours: </span>
                    {convertMinutesToHours(timesheet?.hours)}
                  </h1>
                  <h1 className="text-sm text-black mt-2">
                    <span className="font-bold">Hourly rate ({currency}): </span>
                    {new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(timesheet?.hourlyRate)}
                  </h1>
                  <h1 className="text-sm text-black mt-2">
                    <span className="font-bold">Amount ({currency}): </span>
                    {new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(timesheet?.timesheetAmount)}
                  </h1>
                  {/* <h1 className="text-sm text-black mt-2 flex gap-x-2">
                    <span className="font-bold">Status: </span>
                    <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                      <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                      <span className={`text-xs ${textColor}`}> {label}</span>
                    </div>
                  </h1> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTimesheetViewModal;
