import { useLocation, useNavigate } from 'react-router-dom';
import { DirectboxReceive, Eye } from 'iconsax-react';
import { useState } from 'react';
import { useFormik } from 'formik';
import { payrollEmployeeEditSchema } from '../../../validations/validationSchemas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Dropdown from '../../../components/dropdown';
import { bankOptions } from '../../../constant/dropdownConstOptions';
import AllTimesheetViewModal from '../../../components/allTimesheetViewModal';
import UserLayoutWM from '../../../layout/userLayoutWM';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const UserPayslipView = () => {
  const location = useLocation();
  const { employee, selectedPayslip } = location.state || {};

  const navigate = useNavigate();

  console.log('employee: ', employee)
  console.log('selectedPayslip: ', selectedPayslip)

  const [isEdit, setIsEdit] = useState(false);
  const [isTimesheetModalOpen, setTimeSheetModalOpen] = useState(null);

  const formik = useFormik({
    initialValues: {
      fullName: employee?.employee?.fullName,
      designation: employee?.employee?.employmentDetails?.designationName,
      department: employee?.employee?.employmentDetails?.departmentName,
      bank: employee?.salary[0]?.bank || '',
      bankAccountNum: employee?.salary[0]?.bankAccountNum || '',
      currency: employee?.salary[0]?.currency || '',
      basicSalary: employee?.salary[0]?.basicSalary || 0,
      timeSheets: employee?.salary[0]?.timeSheets || [],
      payMethod: employee?.salary[0]?.payMethod || '',
      employeeCPFPercentage: employee?.salary[0]?.employeeCPFPercentage || 20,
      employerCPFPercentage: employee?.salary[0]?.employerCPFPercentage || 17,
      employeeCPF: employee?.salary[0]?.employeeCPF || 0,
      employerCPF: employee?.salary[0]?.employerCPF || 0,
      grossSalary: employee?.salary[0]?.grossSalary || 0,
      totalCPFContribution: employee?.salary[0]?.totalCPFContribution || 0,
      netSalary: employee?.salary[0]?.netSalary || 0,
      deductions: employee?.deductions || [],
      allowances: employee?.allowances || [],
      claims: employee?.claims || [],
      noPayLeaves: employee?.noPayLeaves || [],
      bonuses: employee?.bonuses || [],
      cpf: {
        employeeCPF: employee?.cpf[0]?.employeeCPF,
        employerCPF: employee?.cpf[0]?.employerCPF,
        CPFContribution: employee?.cpf[0]?.CPFContribution
      }
    },
    validationSchema: payrollEmployeeEditSchema,
    onSubmit: async (values) => {
      console.log('edit payroll employee: ', values);
      // dispatch(updatePayrollEmployee(values, navigate));
    }
  });

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };


  const generatePayslipPdf = async (employee, selectedPayslip) => {
    const currency = employee?.salary[0]?.currency || 'SGD';

    const formatAmount = (amount) => {
      return `${currency} ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount || 0)}`;
    };

    const formatDate = (date) => {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    };
    const dd = {
      content: [
        {
          columns: [
            [
              { text: selectedPayslip.company.companyName, style: 'header', alignment: 'center', bold: true },
              {
                text: `Payslip for ${selectedPayslip.month} ${selectedPayslip.year}`,
                style: 'subheader',
                alignment: 'center',
                bold: true
              }
            ]
          ],
          columnGap: 50
        },

        {
          style: 'tableExample',
          table: {
            widths: ['auto', '*', 'auto', '*'],
            body: [
              [
                { text: 'Employee Name:', bold: true },
                employee?.employee?.fullName || '',
                '',
                {
                  text: [
                    { text: 'Date: ', bold: true },
                    { text: formatDate(selectedPayslip?.payRollCutOffDate), bold: false }
                  ],
                  alignment: 'right'
                }
              ],
              [
                { text: 'Department:', bold: true },
                employee?.employee?.employmentDetails?.departmentName || '',
                '',
                ''
              ],
              [
                { text: 'Designation:', bold: true },
                employee?.employee?.employmentDetails?.designationName || '',
                '',
                ''
              ]
            ]
          },
          layout: 'noBorders',
          margin: [0, 20, 0, 0]
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*'],
            body: [
              [
                [
                  { text: 'Salary:', bold: true, decoration: 'underline' },
                  { text: '', margin: [0, 10, 0, 0] },
                  { text: `Basic: ${formatAmount(employee?.salary?.[0]?.basicSalary)}`, bold: false },
                  employee?.noPayLeaves?.[0] ? { text: '', margin: [0, 20, 0, 0] } : null,
                  employee?.noPayLeaves?.[0] ? { text: `No Pay Leaves: ${formatAmount(employee?.noPayLeaves?.[0]?.totalNoPayLeaveAmount)}`, bold: false } : null,
                  { text: '', margin: [0, 10, 0, 0] },
                  employee?.employee?.salaryDetails?.ot === 1 ? { text: 'Overtime:', bold: true, decoration: 'underline', margin: [0, 5, 0, 0] } : null,
                  employee?.employee?.salaryDetails?.ot === 1 ? { text: `Total Overtime: ${formatAmount(employee?.salary?.[0]?.timeSheets?.[0]?.totalTimesheetAmount)}`, margin: [0, 10, 0, 0] } : null,
                  employee?.employee?.salaryDetails?.ot === 1 ? { text: `Net Overtime: ${formatAmount(employee?.salary?.[0]?.timeSheets?.[0]?.netTimesheetAmount)}`, margin: [0, 10, 0, 0] } : null,
                  employee?.employee?.salaryDetails?.ot === 1 ? { text: '', margin: [0, 10, 0, 0] } : null,
                  { text: 'Allowances:', bold: true, decoration: 'underline', margin: [0, 5, 0, 0] },
                  employee?.allowances?.[0] ? { text: `Total Allowances: ${formatAmount(employee?.allowances?.[0]?.totalAllowanceAmount)}`, margin: [0, 10, 0, 0] } : null,
                  employee?.allowances?.[0] ? { text: `Net Allowances: ${formatAmount(employee?.allowances?.[0]?.netAllowanceAmount)}`, margin: [0, 10, 0, 0] } : null,
                  { text: '', margin: [0, 10, 0, 0] },
                  { text: 'Claims:', bold: true, decoration: 'underline', margin: [0, 5, 0, 0] },
                  employee?.claims?.[0] ? { text: `Total Claims: ${formatAmount(employee?.claims?.[0]?.totalClaimAmount)}`, margin: [0, 10, 0, 0] } : null,
                  { text: '', margin: [0, 10, 0, 0] },
                  { text: 'Bonuses:', bold: true, decoration: 'underline', margin: [0, 5, 0, 0] },
                  employee?.bonuses?.[0] ? { text: `Total Bonus: ${formatAmount(employee?.bonuses?.[0]?.totalBonusAmount)}`, margin: [0, 10, 0, 0] } : null,
                  employee?.bonuses?.[0] ? { text: `Net Bonus: ${formatAmount(employee?.bonuses?.[0]?.netBonusAmount)}`, margin: [0, 10, 0, 0] } : null,
                ],
                [
                  { text: 'Deductions:', bold: true, decoration: 'underline', margin: [0, 0, 0, 0] },
                  employee?.deductions?.[0]?.deductions?.map((deduction) => {
                    return {
                      text: `${deduction?.deductionType}: ${formatAmount(deduction?.deductionAmount)}`,
                      margin: [0, 10, 0, 0]
                    };
                  }),
                  { text: '', margin: [0, 10, 0, 0] },
                  {
                    text: [
                      { text: 'Employee CPF:', margin: [0, 0, 0, 0] },
                      { text: ` ${formatAmount(employee?.cpf?.[0]?.employeeCPF)}`, margin: [4, 0, 0, 0] }
                    ]
                  },
                  { text: '', margin: [0, 10, 0, 0] },
                  { text: 'Contributions:', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
                  {
                    text: [
                      { text: 'Employer CPF:', margin: [0, 0, 0, 0] },
                      { text: ` ${formatAmount(employee?.cpf?.[0]?.employerCPF)}`, margin: [4, 0, 0, 0] }
                    ]
                  }
                ]
              ],
              [
                [
                  {
                    text: [
                      { text: 'Salary Credited By:', margin: [0, 0, 0, 0] },
                      { text: ` ${employee?.salary?.[0]?.payMethod}`, margin: [4, 0, 0, 0] }
                    ]
                  },
                  {
                    text: [
                      { text: 'Bank:', margin: [0, 0, 0, 0] },
                      { text: ` ${employee?.salary?.[0]?.bank}`, margin: [4, 0, 0, 0] }
                    ],
                    margin: [0, 10, 0, 0]
                  },
                  {
                    text: [
                      { text: 'Bank Acc. No:', margin: [0, 0, 0, 0] },
                      { text: ` ${employee?.salary?.[0]?.bankAccountNum}`, margin: [4, 0, 0, 0] }
                    ],
                    margin: [0, 10, 0, 0]
                  }
                ],
                [
                  {
                    text: [
                      { text: 'Net Salary:', bold: true, decoration: 'underline', margin: [0, 0, 0, 0] },
                      { text: ` ${formatAmount(employee?.salary?.[0]?.netSalary)}`, margin: [4, 0, 0, 0] }
                    ]
                  }
                ]
              ]
            ]
          },
          layout: {
            hLineWidth: function (i, node) {
              return 1;
            },
            vLineWidth: function (i, node) {
              return 1;
            },
            hLineColor: function (i, node) {
              return 'gray';
            },
            vLineColor: function (i, node) {
              return 'gray';
            },
            paddingLeft: function (i, node) {
              return 10;
            },
            paddingRight: function (i, node) {
              return 10;
            },
            paddingTop: function (i, node) {
              return 10;
            },
            paddingBottom: function (i, node) {
              return 10;
            }
          },
          margin: [0, 20, 0, 0]
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        subheader: {
          fontSize: 15
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black'
        },
        footer: {
          italics: true,
          alignment: 'center',
          margin: [0, 20, 0, 0]
        }
      },
      defaultStyle: {
        columnGap: 20
      }
    };

    if (employee?.employee?.leaveDetails?.leaves?.length > 0) {
      dd.content.push(
        {
          text: 'Leaves',
          style: 'subheader',
          alignment: 'left',
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 10]
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*'], // Four equal-width columns
            body: [
              // Table Header
              [
                { text: 'Leave Type', bold: true, alignment: 'center', fontSize: 10 },
                { text: 'Leave Code', bold: true, alignment: 'center', fontSize: 10 },
                { text: 'Total Days', bold: true, alignment: 'center', fontSize: 10 },
                { text: 'Balance Days', bold: true, alignment: 'center', fontSize: 10 }
              ],
              // Table Rows
              ...employee.employee.leaveDetails.leaves.map((leave) => [
                { text: leave.leaveType || 'N/A', alignment: 'center', fontSize: 10 }, // Leave Type
                { text: leave.leaveCode || 'N/A', alignment: 'center', fontSize: 10 }, // Leave Code
                { text: leave.totalDays || 0, alignment: 'center', fontSize: 10 }, // Total Days
                { text: leave.balanceDays || 0, alignment: 'center', fontSize: 10 } // Balance Days
              ])
            ]
          },
          layout: {
            hLineWidth: (i, node) => 1,
            vLineWidth: (i, node) => 1,
            hLineColor: (i, node) => 'gray',
            vLineColor: (i, node) => 'gray',
            paddingLeft: (i, node) => 5,
            paddingRight: (i, node) => 5,
            paddingTop: (i, node) => 5,
            paddingBottom: (i, node) => 5
          },
          margin: [0, 0, 0, 10]
        }
      );
    }    

    // Medical Leaves Section
    if (employee?.employee?.medicalLeaveDetails?.medicals?.length > 0) {
      dd.content.push(
        {
          text: 'Medical Leaves',
          style: 'subheader',
          alignment: 'left',
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 10]
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*'], // Four equal-width columns
            body: [
              // Table Header
              [
                { text: 'Medical Type', bold: true, alignment: 'center', fontSize: 10 }, // Header Font Size
                { text: 'Medical Code', bold: true, alignment: 'center', fontSize: 10 },
                { text: 'Total Days', bold: true, alignment: 'center', fontSize: 10 },
                { text: 'Balance Days', bold: true, alignment: 'center', fontSize: 10 }
              ],
              // Table Rows
              ...employee.employee?.medicalLeaveDetails?.medicals?.map((medical) => [
                { text: medical.medicalType || 'N/A', alignment: 'center', fontSize: 10 }, // Data Font Size
                { text: medical.medicalCode || 'N/A', alignment: 'center', fontSize: 10 },
                { text: medical.totalDays || 0, alignment: 'center', fontSize: 10 },
                { text: medical.balanceDays || 0, alignment: 'center', fontSize: 10 }
              ])
            ]
          },
          layout: {
            hLineWidth: (i, node) => 1,
            vLineWidth: (i, node) => 1,
            hLineColor: (i, node) => 'gray',
            vLineColor: (i, node) => 'gray',
            paddingLeft: (i, node) => 5,
            paddingRight: (i, node) => 5,
            paddingTop: (i, node) => 5,
            paddingBottom: (i, node) => 5
          },
          margin: [0, 0, 0, 10]
        }
      );
    }

    // Claims Section (Only show claims with isLimit = 1)
    if (employee?.employee?.claimDetails?.claims?.length > 0) {
      const filteredClaims = employee.employee?.claimDetails?.claims.filter((claim) => claim.isLimit === 1);
      if (filteredClaims.length > 0) {
        dd.content.push(
          {
            text: 'Claims',
            style: 'subheader',
            alignment: 'left',
            fontSize: 12,
            bold: true,
            margin: [0, 20, 0, 10]
          },
          {
            style: 'tableExample',
            table: {
              widths: ['*', '*', '*', '*'], // Four equal-width columns
              body: [
                // Table Header
                [
                  { text: 'Claim Type', bold: true, alignment: 'center', fontSize: 10 },
                  { text: 'Claim Code', bold: true, alignment: 'center', fontSize: 10 },
                  { text: 'Claim Limit', bold: true, alignment: 'center', fontSize: 10 },
                  { text: 'Claim Balance', bold: true, alignment: 'center', fontSize: 10 },
                ],
                // Table Rows
                ...filteredClaims.map((claim) => [
                  { text: claim.claimType || 'N/A', alignment: 'center', fontSize: 10 }, // Claim Type
                  { text: claim.claimCode || 'N/A', alignment: 'center', fontSize: 10 }, // Claim Code
                  { text: `${formatAmount(claim.claimLimit)}` || '0.00', alignment: 'center', fontSize: 10 }, // Claim Limit
                  { text: `${formatAmount(claim.claimBalance)}` || '0.00', alignment: 'center', fontSize: 10 } // Claim Balance
                ])
              ]
            },
            layout: {
              hLineWidth: (i, node) => 1,
              vLineWidth: (i, node) => 1,
              hLineColor: (i, node) => 'gray',
              vLineColor: (i, node) => 'gray',
              paddingLeft: (i, node) => 5,
              paddingRight: (i, node) => 5,
              paddingTop: (i, node) => 5,
              paddingBottom: (i, node) => 5
            },
            margin: [0, 0, 0, 10]
          }
        );
      }
    }    

    dd.content.push({
      text: 'This is a computer-generated slip. No signature is required.',
      style: 'footer',
      alignment: 'center',
      margin: [0, 20, 0, 0]
    });

    pdfMake
      .createPdf(dd)
      .download(`${employee?.employee?.fullName}-salary-slip-${selectedPayslip.month}-${selectedPayslip.year}.pdf`);
  };
  
  return (
    <UserLayoutWM pageTitle={`View Slip`} page={'Payroll'} subPage={`View Slip`}>
      <div className="h-full pb-20 mb-10">
        <div className="bg-white min-h-full p-6 flex flex-col justify-start rounded-2xl">
          <div className="w-full flex justify-between mb-4 items-center">
            <div className="text-2xl font-bold text-black">Employee Payment Details</div>
            <div className="flex gap-x-2">
              <button
                type="button"
                onClick={() => navigate('/user/payslip')}
                className={`bg-red1 hover:bg-red1/90 text-white  font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3`}
              >
                <span>Back</span>
              </button>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-6">
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Full Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="fullName"
                    onChange={formik.handleChange}
                    value={formik.values.fullName}
                    disabled
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Designation</label>
                  <input
                    type="text"
                    placeholder="Designation"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="designation"
                    onChange={formik.handleChange}
                    value={formik.values.designation}
                    disabled
                  />
                  {formik.touched.designation && formik.errors.designation ? (
                    <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Department</label>
                  <input
                    type="text"
                    placeholder="Department"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="department"
                    onChange={formik.handleChange}
                    value={formik.values.department}
                    disabled
                  />
                  {formik.touched.department && formik.errors.department ? (
                    <div className="text-red-500 text-sm">{formik.errors.department}</div>
                  ) : null}
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center pt-5">
                <span className="w-fit">Salary Details </span>
                <div className="border-b border-2 flex ml-5 flex-grow"></div>
              </div>
              <div className="flex flex-col gap-6 lg:flex-row py-5">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">
                    {employee?.employee?.workingHourDetails?.workHoursType === 'Hourly'
                      ? 'Total Timesheet Amount'
                      : 'Basic Salary'}{' '}
                    ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Basic Salary"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="basicSalary"
                    onChange={formik.handleChange}
                    onBlur={(e) => handleFloatChange(e.target.value, 'basicSalary')}
                    value={new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(formik.values.basicSalary)}
                    disabled={!isEdit}
                  />
                  {formik.touched.basicSalary && formik.errors.basicSalary ? (
                    <div className="text-red-500 text-sm">{formik.errors.basicSalary}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3"></div>
              </div>
              {employee?.cpf?.length > 0 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Employee CPF Percentage (%)</label>
                    <input
                      type="number"
                      placeholder="Employee CPF Percentage"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="employeeCPFPercentage"
                      onChange={formik.handleChange}
                      value={formik.values.employeeCPFPercentage}
                      disabled
                    />
                    {formik.touched.employeeCPFPercentage && formik.errors.employeeCPFPercentage ? (
                      <div className="text-red-500 text-sm">{formik.errors.employeeCPFPercentage}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      Employee CPF ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Employee CPF"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="employeeCPF"
                      onChange={formik.handleChange}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.employeeCPF)}
                      disabled
                    />
                    {formik.touched.employeeCPF && formik.errors.employeeCPF ? (
                      <div className="text-red-500 text-sm">{formik.errors.employeeCPF}</div>
                    ) : null}
                  </div>
                </div>
              )}
              {employee?.cpf?.length > 0 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Employer CPF Percentage (%)</label>
                    <input
                      type="number"
                      placeholder="Employer CPF Percentage"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="employerCPFPercentage"
                      onChange={formik.handleChange}
                      value={formik.values.employerCPFPercentage}
                      disabled
                    />
                    {formik.touched.employerCPFPercentage && formik.errors.employerCPFPercentage ? (
                      <div className="text-red-500 text-sm">{formik.errors.employerCPFPercentage}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      Employer CPF ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Employer CPF"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="employerCPF"
                      onChange={formik.handleChange}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.employerCPF)}
                      disabled
                    />
                    {formik.touched.employerCPF && formik.errors.employerCPF ? (
                      <div className="text-red-500 text-sm">{formik.errors.employerCPF}</div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">
                    Gross Salary ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Gross Salary"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="grossSalary"
                    onChange={formik.handleChange}
                    value={new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(formik.values.grossSalary)}
                    disabled
                  />
                  {formik.touched.grossSalary && formik.errors.grossSalary ? (
                    <div className="text-red-500 text-sm">{formik.errors.grossSalary}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3">
                  <label className="mb-2.5 block text-black font-bold">
                    Net Salary ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Net Pay"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="netSalary"
                    onChange={formik.handleChange}
                    onBlur={(e) => handleFloatChange(e.target.value, 'netSalary')}
                    value={new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(formik.values.netSalary)}
                    disabled
                  />
                  {formik.touched.netSalary && formik.errors.netSalary ? (
                    <div className="text-red-500 text-sm">{formik.errors.netSalary}</div>
                  ) : null}
                </div>
              </div>
              {(employee?.employee?.workingHourDetails?.workHoursType === 'Hourly' ||
                employee?.salary?.[0]?.timeSheets.length > 0) && employee?.employee?.salaryDetails?.ot === 0 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5">
                  <div className="w-full lg:w-1/2">
                    <button
                      type="button"
                      onClick={() => setTimeSheetModalOpen(true)}
                      className="w-full flex font-bold justify-between items-center px-5 rounded-xl text-sm border-[1.5px] shadow-lg border-transparent bg-grayLight hover:bg-gray-200 h-12 text-black disabled:text-gray2 disabled:cursor-default disabled:bg-grayDark"
                    >
                      Timesheets
                      <button title="View">
                        <Eye color="#000000" variant="Bold" size={20} />
                      </button>
                    </button>
                  </div>
                  <div className="w-full lg:w-1/2"></div>
                </div>
              )}
              {employee?.employee?.workingHourDetails?.workHoursType === 'Fixed' &&
                employee?.employee?.salaryDetails?.ot === 1 && (
                  <div>
                    <div className="text-black font-bold text-xl flex items-center pt-5">
                      <span className="w-fit">Timesheet Details </span>
                      <div className="border-b border-2 flex ml-5 flex-grow"></div>
                    </div>
                    <div className="my-5 flex flex-col gap-6 lg:flex-row">
                      <div className="w-full lg:w-1/2">
                        <label className="mb-2.5 block text-black font-bold">
                          Total Timesheet Amount({employee?.salary[0]?.currency})
                        </label>
                        <input
                          type="text"
                          placeholder="Gross Salary"
                          className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                          name="grossSalary"
                          onChange={formik.handleChange}
                          value={new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(formik.values.timeSheets?.[0]?.totalTimesheetAmount)}
                          disabled
                        />
                        {formik.touched.grossSalary && formik.errors.grossSalary ? (
                          <div className="text-red-500 text-sm">{formik.errors.grossSalary}</div>
                        ) : null}
                      </div>
                      <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3"></div>
                    </div>
                    {employee?.cpf?.length > 0 && (<div className="my-5 flex flex-col gap-6 lg:flex-row">
                      <div className="w-full lg:w-1/2">
                        <label className="mb-2.5 block text-black font-bold">Employee CPF Percentage (%)</label>
                        <input
                          type="number"
                          placeholder="Employee CPF Percentage"
                          className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                          name="employeeCPFPercentage"
                          value={formik.values.timeSheets?.[0]?.employeeCPFPercentage}
                          disabled
                        />
                        {formik.touched.employeeCPFPercentage && formik.errors.employeeCPFPercentage ? (
                          <div className="text-red-500 text-sm">{formik.errors.employeeCPFPercentage}</div>
                        ) : null}
                      </div>
                      <div className="w-full lg:w-1/2">
                        <label className="mb-2.5 block text-black font-bold">
                          Timesheet Employee CPF ({employee?.salary[0]?.currency})
                        </label>
                        <input
                          type="text"
                          placeholder="Employee CPF"
                          className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                          name="employeeCPF"
                          value={new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(formik.values.timeSheets?.[0]?.timesheetEmployeeCPF)}
                          disabled
                        />
                        {formik.touched.employeeCPF && formik.errors.employeeCPF ? (
                          <div className="text-red-500 text-sm">{formik.errors.employeeCPF}</div>
                        ) : null}
                      </div>
                    </div>)}
                    {employee?.cpf?.length > 0 && (<div className="mb-5 flex flex-col gap-6 lg:flex-row">
                      <div className="w-full lg:w-1/2">
                        <label className="mb-2.5 block text-black font-bold">Employer CPF Percentage (%)</label>
                        <input
                          type="number"
                          placeholder="Employer CPF Percentage"
                          className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                          name="employerCPFPercentage"
                          value={formik.values.timeSheets?.[0]?.employerCPFPercentage}
                          disabled
                        />
                        {formik.touched.employerCPFPercentage && formik.errors.employerCPFPercentage ? (
                          <div className="text-red-500 text-sm">{formik.errors.employerCPFPercentage}</div>
                        ) : null}
                      </div>
                      <div className="w-full lg:w-1/2">
                        <label className="mb-2.5 block text-black font-bold">
                          Timesheet Employer CPF ({employee?.salary[0]?.currency})
                        </label>
                        <input
                          type="text"
                          placeholder="Employer CPF"
                          className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                          name="employerCPF"
                          value={new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(formik.values.timeSheets?.[0]?.timesheetEmployerCPF)}
                          disabled
                        />
                        {formik.touched.employerCPF && formik.errors.employerCPF ? (
                          <div className="text-red-500 text-sm">{formik.errors.employerCPF}</div>
                        ) : null}
                      </div>
                    </div>)}
                    <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                      <div className="w-full lg:w-1/2 mt-8">
                        <button
                          type="button"
                          onClick={() => setTimeSheetModalOpen(true)}
                          className="w-full flex font-bold justify-between items-center px-5 rounded-xl text-sm border-[1.5px] shadow-lg border-transparent bg-grayLight hover:bg-gray-200 h-12 text-black disabled:text-gray2 disabled:cursor-default disabled:bg-grayDark"
                        >
                          Timesheets
                          <button title="View">
                            <Eye color="#000000" variant="Bold" size={20} />
                          </button>
                        </button>
                      </div>
                      <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3">
                        <label className="mb-2.5 block text-black font-bold">
                          Timesheet Net Salary ({employee?.salary[0]?.currency})
                        </label>
                        <input
                          type="text"
                          placeholder="Net Pay"
                          className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                          name="netSalary"
                          value={new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(formik.values.timeSheets?.[0]?.netTimesheetAmount)}
                          disabled
                        />
                        {formik.touched.netSalary && formik.errors.netSalary ? (
                          <div className="text-red-500 text-sm">{formik.errors.netSalary}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
              )}
              {employee?.cpf?.length > 0 && (
                <div className="text-black font-bold text-xl flex items-center mt-10">
                  CPF <div className="border-b border-2 flex flex-grow ml-5"></div>
                </div>
              )}
              {employee?.cpf?.length > 0 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row pt-5">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      Employee CPF ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Gross Salary"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="grossSalary"
                      onChange={formik.handleChange}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.cpf.employeeCPF)}
                      disabled
                    />
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3">
                    <label className="mb-2.5 block text-black font-bold">
                      Employer CPF ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Net Pay"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="netSalary"
                      onChange={formik.handleChange}
                      onBlur={(e) => handleFloatChange(e.target.value, 'netSalary')}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.cpf.employerCPF)}
                      disabled
                    />
                    {formik.touched.netSalary && formik.errors.netSalary ? (
                      <div className="text-red-500 text-sm">{formik.errors.netSalary}</div>
                    ) : null}
                  </div>
                </div>
              )}
              {employee?.cpf?.length > 0 && (
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      CPF Contribution ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Gross Salary"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="grossSalary"
                      onChange={formik.handleChange}
                      value={new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(formik.values.cpf.CPFContribution)}
                      disabled
                    />
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-wrap items-center gap-x-3"></div>
                </div>
              )}
              <div className="text-black font-bold text-xl flex items-center mt-10">
                Deductions <div className="border-b border-2 flex flex-grow ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5 items-end">
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  {formik.values.deductions[0]?.deductions?.map((deduction, index) => (
                    <div key={index}>
                      <label className="mb-2.5 block text-black font-bold">
                        {deduction?.deductionType} ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Deduction Amount"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name={`deductions[${index}].deductionAmount`}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleFloatChange(e.target.value, `deductions[${index}].deductionAmount`)}
                        value={new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(deduction.deductionAmount)}
                        disabled
                      />
                      {formik.touched.deductions?.[index]?.amount && formik.errors.deductions?.[index]?.amount ? (
                        <div className="text-red-500 text-sm">{formik.errors.deductions[index].amount}</div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">
                    Total Deductions ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Net Pay"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="netSalary"
                    onChange={formik.handleChange}
                    value={employee?.deductions[0] ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(employee?.deductions[0]?.totalDeductionAmount) : '0.00'}
                    disabled
                  />
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center mt-10">
                No Pay Leaves <div className="border-b border-2 flex flex-grow ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5 items-end">
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  {formik.values?.noPayLeaves[0]?.noPayLeaves?.map((noPayLeave, index) => (
                    <div key={index}>
                      <label className="mb-2.5 block text-black font-bold">
                        {noPayLeave?.noPayLeaveType} ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="No Pay Leave Amount"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name={`noPayLeaves[${index}].noPayLeaveAmount`}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleFloatChange(e.target.value, `noPayLeaves[${index}].noPayLeaveAmount`)}
                        value={
                          noPayLeave?.noPayLeaveAmount
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(noPayLeave?.noPayLeaveAmount)
                            : '0.00'
                        }
                        disabled
                      />
                      {formik.touched.noPayLeaves?.[index]?.noPayLeaveAmount &&
                      formik.errors.noPayLeaves?.[index]?.noPayLeaveAmount ? (
                        <div className="text-red-500 text-sm">{formik.errors.noPayLeaves[index].noPayLeaveAmount}</div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">
                    Total No Pay Leaves ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Net Pay"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="netSalary"
                    onChange={formik.handleChange}
                    value={
                      employee?.noPayLeaves[0]
                        ? new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(employee?.noPayLeaves[0]?.totalNoPayLeaveAmount)
                        : '0.00'
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center">
                Allowances <div className="border-b border-2 w-full ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5 items-start">
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  {formik.values.allowances[0]?.allowances?.map((allowance, index) => (
                    <div key={index}>
                      <label className="mb-2.5 block text-black font-bold">
                        {allowance?.allowanceType} ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Allowance Amount"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name={`allowances[${index}].allowanceAmount`}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleFloatChange(e.target.value, `allowances[${index}].allowanceAmount`)}
                        value={new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(allowance.allowanceAmount)}
                        disabled
                      />
                    </div>
                  ))}
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  <div>
                    <label className="mb-2.5 block text-black font-bold">
                      Total Allowance ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Net Pay"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="netSalary"
                      onChange={formik.handleChange}
                      value={employee?.allowances[0] ? new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(employee?.allowances[0]?.totalAllowanceAmount) : '0.00'}
                      disabled
                    />
                  </div>
                  {employee?.cpf?.length > 0 && (
                    <div>
                      <label className="mb-2.5 block text-black font-bold">
                        Allowance Employee CPF ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Net Pay"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name="netSalary"
                        onChange={formik.handleChange}
                        value={employee?.allowances[0] ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(employee?.allowances[0]?.allowanceEmployeeCPF) : '0.00'}
                        disabled
                      />
                    </div>
                  )}
                  {employee?.cpf?.length > 0 && (
                    <div>
                      <label className="mb-2.5 block text-black font-bold">
                        Allowance Employer CPF ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Net Pay"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name="netSalary"
                        onChange={formik.handleChange}
                        value={employee?.allowances[0] ? new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(employee?.allowances[0]?.allowanceEmployerCPF) : '0.00'}
                        disabled
                      />
                    </div>
                  )}
                  <div>
                    <label className="mb-2.5 block text-black font-bold">
                      Net Allowance ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Net Pay"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="netSalary"
                      onChange={formik.handleChange}
                      value={employee?.allowances[0] ? new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(employee?.allowances[0]?.netAllowanceAmount) : '0.00'}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center">
                Bonuses <div className="border-b border-2 w-full ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5 items-start">
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  {formik.values.bonuses[0]?.bonuses?.map((bonus, index) => (
                    <div key={index}>
                      <label className="mb-2.5 block text-black font-bold">
                        {bonus?.bonusType} ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Bonus Amount"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name={`bonuses[${index}].bonusAmount`}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleFloatChange(e.target.value, `bonuses[${index}].bonusAmount`)}
                        value={new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(bonus.bonusAmount)}
                        disabled
                      />
                    </div>
                  ))}
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  <div>
                    <label className="mb-2.5 block text-black font-bold">
                      Total Bonus ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Net Pay"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="netSalary"
                      onChange={formik.handleChange}
                      value={
                        employee?.bonuses[0]
                          ? new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }).format(employee?.bonuses[0]?.totalBonusAmount)
                          : '0.00'
                      }
                      disabled
                    />
                  </div>
                  {employee?.cpf?.length > 0 && (
                    <div>
                      <label className="mb-2.5 block text-black font-bold">
                        Bonus Employee CPF ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Bonus Employee CPF"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name="bonusEmployeeCPF"
                        onChange={formik.handleChange}
                        value={
                          employee?.bonuses[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.bonuses[0]?.bonusEmployeeCPF)
                            : '0.00'
                        }
                        disabled
                      />
                    </div>
                  )}
                  {employee?.cpf?.length > 0 && (
                    <div>
                      <label className="mb-2.5 block text-black font-bold">
                        Bonus Employer CPF ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Bonus Employer CPF"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name="bonusEmployerCPF"
                        onChange={formik.handleChange}
                        value={
                          employee?.bonuses[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.bonuses[0]?.bonusEmployerCPF)
                            : '0.00'
                        }
                        disabled
                      />
                    </div>
                  )}
                  <div>
                    <label className="mb-2.5 block text-black font-bold">
                      Net Bonus ({employee?.salary[0]?.currency})
                    </label>
                    <input
                      type="text"
                      placeholder="Net Bonus Amount"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                      name="netBonusAmount"
                      onChange={formik.handleChange}
                      value={
                        employee?.bonuses[0]
                          ? new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }).format(employee?.bonuses[0]?.netBonusAmount)
                          : '0.00'
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center">
                Claims <div className="border-b border-2 w-full ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row py-5 items-end">
                <div className="w-full lg:w-1/2 flex flex-col gap-5">
                  {formik.values.claims[0]?.claims?.map((claim, index) => (
                    <div key={index}>
                      <label className="mb-2.5 block text-black font-bold">
                        {claim?.claimType} ({employee?.salary[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder="Claim Amount"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                        name={`claims[${index}].amount`}
                        onChange={formik.handleChange}
                        onBlur={(e) => handleFloatChange(e.target.value, `claims[${index}].amount`)}
                        value={new Intl.NumberFormat('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }).format(claim.claimAmount)}
                        disabled
                      />
                      {formik.touched.claims?.[index]?.amount && formik.errors.claims?.[index]?.amount ? (
                        <div className="text-red-500 text-sm">{formik.errors.claims[index].amount}</div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">
                    Total Claims ({employee?.salary[0]?.currency})
                  </label>
                  <input
                    type="text"
                    placeholder="Net Pay"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="netSalary"
                    onChange={formik.handleChange}
                    value={employee?.claims[0] ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(employee?.claims[0]?.totalClaimAmount) : '0.00'}
                    disabled
                  />
                </div>
              </div>
              <div className="text-black font-bold text-xl flex items-center">
                <span className="w-40">Bank Details </span>
                <div className="border-b border-2 w-full ml-5"></div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row pt-5">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Bank</label>
                  <Dropdown
                    value={bankOptions.find((option) => option.value === formik.values.bank)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('bank', selectedValue.value);
                    }}
                    options={bankOptions}
                    isClearable={false}
                    isSearchable={false}
                    menuPlacement="top"
                    placeholder="Select Bank"
                    name="bank"
                    id="bank"
                    isDisabled={true}
                  />
                  {formik.touched.bank && formik.errors.bank ? (
                    <div className="text-red-500 text-sm">{formik.errors.bank}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Account Number</label>
                  <input
                    type="number"
                    placeholder="Type Account Number"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayDark"
                    name="accountNumber"
                    onChange={formik.handleChange}
                    value={formik.values.bankAccountNum}
                    disabled
                  />
                  {formik.touched.bankAccountNum && formik.errors.bankAccountNum ? (
                    <div className="text-red-500 text-sm">{formik.errors.bankAccountNum}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
          <button
            type="button"
            onClick={() => generatePayslipPdf(employee, selectedPayslip)}
            className="bg-black/90 hover:bg-black/80 text-white font-bold rounded-xl px-6 h-12 flex items-center justify-center gap-x-3 text-sm"
          >
            <DirectboxReceive color="#ffffff" variant="Outline" size={25} />
            <span>Download Salary Slip</span>
          </button>
        </div>
      </div>
      <AllTimesheetViewModal
        isOpen={isTimesheetModalOpen}
        timesheets={employee?.salary[0]?.timeSheets[0]?.timesheets}
        onClose={() => setTimeSheetModalOpen(false)}
        currency={employee?.salary[0]?.currency}
      />
    </UserLayoutWM>
  );
};

export default UserPayslipView;
