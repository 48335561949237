import { useEffect, useState } from 'react';
import DefaultProfile from '../../../assets/images/hrsimply-default.png';
import { useFormik } from 'formik';
import { editCompanySchema } from '../../../validations/validationSchemas';
import { updateCompany } from '../../../actions/companyAction';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import { businessType, yesOrNoOption } from '../../../constant/staticData';
import CountryCodeDropdown from '../../../components/countryCodeDropdown';
import countryOptions from '../../../constant/countries.json';
import industryOptions from '../../../constant/all_industries.json';
import { CallCalling, Camera, Copy, Edit2, Location, Sms } from 'iconsax-react';
import ProfileImageUploadModal from '../../../components/profileImageUploadModal';
import { copyToClipboard } from '../../../constant/functions';

const CompanyDetails = ({ company, isNewCompany }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [editProfilePicModal, setEditProfilePicModal] = useState(false);

  useEffect(() => {
    if (isNewCompany) {
      setEdit(true);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      companyId: company?._id,
      companyName: company?.companyName || '',
      // email: company?.email || '',
      entityType: company?.entityType || '',
      uen: company?.uen || '',
      csn: company?.csn || '',
      industry: company?.industry || '',
      gstRegistered: company?.gstRegistered ?? '',
      postalCode: company?.postalCode || '',
      address: company?.address || '',
      country: company?.country || '',
      areaCode: company?.areaCode || '+65',
      officeNo: company?.officeNo || ''
    },
    validationSchema: editCompanySchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(updateCompany(values, setEdit));
    }
  });

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="relative flex flex-col items-center">
          <div className="mb-1 relative">
            <img src={company?.companyLogo || DefaultProfile} alt="company logo" className="w-20 h-20 rounded-full object-cover" />
            <button
              onClick={() => setEditProfilePicModal(true)}
              className="absolute right-0 bottom-0 z-50 w-7 h-7 bg-white hover:bg-grayDark shadow-lg rounded-xl flex justify-center items-center"
            >
              <Camera color="#3D54DD" variant="Outline" size={18} className="cursor-pointer " />
            </button>
          </div>
          <div className="text-xl text-black font-bold text-center">{company?.companyName}</div>
          <div className="text-sm font-normal text-black/40 text-center">{company?.entityType}</div>
          <div className="text-sm font-normal text-black/40 uppercase mb-2 text-center">{company?.industry}</div>
          <div className="flex items-center justify-center flex-wrap gap-x-4 mb-3">
            {company?.uen && (
              <div className="text-sm text-black font-normal text-center">
                <span className="font-bold">Unique Entity Number (UEN):</span> {company?.uen}
              </div>
            )}
            {company?.csn && (
              <div className="text-sm text-black font-normal text-center">
                <span className="font-bold">CPF Submission Number (CSN) :</span> {company?.csn}
              </div>
            )}
          </div>
          <div className="flex flex-wrap w-full gap-x-10 items-center justify-center">
            {company?.officeNo && (
              <div className="flex flex-row text-sm md:text-lg font-normal text-black items-center gap-x-2">
                <CallCalling color="#000000" variant="Bold" size={20} />
                <div>
                  {company?.areaCode} {company?.officeNo}
                </div>
                <Copy
                  onClick={() => copyToClipboard(`${company?.areaCode}${company?.officeNo}`)}
                  color="#000000"
                  variant="Outline"
                  size={20}
                  className="cursor-pointer"
                />
              </div>
            )}

            {/* <div className="flex flex-row text-sm md:text-lg font-normal text-black items-center gap-x-2">
              <Sms color="#000000" variant="Bold" size={20} />
              <a href={`mailto:${company?.email}`} className="hover:underline hover:text-primary">
                {company?.email}
              </a>
              <Copy color="#000000" variant="Outline" size={20} className="cursor-pointer" />
            </div> */}

            {company?.address && (
              <div className="flex items-start gap-x-2 text-sm md:text-lg font-normal text-black">
                <Location color="#000000" variant="Bold" size={20} className="flex-shrink-0 mt-1" />
                <div className="flex items-center">
                  <span className="break-words">
                    {company?.address}, {company?.country}, {company?.postalCode}
                  </span>
                  <Copy
                    onClick={() => copyToClipboard(`${company?.address}, ${company?.country}, ${company?.postalCode}`)}
                    color="#000000"
                    variant="Outline"
                    size={20}
                    className="cursor-pointer flex-shrink-0 ml-2"
                  />
                </div>
              </div>
            )}
          </div>

          {!edit && (
            <button
              onClick={() => setEdit(true)}
              type="button"
              className="absolute right-4 top-6 hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
            >
              <Edit2 color="#16a34a" variant="Bold" size={20} />
            </button>
          )}
        </div>
      </div>
      {edit && <div className="w-full border-[4px] rounded-lg border-grayMedium mt-5"></div>}
      {edit && (
        <div className="rounded-xl bg-white shadow-lg w-full">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-6">
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Company name</label>
                  <input
                    type="text"
                    placeholder="Company name"
                    className="w-full rounded-xl text-sm border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
                    name="companyName"
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                    disabled
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="text-red-500 text-sm">{formik.errors.companyName}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  {/* <label className="mb-2.5 block text-black font-bold">Email</label>
                  <input
                    type="email"
                    placeholder="Email address"
                    className="w-full rounded-xl text-sm border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    disabled
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 text-sm">{formik.errors.email}</div>
                  ) : null} */}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Business Entity Type</label>
                  <Dropdown
                    value={businessType.find((option) => option.value === formik.values.entityType)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('entityType', selectedValue.value);
                    }}
                    options={businessType}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select Business Entity Type"
                    name="entityType"
                    id="entityType"
                  />
                  {formik.touched.entityType && formik.errors.entityType ? (
                    <div className="text-red-500 text-sm">{formik.errors.entityType}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Unique Entity Number (UEN)</label>
                  <input
                    type="text"
                    placeholder="Business UEN"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
                    name="uen"
                    onChange={formik.handleChange}
                    value={formik.values.uen}
                  />
                  {formik.touched.uen && formik.errors.uen ? (
                    <div className="text-red-500 text-sm">{formik.errors.uen}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Office Telephone</label>
                  <div className="flex flex-row">
                    <CountryCodeDropdown
                      value={formik.values.areaCode}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('areaCode', selectedValue.value);
                      }}
                      isClearable={false}
                      name="areaCode"
                      id="areaCode"
                    />
                    <input
                      type="text"
                      placeholder="Business Telephone"
                      className="w-full rounded-e-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
                      name="officeNo"
                      onChange={formik.handleChange}
                      value={formik.values.officeNo}
                    />
                  </div>
                  {formik.touched.officeNo && formik.errors.officeNo ? (
                    <div className="text-red-500 text-sm">{formik.errors.officeNo}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">CPF Submission Number (CSN)</label>
                  <input
                    type="text"
                    placeholder="Business CSN"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
                    name="csn"
                    onChange={formik.handleChange}
                    value={formik.values.csn}
                  />
                  {formik.touched.csn && formik.errors.csn ? (
                    <div className="text-red-500 text-sm">{formik.errors.csn}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Industry</label>
                  <Dropdown
                    value={industryOptions.find((option) => option.value === formik.values.industry)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('industry', selectedValue.value);
                    }}
                    options={industryOptions}
                    isClearable={false}
                    placeholder="Select Industry"
                    name="industry"
                    id="industry"
                  />
                  {formik.touched.industry && formik.errors.industry ? (
                    <div className="text-red-500 text-sm">{formik.errors.industry}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">GST Registered?</label>
                  <Dropdown
                    value={yesOrNoOption.find((option) => option.value === formik.values.gstRegistered)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('gstRegistered', selectedValue.value);
                    }}
                    options={yesOrNoOption}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select Yes/No"
                    name="gstRegistered"
                    id="gstRegistered"
                  />
                  {formik.touched.gstRegistered && formik.errors.gstRegistered ? (
                    <div className="text-red-500 text-sm">{formik.errors.gstRegistered}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4">
                <div className="w-full">
                  <label className="mb-2.5 block text-black font-bold">Address</label>
                  <input
                    type="text"
                    placeholder="Address"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
                    name="address"
                    onChange={formik.handleChange}
                    value={formik.values.address}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="text-red-500 text-sm">{formik.errors.address}</div>
                  ) : null}
                </div>
              </div>

              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Country</label>
                  <Dropdown
                    value={countryOptions.find((option) => option.value === formik.values.country)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('country', selectedValue.value);
                    }}
                    options={countryOptions}
                    isClearable={false}
                    placeholder="Select Country"
                    name="country"
                    id="country"
                    menuPlacement="top"
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <div className="text-red-500 text-sm">{formik.errors.country}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Postal Code</label>
                  <input
                    type="text"
                    placeholder="Postal Code"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
                    name="postalCode"
                    onChange={formik.handleChange}
                    value={formik.values.postalCode}
                  />
                  {formik.touched.postalCode && formik.errors.postalCode ? (
                    <div className="text-red-500 text-sm">{formik.errors.postalCode}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end w-full gap-x-2">
                <button
                  type="button"
                  onClick={() => setEdit(false)}
                  className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex w-28 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {editProfilePicModal && (
        <ProfileImageUploadModal closeModal={() => setEditProfilePicModal(false)} companyId={company?._id} userRole="company" />
      )}
    </div>
  );
};

export default CompanyDetails;
