import { useEffect, useState, useMemo } from 'react';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../layout/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import empty from '../../../assets/images/empty.png';
import { format } from 'date-fns';
import { Eye } from 'iconsax-react';
import { ViewDocumentModal } from './viewDocumentModal';
import Dropdown from '../../../components/dropdown';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import { getClaimsReport } from '../../../actions/reportAction';
import Datepicker from 'react-tailwindcss-datepicker';
import * as XLSX from 'xlsx';
import { ToastBar } from '../../../components/toastbar';
import { getStatus } from '../../../constant/functions';

const ClaimsReport = () => {
  const dispatch = useDispatch();
  const today = new Date();
  const thirtyDaysBefore = new Date();
  thirtyDaysBefore.setDate(today.getDate() - 30);

  const [sortConfig, setSortConfig] = useState({
    key: 'createdDate',
    direction: 'descending'
  });
  const [selectedClaims, setselectedClaims] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToView, setFileToView] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: thirtyDaysBefore,
    endDate: today
  });

  const companies = useSelector((state) => state.company?.companies || []);
  const company = useSelector((state) => state.company?.company);
  const claimsReport = useSelector((state) => state.reports?.claims) || [];
  console.log('claimsReport: ', claimsReport);

  useEffect(() => {
    const inputValue = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      companyId: company?._id
    };
    dispatch(getClaimsReport(inputValue));
  }, [dispatch, company, dateRange]);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    if (selectedValue) {
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };

  const handleRangeChange = (newValue) => {
    console.log('datePickerValue:', newValue);
    setDateRange(newValue);
  };

  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  const handleView = (file) => {
    setFileToView(file);
    setIsModalOpen(true);
  };

  const closeViewModal = () => {
    setIsModalOpen(false);
    setFileToView(null);
  };

  const sortedClaims = useMemo(() => {
    if (!claimsReport.length) {
      return [];
    }

    let sortableClaims = claimsReport.flatMap((claim) =>
      claim.claims.map((claims) => ({
        ...claims,
        employee: claim.employee
      }))
    );

    sortableClaims.sort((a, b) => {
      const fieldA = sortConfig.key.split('.').reduce((obj, key) => obj[key], a);
      const fieldB = sortConfig.key.split('.').reduce((obj, key) => obj[key], b);

      const valueA = typeof fieldA === 'string' ? fieldA.toLowerCase() : String(fieldA || '');
      const valueB = typeof fieldB === 'string' ? fieldB.toLowerCase() : String(fieldB || '');

      if (sortConfig.direction === 'ascending') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    return sortableClaims;
  }, [claimsReport, sortConfig]);

  useEffect(() => {
    if (selectedClaims.length === sortedClaims.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedClaims, sortedClaims]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 4:
        return { label: 'Processing', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 2:
        return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
      case 3:
        return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
      case 5:
        return { label: 'Confirmed', bgColor: 'bg-blue-500', textColor: 'text-blue-500' };
      default:
        return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedClaims.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedClaims.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const downloadExcel = () => {
    try {
      if (!sortedClaims.length) {
        ToastBar.warning('No Claim Found');
        return;
      }

      const formattedLeaves = sortedClaims?.map((claim) => {
        const formatCurrency = (amount, currencySymbol = claim?.employee?.salaryDetails?.currency) => {
          if (amount == null) return `${currencySymbol} 0.00`;
          return `${currencySymbol} ${amount.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        };

        const formattedClaimDate = !Number.isNaN(new Date(claim.claimDate).getTime())
          ? format(new Date(claim.claimDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedCreatedDate = !Number.isNaN(new Date(claim.createdDate).getTime())
          ? format(new Date(claim.createdDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        return {
          'Full Name': claim?.employee?.fullName || '',
          'Claim Type': claim?.claimType,
          'Claim Date': formattedClaimDate,
          'Claim Amount': formatCurrency(claim?.claimAmount),
          'Created Date': formattedCreatedDate,
          'Status': getStatus(claim?.status),
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(formattedLeaves);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Claims Report');

      const maxLength = {};
      formattedLeaves.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          maxLength[key] = Math.max(maxLength[key] || 0, length);
        });
      });
      const colWidths = Object.entries(maxLength).map(([key, length]) => {
        if (key === 'Claim Amount') return { wch: 12 };
        else if (key === 'Claim Type') return { wch: 12 }
        return { wch: length + 2 };
      });
      worksheet['!cols'] = colWidths;

      const filename = `Generate_Claims_Report.xlsx`;
      XLSX.writeFile(workbook, filename);
      ToastBar.success('Excel Download Successful');
    } catch (error) {
      console.log(error);
      ToastBar.error('Error Downloading Excel');
    }
  };

  return (
    <Layout pageTitle={'Claims Report'} page={'Reports'} subPage={'Claims Report'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl min-h-[70vh] h-full overflow-y-auto">
        <div>
          <div className="flex flex-col gap-4 gap-x-4 w-full">
            <div className="w-full lg:w-1/2">
              <label className="block text-black font-semibold mb-0.5">Select Company</label>
              {companyOptions.length > 0 && (
                <Dropdown
                  value={companyOptions?.find((option) => option.value === company?.companyName)}
                  onChange={handleCompanyChange}
                  options={companyOptions}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Select Company"
                  name="company"
                  id="company"
                />
              )}
            </div>
            <div className="flex flex-row items-end gap-x-5">
              <div className="w-72">
                <label className="block text-black font-semibold mb-0.5">Select Range</label>
                <Datepicker
                  primaryColor="indigo"
                  popoverDirection="down"
                  value={dateRange}
                  onChange={handleRangeChange}
                  displayFormat="DD-MM-YYYY"
                  inputClassName="w-full px-4 rounded-lg bg-grayLight text-sm h-12 cursor-pointer"
                />
              </div>
              <div onClick={downloadExcel} className="cursor-pointer" title="Download Payroll Employees">
                <FontAwesomeIcon icon={faFileExcel} className="text-[42px]" color="#3CA455" />
              </div>
            </div>
          </div>
          {sortedClaims.length > 0 ? (
            <div className="-mt-14">
              <div className="flex justify-end items-end my-5">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black">
                    <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
                    <th onClick={() => handleSort('employee.fullName')} className="cursor-pointer">
                      Full Name <FontAwesomeIcon icon={getSortIcon('employee.fullName')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('claimType')} className="cursor-pointer">
                      Claim Type
                      <FontAwesomeIcon icon={getSortIcon('claimType')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('claimDate')} className="cursor-pointer">
                      Claim Date <FontAwesomeIcon icon={getSortIcon('claimDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('claimAmount')} className="cursor-pointer">
                      Claim Amount <FontAwesomeIcon icon={getSortIcon('claimAmount')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('createdDate')} className="cursor-pointer">
                      Created Date <FontAwesomeIcon icon={getSortIcon('createdDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4">
                      Status <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
                    </th>
                    <th className="rounded-r-xl">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((claim, index) => {
                    const formattedClaimDate = !Number.isNaN(new Date(claim?.claimDate).getTime())
                      ? format(new Date(claim?.claimDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedCreatedDate = !Number.isNaN(new Date(claim?.createdDate).getTime())
                      ? format(new Date(claim?.createdDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const { label, textColor, bgColor } = getStatusLabel(claim?.status);

                    return (
                      <tr className={`h-20 text-sm text-black`} key={index}>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={claim?.employee?.avatar ? claim?.employee?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center" title={claim?.employee?.fullName}>
                          {claim?.employee?.fullName}
                        </td>
                        <td className="h-20 text-center">{claim?.claimType}</td>
                        <td className="h-20 text-center">{formattedClaimDate}</td>
                        <td className="h-20 text-center">
                          {new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(claim?.claimAmount)}
                        </td>
                        <td className="h-20 text-center">{formattedCreatedDate}</td>
                        <td className="h-20 w-26">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                        <td className="h-20 text-center flex justify-center items-center">
                          <div
                            className="flex justify-center items-center w-8 h-8 p-1.5 rounded-md hover:bg-blue-400 shadow-md"
                            onClick={() => handleView(claim?.claimFile)}
                            title="View"
                          >
                            <Eye color="#6b7280" variant="Bold" size={20} className="cursor-pointer" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Claims</div>
            </div>
          )}
        </div>
        <div className="flex">
          {currentEntries.length > 0 && (
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedClaims.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${
                          pageNumber === currentPage
                            ? 'bg-purple1 text-white rounded-xl font-bold'
                            : 'text-black bg-oppty-green-5'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && <ViewDocumentModal fileToView={fileToView} closeViewModal={closeViewModal} />}
    </Layout>
  );
};

export default ClaimsReport;
