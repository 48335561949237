import { useFormik } from 'formik';
import { employeeClaimSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import { updateClaim } from '../../../actions/employeeAction';

const EditClaim = ({ claim, claimOptions, claimLimitOptions, onClose, employee }) => {
  const dispatch = useDispatch();

  //console.log(claim)

  const formik = useFormik({
    initialValues: {
      _id: claim?._id,
      claimId: claim?.claimId || '',
      claimType: claim?.claimType || '',
      claimCode: claim?.claimCode || '',
      isLimit: claim?.isLimit || 0,
      claimLimit: claim?.claimLimit || 0,
      claimBalance: claim?.claimBalance || 0,
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      status: claim?.status
    },
    validationSchema: employeeClaimSchema,
    onSubmit: async (values) => {
      console.log('update value: ', values);
      dispatch(updateClaim(values, onClose));
    }
  });

  const handleLimitChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : 0;

    // Set claimLimit to 0 if isLimit is set to "No" (0)
    if (selectedValue === 0) {
      formik.setFieldValue('claimLimit', 0);
    }

    formik.setFieldValue('isLimit', selectedValue);
  };

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Claim Type</label>
              <Dropdown
                value={claimOptions.find((option) => option.claimType === formik.values.claimType)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    formik.setValues({
                      ...formik.values,
                      claimType: selectedValue.value,
                      claimCode: selectedValue.claimCode,
                      claimId: selectedValue.claimId
                    });
                  }
                }}
                options={claimOptions}
                isClearable={false}
                placeholder="Select Claim"
                name="claimType"
                id="claimType"
                isDisabled
              />
              {formik.touched.claimType && formik.errors.claimType ? (
                <div className="text-red-500 text-sm">{formik.errors.claimType}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2"></div>
          </div>
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Has Limit?</label>
              <Dropdown
                value={claimLimitOptions.find((option) => option.value === formik.values.isLimit)}
                onChange={handleLimitChange}
                options={claimLimitOptions}
                isClearable={false}
                placeholder="Has Limit?"
                name="isLimit"
                id="isLimit"
              />
              {formik.touched.isLimit && formik.errors.isLimit ? (
                <div className="text-red-500 text-sm">{formik.errors.isLimit}</div>
              ) : null}
            </div>
            {formik.values.isLimit == 1 && (
              <div className="w-full lg:w-1/2">
                <label className="mb-2.5 block text-black font-bold">Claim Limit</label>
                <input
                  type="number"
                  placeholder="Claim Limit"
                  className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                  name="claimLimit"
                  onChange={formik.handleChange}
                  value={formik.values.claimLimit}
                />
                {formik.touched.claimLimit && formik.errors.claimLimit && (formik.touched.isLimit == 1) ? (
                  <div className="text-red-500 text-sm">{formik.errors.claimLimit}</div>
                ) : null}
              </div>
            )}
          </div>
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditClaim;
