import { useNavigate } from 'react-router-dom';

const DashboardMiddleSection = ({ dashboardDetails, user }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-wrap gap-11 mt-4 bg-white p-6 rounded-2xl">
      <div
        className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
        onClick={() => navigate('/user/apply-leave')}
      >
        <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
          {dashboardDetails?.pendingLeavesCount}
        </div>
        <div className="text-black font-semibold group-hover:underline">Pending Leaves</div>
      </div>
      {user?.workingHourDetails?.workHoursType === 'Fixed' && (
        <div
          className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
          onClick={() => navigate('/user/apply-no-pay-leave')}
        >
          <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
            {dashboardDetails?.pendingNoPayLeavesCount}
          </div>
          <div className="text-black font-semibold group-hover:underline">Pending No Pay Leaves</div>
        </div>
      )}
      <div
        className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
        onClick={() => navigate('/user/apply-medical-leave')}
      >
        <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
          {dashboardDetails?.pendingMedicalLeavesCount}
        </div>
        <div className="text-black font-semibold group-hover:underline">Pending Medical Leaves</div>
      </div>
      <div
        className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
        onClick={() => navigate('/user/submit-claim')}
      >
        <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
          {dashboardDetails?.pendingClaimsCount}
        </div>
        <div className="text-black font-semibold group-hover:underline">Pending Claims Count</div>
      </div>
      {(user?.workingHourDetails?.workHoursType === 'Hourly' || user?.salaryDetails?.ot === 1) && (
        <div
          className="flex flex-grow flex-col justify-center items-center w-fit cursor-pointer group"
          onClick={() => navigate('/user/timesheets')}
        >
          <div className="mb-1 h-40 w-40 rounded-full border-purple1 border-[18px] flex justify-center items-center text-4xl font-bold text-black shadow-xl">
            {dashboardDetails?.pendingTimesheetsCount}
          </div>
          <div className="text-black font-semibold group-hover:underline">Pending Timesheets</div>
        </div>
      )}
    </div>
  );
};

export default DashboardMiddleSection;
