import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { employeeBonusSchema } from '../../../validations/validationSchemas';
import Dropdown from '../../../components/dropdown';
import { Add, Edit2, Trash } from 'iconsax-react';
import DeleteConfirmationModal from '../../../components/deleteConfirmationModal';
import EditBonus from './editBonus';
import { monthOptions } from '../../../constant/dropdownConstOptions';
import DateDropdown3 from '../../../components/dateDropdown3';
import { addBonus, deleteBonus } from '../../../actions/bonusActions';

const BonusDetails = ({ employee, company }) => {
  const dispatch = useDispatch();

  const [add, setAdd] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [bonusToDelete, setBonusToDelete] = useState(null);

  const bonusOptions =
    company?.bonuses?.map((bonus) => ({
      label: `${bonus?.bonusType} - ${bonus?.bonusCode}`,
      value: bonus?.bonusType,
      bonusType: bonus?.bonusType,
      bonusCode: bonus?.bonusCode,
      bonusId: bonus?._id
    })) || [];

  const formik = useFormik({
    initialValues: {
      companyId: employee?.company?._id,
      employeeId: employee?._id,
      month: '',
      year: '',
      bonusId: '',
      bonusType: '',
      bonusCode: '',
      bonusAmount: '',
      status: 1
    },
    validationSchema: employeeBonusSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      dispatch(addBonus(values, setAdd, resetForm));
    }
  });

  const handleModalDelete = (bonus) => {
    setIsConfirmationModalOpen(true);
    setBonusToDelete(bonus)
  };

  const handleDelete = () => {
    if (bonusToDelete) {
      dispatch(
        deleteBonus(
          {
            _id: bonusToDelete?._id,
            employeeId: employee?._id,
            companyId: employee?.company?._id
          },
          setIsConfirmationModalOpen
        )
      );
    }
  };

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };
  const handleMonthChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    formik.setFieldValue('month', selectedValue);
  };

  const handleYearChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    formik.setFieldValue('year', selectedValue);
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = [
    { label: currentYear.toString(), value: currentYear },
    { label: (currentYear + 1).toString(), value: currentYear + 1 }
  ];

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const monthNames = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];
  
    // Get the current month's name
    const currentMonth = monthNames[currentDate.getMonth()]
    formik.setFieldValue('bonusType', '');
    formik.setFieldValue('year', currentYear);
    formik.setFieldValue('month', currentMonth);
  }, [add]); 

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-bold text-black text-2xl">Bonus Details</h3>
          {!add && (
            <button
              className="w-16 h-8 rounded-lg flex items-center justify-center text-2xl font-bold bg-grayLight shadow-md"
              onClick={() => setAdd(true)}
            >
              <Add color="#3D54DD" variant="Linear" size={20} />
            </button>
          )}
        </div>
        {add && (
          <div className="rounded-xl bg-white shadow-lg w-full mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-6">
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Bonus Type</label>
                    <Dropdown
                      value={bonusOptions.find((option) => option.bonusType === formik.values.bonusType)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) {
                          formik.setValues({
                            ...formik.values,
                            bonusType: selectedValue.value,
                            bonusCode: selectedValue.bonusCode,
                            bonusId: selectedValue.bonusId
                          });
                        }
                      }}
                      options={bonusOptions}
                      isClearable={false}
                      placeholder="Select Bonus"
                      name="bonusType"
                      id="bonusType"
                    />
                    {formik.touched.bonusType && formik.errors.bonusType ? (
                      <div className="text-red-500 text-sm">{formik.errors.bonusType}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2 flex lg:flex-row gap-x-2">
                    <div className="w-1/2">
                      <label className="mb-2.5 block text-black font-bold">Month</label>
                      <div className="font-bold text-lg text-black flex">
                        <DateDropdown3
                          value={monthOptions.find((option) => option.value === formik.values?.month)}
                          onChange={handleMonthChange}
                          options={monthOptions}
                          isClearable={false}
                          placeholder="Month"
                          name="month"
                          id="month"
                        />
                      </div>
                    </div>
                    <div className="w-1/2">
                      <label className="mb-2.5 block text-black font-bold">Year</label>
                      <div className="font-bold text-lg text-black flex">
                        <DateDropdown3
                          value={yearOptions.find((option) => option.value === formik.values?.year)}
                          onChange={handleYearChange}
                          options={yearOptions}
                          isClearable={false}
                          placeholder="Year"
                          name="year"
                          id="year"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">
                      Amount ({employee?.salaryDetails?.currency})
                    </label>
                    <input
                      type="number"
                      placeholder="Amount"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="bonusAmount"
                      onChange={formik.handleChange}
                      onBlur={(e) => handleFloatChange(e.target.value, 'bonusAmount')}
                      value={formik.values.bonusAmount}
                    />
                    {formik.touched.bonusAmount && formik.errors.bonusAmount ? (
                      <div className="text-red-500 text-sm">{formik.errors.bonusAmount}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2"></div>
                </div>
                <div className="flex justify-end w-full gap-x-2">
                  <button
                    type="button"
                    onClick={() => setAdd(false)}
                    className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex w-28 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="flex flex-wrap gap-3 mt-5">
          {employee?.bonusDetails?.bonuses?.map((bonus, index) => {
            return (
              <div
                className={`p-6 relative bg-white shadow-lg ${editIndex === index ? 'w-full' : 'w-[32%]'} rounded-2xl`}
                key={index}
              >
                {editIndex !== index && (
                  <div className="pr-12">
                    <div className="text-base text-black font-bold">
                      {bonus?.bonusType} - {bonus?.bonusCode}
                    </div>
                    <div className="text-sm font-normal text-black/40">
                      <span className="font-semibold">Amount:</span> {bonus?.bonusAmount?.toFixed(2)}
                    </div>
                    <div className="text-sm font-normal text-black/40">
                      <span className="font-semibold">Month:</span> {bonus?.month} {' '}
                    </div>
                    <div className="text-sm font-normal text-black/40">
                      <span className="font-semibold">Year:</span> {bonus?.year}
                    </div>
                  </div>
                )}
                {editIndex !== index && (
                  <div className="flex flex-row gap-x-1 absolute right-4 top-6">
                    <button
                      onClick={() => setEditIndex(index)}
                      type="button"
                      className="hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center"
                    >
                      <Edit2 color="#16a34a" variant="Bold" size={20} />
                    </button>
                    <button
                      onClick={() => handleModalDelete(bonus)}
                      type="button"
                      className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-600"
                    >
                      <Trash color="#C84040" variant="Bold" size={20} />
                    </button>
                    <DeleteConfirmationModal
                      isOpen={isConfirmationModalOpen}
                      onClose={() => setIsConfirmationModalOpen(false)}
                      handleDelete={() => handleDelete(bonus)}
                      title={'bonus'}
                    />
                  </div>
                )}
                {editIndex === index && (
                  <EditBonus
                    bonus={bonus}
                    onClose={() => setEditIndex(null)}
                    employee={employee}
                    bonusOptions={bonusOptions}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BonusDetails;
