import React from 'react';
import { format } from 'date-fns';
import defaultPic from '../assets/images/Default_profilepic.png'

const NotificationsList = ({ notifications, onNotificationClick }) => {
    return (
      <div className="mt-4 w-full">
        {notifications && notifications.length > 0 ? (
          notifications?.map((notification) => (
            <div
              key={notification._id}
              onClick={() => onNotificationClick(notification)}
              className={`flex gap-x-3 flex-row p-4 mb-2 cursor-pointer ${notification.isRead ? '' : 'bg-blue-100'}`}
            >
              <div className="flex w-12 h-12 rounded-full">
                {notification?.senderId?.avatar ? (
                  <img src={notification?.senderId?.avatar} alt="pic" className="rounded-full w-12 h-12 object-cover" />
                ) : (
                  <img src={defaultPic} alt="pic" className="rounded-full w-12 h-12 object-cover" />
                )}
              </div>
              <div className="flex flex-col w-72">
                <span className="font-bold">{notification.message}</span>
                <span className="text-xs">{format(new Date(notification.createdDate), 'dd-MM-yyyy HH:mm')}</span>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 py-4">No Notifications</div>
        )}
      </div>
    );
};

export default NotificationsList;
