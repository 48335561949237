import { useDispatch, useSelector } from 'react-redux';
import SuperAdminLayout from '../superadminLayout';
import { useFormik } from 'formik';
import { createOrganizationSchema } from '../../../validations/validationSchemas';
import { useEffect, useState } from 'react';
import { addOrganization, getAllOrganization } from '../../../actions/superadminAction';
import OrganizationList from './organizationList';
import empty from '../../../assets/images/empty.png';

const SuperAdminCreateOrganization = () => {
  const dispatch = useDispatch();

  const [isAdd, setAdd] = useState(false);

  const organizations = useSelector((state) => state.superAdmin?.organizations || []);

  const formik = useFormik({
    initialValues: {
      organizationName: ''
    },
    validationSchema: createOrganizationSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(addOrganization(values, setAdd, formik.resetForm));
    }
  });

  useEffect(() => {
    dispatch(getAllOrganization());
  }, []);

  console.log('organizations: ', organizations);

  return (
    <SuperAdminLayout pageTitle={'Create New Organization'} page={'Super Admin'} subPage={'Create New Organization'}>
      <div className="w-full bg-white rounded-xl p-6 min-h-[80vh]">
        <div className="flex justify-end">
          <div
            className={`${isAdd ? 'bg-red-600' : 'bg-orange-600'} px-5 py-2 w-fit text-white rounded-xl font-bold cursor-pointer`}
            onClick={() => setAdd(!isAdd)}
          >
            {isAdd ? 'Cancel' : '+ Add Organization'}
          </div>
        </div>
        {isAdd && (
          <form onSubmit={formik.handleSubmit} className="max-w-[310px] md:max-w-[400px] w-full">
            <div className="bg-white p-6 rounded-2xl shadow-lg mt-5">
              <h4 className="text-2xl font-bold mb-5 text-black">Create Organization</h4>
              <div className="mb-4">
                <label className="mb-1 block text-white lg:text-black font-medium text-sm">Organization Name</label>
                <input
                  type="text"
                  id="organizationName"
                  name="organizationName"
                  placeholder="Enter Organization"
                  className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-orange-600 active:border-orange-600 disabled:cursor-default disabled:bg-whiter"
                  onChange={formik.handleChange}
                  value={formik.values.organizationName}
                />
                {formik.touched.organizationName && formik.errors.organizationName ? (
                  <div className="text-red-500 text-sm">{formik.errors.organizationName}</div>
                ) : null}
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-orange-600 hover:bg-orange-600/90 text-white font-bold rounded-lg px-4 py-2"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        )}
        {organizations?.length > 0 ? (
          <div className="mt-10">
            <OrganizationList allOrganization={organizations} />
          </div>
        ) : (
          <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
            <img src={empty} alt="empty data" />
            <div className="mt-5 text-purple1 font-bold text-xl">No Organizations</div>
          </div>
        )}
      </div>
    </SuperAdminLayout>
  );
};

export default SuperAdminCreateOrganization;
