import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import { employeeLeaveSchema } from '../../../validations/validationSchemas';
import { addLeave, deleteLeave } from '../../../actions/leaveAction';
import EditLeave from './editLeave';
import { Add, Edit2, Trash } from 'iconsax-react';
import DeleteConfirmationModal from '../../../components/deleteConfirmationModal';
import { yesOrNoOptions } from '../../../constant/dropdownConstOptions';

const LeaveDetails = ({ employee, company }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [leaveToDelete, setLeaveToDelete] = useState(null);

  const leaveOptions =
    company?.leaves?.map((leave) => ({
      label: `${leave?.leaveType} - ${leave?.leaveCode}`,
      value: leave?.leaveType,
      leaveType: leave?.leaveType,
      leaveCode: leave?.leaveCode,
      leaveId: leave?._id
    })) || [];

  const formik = useFormik({
    initialValues: {
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      leaveId: '',
      leaveType: '',
      leaveCode: '',
      totalDays: '',
      balanceDays: '',
      takenDays: '',
      isCarryForward: 0,
      carryForwardMaxDays: 0,
      status: 1
    },
    validationSchema: employeeLeaveSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      dispatch(addLeave(values, setEdit, resetForm));
    }
  });

  const handleModalDelete = (leave) => {
    setIsConfirmationModalOpen(true);
    setLeaveToDelete(leave)
  };

  const handleDelete = () => {
    if (leaveToDelete) {
      dispatch(
        deleteLeave(
          {
            _id: leaveToDelete?._id,
            employeeId: employee?._id,
            companyId: employee?.company?._id
          },
          setIsConfirmationModalOpen
        )
      );
    }
  };

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="flex flex-row justify-between items-center">
          <h3 className="font-bold text-black text-2xl">Leave Details</h3>
          {!edit && (
            <button
              className="w-16 h-8 rounded-lg flex items-center justify-center text-2xl font-bold bg-grayLight shadow-md"
              onClick={() => {
                formik.resetForm()
                setEdit(true)}
              }
            >
              <Add color="#3D54DD" variant="Linear" size={20} />
            </button>
          )}
        </div>
        {edit && (
          <div className="rounded-xl bg-white shadow-lg w-full mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-6">
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Leave Type</label>
                    <Dropdown
                      value={leaveOptions.find((option) => option.leaveType === formik.values.leaveType)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) {
                          formik.setValues({
                            ...formik.values,
                            leaveType: selectedValue.value,
                            leaveCode: selectedValue.leaveCode,
                            leaveId: selectedValue.leaveId
                          });
                        }
                      }}
                      options={leaveOptions}
                      isClearable={false}
                      placeholder="Select Leave"
                      name="allowanceType"
                      id="allowanceType"
                    />
                    {formik.touched.leaveType && formik.errors.leaveType ? (
                      <div className="text-red-500 text-sm">{formik.errors.leaveType}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Total Days</label>
                    <input
                      type="number"
                      placeholder="Total Days"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                      name="totalDays"
                      onChange={(e) =>
                        formik.setValues({
                          ...formik.values,
                          totalDays: e.target.value,
                          balanceDays: e.target.value,
                          takenDays: 0
                        })
                      }
                      value={formik.values.totalDays}
                    />
                    {formik.touched.totalDays && formik.errors.totalDays ? (
                      <div className="text-red-500 text-sm">{formik.errors.totalDays}</div>
                    ) : null}
                  </div>
                </div>
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Carry Forward?</label>
                    <Dropdown
                      value={yesOrNoOptions.find((option) => option.value === formik.values.isCarryForward)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption ? selectedOption.value : 0;
                        formik.setFieldValue('isCarryForward', selectedValue);
                      }}
                      options={yesOrNoOptions}
                      isClearable={false}
                      placeholder="Is Carry Forward?"
                      name="isCarryForward"
                      id="isCarryForward"
                    />
                    {formik.touched.isCarryForward && formik.errors.isCarryForward ? (
                      <div className="text-red-500 text-sm">{formik.errors.isCarryForward}</div>
                    ) : null}
                  </div>
                  {formik.values.isCarryForward === 1 ? (
                    <div className="w-full lg:w-1/2">
                      <label className="mb-2.5 block text-black font-bold">Maximum Days</label>
                      <input
                        type="number"
                        placeholder="Carry Forward Maximum Days"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                        name="carryForwardMaxDays"
                        onChange={formik.handleChange}
                        value={formik.values.carryForwardMaxDays}
                      />
                      {formik.touched.carryForwardMaxDays && formik.errors.carryForwardMaxDays ? (
                        <div className="text-red-500 text-sm">{formik.errors.carryForwardMaxDays}</div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="w-full lg:w-1/2"></div>
                  )}
                </div>
                <div className="flex justify-end w-full gap-x-2">
                  <button
                    type="button"
                    onClick={() => setEdit(false)}
                    className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="flex w-28 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="flex flex-wrap gap-3 mt-5">
          {employee?.leaveDetails?.leaves?.map((leave, index) => {
            return (
              <div
                className={`p-6 relative bg-white shadow-lg ${editIndex === index ? 'w-full' : 'w-[32%]'} rounded-2xl`}
                key={index}
              >
                {editIndex !== index && (
                  <div className="pr-12">
                    <div className="text-base text-black font-bold">
                      {leave?.leaveType} - {leave?.leaveCode}
                    </div>
                    <div className="text-sm font-normal text-black/40">
                      <span className="font-semibold">Total Days:</span> {leave?.totalDays}
                    </div>
                    <div className="text-sm font-normal text-black/40">
                      <span className="font-semibold">Balance:</span> {leave?.balanceDays}
                    </div>
                    {leave?.isCarryForward === 1 && <div className="text-sm font-normal text-black/40">
                      <span className="font-semibold">Max Days Carry Forward:</span> {leave?.carryForwardMaxDays}
                    </div>}
                  </div>
                )}

                {editIndex !== index && (
                  <div className="flex flex-row gap-x-1 absolute right-4 top-6">
                    <button
                      onClick={() => setEditIndex(index)}
                      type="button"
                      className="hover:bg-green-500 p-1.5 w-8 h-8 rounded-md flex justify-center"
                    >
                      <Edit2 color="#16a34a" variant="Bold" size={20} />
                    </button>
                    <button
                      onClick={() => handleModalDelete(leave)}
                      type="button"
                      className="flex justify-center w-8 h-8 p-1.5 rounded-md hover:bg-red-600"
                    >
                      <Trash color="#C84040" variant="Bold" size={20} />
                    </button>
                    <DeleteConfirmationModal
                      isOpen={isConfirmationModalOpen}
                      onClose={() => setIsConfirmationModalOpen(false)}
                      handleDelete={() => handleDelete(leave)}
                      title={'leave'}
                    />
                  </div>
                )}
                {editIndex === index && (
                  <EditLeave
                    leave={leave}
                    onClose={() => setEditIndex(null)}
                    employee={employee}
                    leaveOptions={leaveOptions}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LeaveDetails;
