import { Calendar1, Document } from 'iconsax-react';
import { useDispatch } from 'react-redux';
import { generatePayroll } from '../../../actions/payrollAction';
import DateDropdown from '../../../components/dateDropdown';
import { useState } from 'react';

const InitialGeneratePayroll = ({ companyId }) => {
  const dispatch = useDispatch();

  const currentDate = new Date();

  const [month, setMonth] = useState(currentDate.toLocaleString('default', { month: 'long' }));
  const [year, setYear] = useState(currentDate.getFullYear()); 

  const handleGeneratePayroll = () => {
    const inputValue = {
      month: month,
      year: year,
      companyId: companyId
    };
    console.log(inputValue);
    if (month && year) dispatch(generatePayroll(inputValue));
  };

  const getMonthOptions = () => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
    const nextMonthIndex = (currentMonthIndex + 1) % 12;

    return [
      { label: months[previousMonthIndex], value: months[previousMonthIndex] },
      { label: months[currentMonthIndex], value: months[currentMonthIndex] },
      { label: months[nextMonthIndex], value: months[nextMonthIndex] }
    ];
  };

  const monthOptions = getMonthOptions();

  const handleMonthChange = (selectedMonth) => {
    setMonth(selectedMonth.value);

    // Update year based on month selection
    if (selectedMonth.value === "January" && month === "December") {
      setYear((prevYear) => prevYear + 1);
    } else if (selectedMonth.value === "December" && month === "January") {
      setYear((prevYear) => prevYear - 1);
    }
  };

  return (
    <div className="bg-white p-6 rounded-2xl">
      <div className="flex flex-wrap justify-between gap-3">
        <div className="flex gap-x-5 items-center">
          <div className="w-12 h-12 bg-grayLight flex justify-center items-center rounded-full">
            <Calendar1 color="#3D54DD" variant="Broken" size={25} />
          </div>
          <div>
            <div className="text-black font-medium text-xs">Salary period</div>
            <div className="flex flex-wrap items-center gap-x-5 text-sm font-bold">
              <div>
                <DateDropdown
                  value={monthOptions.find((option) => option.value === month)}
                  onChange={handleMonthChange}
                  options={monthOptions}
                  isClearable={false}
                  placeholder="Month"
                  name="month"
                  id="month"
                />
              </div>

              <div className="text-black">{year}</div>
            </div>
          </div>
        </div>
        {/* <div></div> */}
        <button
          type="button"
          disabled={!companyId}
          onClick={() => handleGeneratePayroll()}
          className="bg-purple1 hover:bg-purple1/90 disabled:bg-gray-500 text-white font-bold rounded-xl px-6 h-14 text-right flex items-center gap-x-3"
        >
          <Document color="#ffffff" variant="Outline" size={25} />
          <span>Generate Payroll</span>
        </button>
      </div>
    </div>
  );
};

export default InitialGeneratePayroll;
