import { noPayLeaveSchema } from '../../../validations/validationSchemas';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { updateNoPayLeave } from '../../../actions/companyAction';

const EditNoPayLeave = ({ company, leave, onClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      companyId: company?._id,
      _id: leave?._id,
      noPayLeaveType: leave?.noPayLeaveType || '',
      noPayLeaveCode: leave?.noPayLeaveCode || ''
    },
    validationSchema: noPayLeaveSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(updateNoPayLeave(values, onClose));
    }
  });

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Leave Type</label>
              <input
                type="text"
                placeholder="Leave Type"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="noPayLeaveType"
                onChange={formik.handleChange}
                value={formik.values.noPayLeaveType}
              />
              {formik.touched.noPayLeaveType && formik.errors.noPayLeaveType ? (
                <div className="text-red-500 text-sm">{formik.errors.noPayLeaveType}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Leave Code</label>
              <input
                type="text"
                placeholder="Leave Code"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="noPayLeaveCode"
                onChange={formik.handleChange}
                value={formik.values.noPayLeaveCode}
              />
              {formik.touched.noPayLeaveCode && formik.errors.noPayLeaveCode ? (
                <div className="text-red-500 text-sm">{formik.errors.noPayLeaveCode}</div>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditNoPayLeave;
