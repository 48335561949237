import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { getCurrentCompany } from './companyAction';
import { getEmployee } from './employeeAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_CURRENT_PAYROLL = 'FETCH_CURRENT_PAYROLL';
export const FETCH_HISTORY_PAYROLL = 'FETCH_HISTORY_PAYROLL';
export const FETCH_PAYSLIPS = 'FETCH_PAYSLIPS';
export const SET_SELECTED_PAYSLIP = 'SET_SELECTED_PAYSLIP';
export const SET_CLICKED_PAYSLIP = 'SET_CLICKED_PAYSLIP';

export const fetchCurrentPayroll = (data) => ({
  type: FETCH_CURRENT_PAYROLL,
  payload: data
});

export const fetchHistoryPayroll = (data) => ({
  type: FETCH_HISTORY_PAYROLL,
  payload: data
});

export const fetchPaySlips = (data) => ({
  type: FETCH_PAYSLIPS,
  payload: data
});

export const setSelectedPayslip = (data) => ({
  type: SET_SELECTED_PAYSLIP,
  payload: data
});

export const setClickPayslips = (data) => ({
  type: SET_CLICKED_PAYSLIP,
  payload: data
});

export const generatePayroll = (inputData) => {
  console.log(inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/generate-payroll`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentPayroll(inputData?.companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getCurrentPayroll = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/payroll/get-current-payroll`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('payroll: ', response.data);
        dispatch(fetchCurrentPayroll(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const submitApproval = (currentPayrollId, companyId) => {

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/submit-payroll-approval`, { currentPayrollId }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentPayroll(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approvePayroll = (currentPayrollId, companyId) => {

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/approve-payroll`, { currentPayrollId }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentPayroll(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const confirmPayroll = (currentPayrollId, payRollCutOffDate, companyId) => {

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/confirm-payroll`, { currentPayrollId, payRollCutOffDate }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentPayroll(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectPayroll = (currentPayrollId, companyId) => {

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/reject-payroll`, { currentPayrollId }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentPayroll(companyId));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updatePayrollEmployee = (inputData, navigate) => {
  console.log(inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/update-payroll-employee`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentPayroll());
        navigate('/payroll/generate-payroll');
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPayslips = (inputData, setLoader) => {
  console.log(inputData);

  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/payroll/get-payslips`, {
        params: inputData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('payslips res: ', response.data);
        dispatch(fetchPaySlips(response.data));
        setLoader(false)
        ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        dispatch(fetchPaySlips([]));
        setLoader(false)
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        dispatch(fetchPaySlips([]));
        setLoader(false)
        ToastBar.error(error.message);
      }
    }
  };
};

export const generatePayrollHistory = (inputData) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/payroll/get-payroll-history`, {
        params: inputData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('history payroll: ', response.data);
        dispatch(fetchHistoryPayroll(response.data));
        //ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        dispatch(fetchHistoryPayroll({}));
        //ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        dispatch(fetchHistoryPayroll({}));
        ToastBar.error(error.message);
      }
    }
  };
};

export const deletePayroll = (inputData, setIsConfirmationModalOpen) => {
  console.log('input: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/payroll/delete-payroll`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentPayroll());
        setIsConfirmationModalOpen(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};
