import { useFormik } from 'formik';
import { employeeNoPayLeaveSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import { updateNoPayLeave } from '../../../actions/nopayLeaveAction';

const EditNoPayLeave = ({ leave, noPayLeaveOptions, onClose, employee }) => {
  console.log('leave: ', leave)
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      _id: leave?._id,
      noPayLeaveId: leave?.noPayLeaveId || '',
      noPayLeaveType: leave?.noPayLeaveType || '',
      noPayLeaveCode: leave?.noPayLeaveCode || '',
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      status: leave?.status
    },
    validationSchema: employeeNoPayLeaveSchema,
    onSubmit: async (values) => {
      console.log('update value: ', values);
      dispatch(updateNoPayLeave(values, onClose));
    }
  });

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">No Pay Leave Type</label>
              <Dropdown
                value={noPayLeaveOptions.find((option) => option.noPayLeaveType === formik.values.noPayLeaveType)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    formik.setValues({
                      ...formik.values,
                      noPayLeaveType: selectedValue.value,
                      noPayLeaveCode: selectedValue.noPayLeaveCode,
                      noPayLeaveId: selectedValue.noPayLeaveId
                    });
                  }
                }}
                options={noPayLeaveOptions}
                isClearable={false}
                placeholder="Select No Pay Leave"
                name="noPayLeaveType"
                id="noPayLeaveType"
                isDisabled
              />
              {formik.touched.noPayLeaveType && formik.errors.noPayLeaveType ? (
                <div className="text-red-500 text-sm">{formik.errors.noPayLeaveType}</div>
              ) : null}
            </div>
          </div>
          
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditNoPayLeave;
