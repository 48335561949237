import * as Yup from 'yup';

export const loginCandidateSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required('Please enter your email address')
    .email('Please enter a valid email address')
    .max(200, 'Email cannot exceed 200 characters'),
  password: Yup.string().required('Password is required')
});

export const loginSuperAdminSchema = Yup.object({
  username: Yup.string().required('Please enter your username'),
  password: Yup.string().required('Please enter your Password')
});

export const createOrganizationSchema = Yup.object({
  organizationName: Yup.string().required('Organization name is required')
});

export const createCompanySchema = Yup.object({
  organizationId: Yup.string().required('Select organization'),
  companyName: Yup.string().required('Company name is required')
});

export const createAdminSchema = Yup.object({
  organizationId: Yup.string().required('Select Organization'),
  companyId: Yup.string().required('Select Company'),
  fullName: Yup.string().required('Please enter full name'),
  email: Yup.string()
    .trim()
    .required('Please enter email address')
    .email('Please enter a valid email address')
    .max(200, 'Email cannot exceed 200 characters'),
  password: Yup.string()
    .required('Password is required.')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
    .min(8, 'Password must be at least 8 characters long.')
    .max(128, 'Password cannot be more than 128 characters long.'),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('Please confirm password.'),
  role: Yup.string().required('Role is required'),
});

export const addCompanySchema = Yup.object({
  organizationId: Yup.string().required('Organization ID is empty'),
  companyName: Yup.string().required('Company name is required')
});

export const editCompanySchema = Yup.object().shape({
  companyName: Yup.string().required('Company name is required'),
  // email: Yup.string().email('Invalid email address').required('Email is required'),
  entityType: Yup.string().required('Entity type is required'),
  uen: Yup.string().required('UEN is required'),
  csn: Yup.string().required('CSN is required'),
  industry: Yup.string().required('Industry is required'),
  gstRegistered: Yup.string().required('GST registration status is required'),
  postalCode: Yup.string().required('Postal code is required'),
  address: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  areaCode: Yup.string()
    .matches(/^\+\d{1,3}$/, 'Country code must start with a + followed by 1 to 3 digits')
    .required('Country code is required'),
  officeNo: Yup.string().matches(/^\d+$/, 'Office number must be a valid number').required('Office number is required')
});

export const addEmployeeSchema = Yup.object().shape({
  salutation: Yup.string().required('Salutation is required'),
  fullName: Yup.string().required('Full name is required'),
  idType: Yup.string().required('ID type is required'),
  idNum: Yup.string().required('ID number is required'),
  personalEmail: Yup.string().email('Invalid email').required('Personal email is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  dob: Yup.number().required('Date of birth is required'),
  maritalStatus: Yup.string().required('Marital status is required'),
  areaCode: Yup.string().required('Area code is required'),
  mobile: Yup.string().required('Mobile number is required'),
  // areaCodeContact: Yup.string().required('Area code for contact is required'),
  // contact: Yup.string().required('Contact number is required'),
  religion: Yup.string().required('Religion is required'),
  race: Yup.string().required('Race is required'),
  nationality: Yup.string().required('Nationality is required'),
  gender: Yup.string().required('Gender is required'),
  citizenship: Yup.string().required('Citizenship is required'),
  prObtained: Yup.string().when('citizenship', {
    is: (value) => value === 'PR',
    then: (schema) => schema.required('PR Obtain Date is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  reEntryExpiration: Yup.string().when('citizenship', {
    is: (value) => value === 'PR',
    then: (schema) => schema.required('Re-entry Expiration Date is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  workPassType: Yup.string().when('citizenship', {
    is: (value) => value === 'Foreigner',
    then: (schema) => schema.required('Work Pass Type is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  workPassObtained: Yup.string().when('citizenship', {
    is: (value) => value === 'Foreigner',
    then: (schema) => schema.required('Work Pass Obtain Date is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  workPassExpiration: Yup.string().when('citizenship', {
    is: (value) => value === 'Foreigner',
    then: (schema) => schema.required('Work Pass Expiration Date is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  address: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  postal: Yup.string().required('Postal code is required')
});

export const employmentDetailsSchema = Yup.object().shape({
  //branchName: Yup.string().required('Branch is required'),
  departmentName: Yup.string().required('Department is required'),
  designationName: Yup.string().required('Designation is required'),
  empType: Yup.string().required('Employee type is required'),
  empStatus: Yup.string().required('Employee status is required'),
  role: Yup.string().required('Role is required'),
  startDate: Yup.number().required('Start date is required').nullable(),
  endDate: Yup.number().nullable().min(Yup.ref('startDate'), 'End date cannot be before start date')
});

// export const employeePayDetailSchema = Yup.object().shape({
//   payMethod: Yup.string().required('Pay method is required'),
//   currency: Yup.string().required('Currency is required'),
//   basicSalary: Yup.number().min(0.01, 'Basic salary must be at least 0.01').nullable(),
//   hourlyRate: Yup.number().min(0.01, 'Hourly rate must be at least 0.01').nullable(),
//   grossSalary: Yup.number().required('Gross salary is required').min(0, 'Gross salary must be at least 0.01'),
//   employeeCPFPercentage: Yup.number()
//     .required('Employee CPF percentage is required')
//     .min(0, 'Employee CPF percentage must be at least 0')
//     .max(100, 'Employee CPF percentage must be at most 100'),
//   employerCPFPercentage: Yup.number()
//     .required('Employer CPF percentage is required')
//     .min(0, 'Employer CPF percentage must be at least 0')
//     .max(100, 'Employer CPF percentage must be at most 100'),
//   employeeCPF: Yup.number().required('Employee CPF is required').min(0, 'Employee CPF must be at least 0.01'),
//   employerCPF: Yup.number().required('Employer CPF is required').min(0, 'Employer CPF must be at least 0.01'),
//   totalCPFContribution: Yup.number()
//     .required('Total CPF contribution is required')
//     .min(0, 'Total CPF contribution must be at least 0'),
//   bank: Yup.string().required('Bank is required'),
//   bankAccountNum: Yup.string().required('Bank account number is required'),
// }).test('basic-or-hourly', 'Either basic salary or hourly rate is required', function (value) {
//   return value.basicSalary || value.hourlyRate;
// });

export const employeePayDetailSchema = Yup.object()
  .shape({
    payMethod: Yup.string().required('Pay method is required'),
    currency: Yup.string().required('Currency is required'),
    basicSalary: Yup.number().min(0.01, 'Basic salary must be at least 0.01').nullable(),
    hourlyRate: Yup.number().min(0.01, 'Hourly rate must be at least 0.01').nullable(),
    grossSalary: Yup.number().required('Gross salary is required').min(0, 'Gross salary must be at least 0.01'),
    employeeCPFPercentage: Yup.number().when('cpf', {
      is: 1,
      then: (schema) => schema.required('Employee CPF percentage is required').min(0).max(100),
      otherwise: (schema) => schema.notRequired()
    }),
    employerCPFPercentage: Yup.number().when('cpf', {
      is: 1,
      then: (schema) => schema.required('Employer CPF percentage is required').min(0).max(100),
      otherwise: (schema) => schema.notRequired()
    }),
    employeeCPF: Yup.number().when('cpf', {
      is: 1,
      then: (schema) => schema.required('Employee CPF is required').min(0),
      otherwise: (schema) => schema.notRequired()
    }),
    employerCPF: Yup.number().when('cpf', {
      is: 1,
      then: (schema) => schema.required('Employer CPF is required').min(0),
      otherwise: (schema) => schema.notRequired()
    }),
    totalCPFContribution: Yup.number().when('cpf', {
      is: 1,
      then: (schema) => schema.required('Total CPF is required').min(0),
      otherwise: (schema) => schema.notRequired()
    }),
    bank: Yup.string().required('Bank is required'),
    bankAccountNum: Yup.string().required('Bank account number is required')
  })
  .test('basic-or-hourly', 'Either basic salary or hourly rate is required', function (value) {
    return value.basicSalary || value.hourlyRate;
  });

export const branchSchema = Yup.object().shape({
  branchName: Yup.string().required('Branch Name is required'),
  branchCode: Yup.string().required('Branch Code is required'),
  postalCode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Postal Code must be exactly 6 Digits')
    .required('Postal Code is required'),
  address: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  areaCode: Yup.string()
    .matches(/^\+\d{1,3}$/, 'Country code must start with a + followed by 1 to 3 digits')
    .required('Country code is Required'),
  officeNo: Yup.string().matches(/^\d+$/, 'Office Number must be a valid number').required('Office Number is required')
});

export const departmentSchema = Yup.object().shape({
  departmentName: Yup.string().required('Department Name is required'),
  departmentCode: Yup.string().required('Department Code is required')
});

export const credentialDetailsSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Enter a valid email address')
    .max(200, 'Email cannot exceed 200 characters'),
  password: Yup.string()
    .required('Password is required')
    .matches(/[A-Z]/, 'Uppercase required')
    .min(8, 'Password must have at least 8 characters')
    .max(128, 'Password can not exceed 128 characters')
});

export const approvingDetailsSchema = Yup.object({
  fullName: Yup.string().required('Approving Manager is required')
});

export const designationSchema = Yup.object().shape({
  designationName: Yup.string().required('Designation Name is required'),
  designationCode: Yup.string().required('Designation Code is required')
});

export const leaveSchema = Yup.object().shape({
  leaveType: Yup.string().required('Leave Type is required'),
  leaveCode: Yup.string().required('Leave Code is required')
});

export const medicalLeaveSchema = Yup.object().shape({
  medicalType: Yup.string().required('Medical Type is required'),
  medicalCode: Yup.string().required('Medical Code is required')
});

export const claimSchema = Yup.object().shape({
  claimType: Yup.string().required('Claim Type is required'),
  claimCode: Yup.string().required('Claim Code is required')
});

export const allowanceSchema = Yup.object().shape({
  allowanceType: Yup.string().required('Allowance Type is required'),
  allowanceCode: Yup.string().required('Allowance Code is required')
});

export const deductionSchema = Yup.object().shape({
  deductionType: Yup.string().required('Deduction Type is required'),
  deductionCode: Yup.string().required('Deduction Code is required')
});

export const bonusSchema = Yup.object().shape({
  bonusType: Yup.string().required('Bonus Type is required'),
  bonusCode: Yup.string().required('Bonus Code is required')
});

export const noPayLeaveSchema = Yup.object().shape({
  noPayLeaveType: Yup.string().required('Leave Type is required'),
  noPayLeaveCode: Yup.string().required('Leave Code is required')
});

export const employeeAllowanceSchema = Yup.object().shape({
  allowanceType: Yup.string().required('Allowance Type is required'),
  allowanceAmount: Yup.number().typeError('Amount must be a number').required('Amount is required'),
  allowanceFrequency: Yup.string().required('frequency is required')
});

export const employeeBonusSchema = Yup.object().shape({
  bonusType: Yup.string().required('Bonus Type is required'),
  bonusAmount: Yup.number().typeError('Amount must be a number').required('Amount is required')
});

export const employeeDeductionSchema = Yup.object().shape({
  deductionType: Yup.string().required('Deduction Type is required'),
  deductionAmount: Yup.number().typeError('Amount must be a number').required('Amount is required'),
  deductionFrequency: Yup.string().required('frequency is required')
});

export const employeeClaimSchema = Yup.object().shape({
  claimType: Yup.string().required('Claim Type is required'),
  isLimit: Yup.number().required('Has Limit is required').oneOf([0, 1], 'Has Limit must be 0 or 1'),
  claimLimit: Yup.number()
    .nullable()
    .when('isLimit', {
      is: 1,
      then: (schema) =>
        schema
          .required('Claim Limit is required')
          .min(1, 'Claim Limit must be greater than 0')
          .test('is-positive', 'Claim Limit must be a positive number greater than 0', (value) => value > 0),
      otherwise: (schema) => schema.notRequired().nullable()
    })
});

export const employeeLeaveSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee ID is required'),
  leaveId: Yup.string().nullable(),
  leaveType: Yup.string().required('Leave type is required'),
  leaveCode: Yup.string().required('Leave code is required'),
  totalDays: Yup.number()
    .required('Total days are required')
    .integer('Total days must be an integer')
    .min(0, 'Total days must be at least 0'),
  balanceDays: Yup.number()
    .required('Balance days are required')
    .integer('Balance days must be an integer')
    .min(0, 'Balance days must be at least 0'),
  takenDays: Yup.number()
    .required('Taken days are required')
    .integer('Taken days must be an integer')
    .min(0, 'Taken days must be at least 0'),
  isCarryForward: Yup.number()
    .required('isCarryForward is required')
    .oneOf([0, 1], 'isCarryForward must be either 0 or 1'),
  carryForwardMaxDays: Yup.number()
    .test('carry-forward-max-days', 'Carry forward max days must be at least 1', function(value) {
      const { isCarryForward } = this.parent;
      if (isCarryForward === 1 && !value) {
        return false; 
      }
      return true;
    })
});


export const employeeNoPayLeaveSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee ID is required'),
  noPayLeaveId: Yup.string().nullable(),
  noPayLeaveType: Yup.string().required('No Pay Leave type is required'),
  noPayLeaveCode: Yup.string().required('No Pay Leave code is required')
});

export const employeeMedicalSchema = Yup.object().shape({
  employeeId: Yup.string().required('Employee ID is required'),
  medicalId: Yup.string().nullable(),
  medicalType: Yup.string().required('Leave type is required'),
  medicalCode: Yup.string().required('Leave code is required'),
  totalDays: Yup.number()
    .required('Total days are required')
    .integer('Total days must be an integer')
    .min(0, 'Total days must be at least 0'),
  balanceDays: Yup.number()
    .required('Balance days are required')
    .integer('Balance days must be an integer')
    .min(0, 'Balance days must be at least 0'),
  takenDays: Yup.number()
    .required('Taken days are required')
    .integer('Taken days must be an integer')
    .min(0, 'Taken days must be at least 0')
});

export const applyLeaveSchema = Yup.object().shape({
  leaveType: Yup.string().required('Leave Type is required'),
  fromDate: Yup.string().required('From Date is required'),
  toDate: Yup.string().required('To Date is required')
});

export const applyNoPayLeaveSchema = Yup.object().shape({
  noPayLeaveType: Yup.string().required('Leave Type is required'),
  fromDate: Yup.string().required('From Date is required'),
  toDate: Yup.string().required('To Date is required'),
  noPayLeaveAmount: Yup.number()
    .typeError('Amount must be a number')
    .required('Amount is required')
    .min(0, 'Amount cannot be negative')
});

export const applyMedicalSchema = Yup.object().shape({
  medicalType: Yup.string().required('Medical Leave Type is required'),
  fromDate: Yup.string().required('From Date is required'),
  toDate: Yup.string().required('To Date is required')
});

export const submitClaimSchema = Yup.object().shape({
  claimType: Yup.string().required('Claim Type is required'),
  claimDate: Yup.string().required('Claim Date is required'),
  claimAmount: Yup.string().required('Claim Amount is required'),
  claimFile: Yup.string().required('File is required'),
  claimRemark: Yup.string().required('Remark is required')
});

export const createNewPasswordSchema = Yup.object({
  password: Yup.string()
    .required('Password is required.')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
    .min(8, 'Password must be at least 8 characters long.')
    .max(128, 'Password cannot be more than 128 characters long.'),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('Please confirm your password.'),
  email: Yup.string().email('Please enter a valid email address.').required('Email is required.')
});

export const payrollCutOffSchema = Yup.object().shape({
  day: Yup.string().required('Day Type is required')
});

export const payrollEmployeeEditSchema = Yup.object().shape({
  _id: Yup.string().nullable(),
  fullName: Yup.string().required('Full name is required'),
  paidDays: Yup.number()
    .typeError('Paid days must be a number')
    .required('Paid days are required')
    .min(0, 'Paid days cannot be negative'),
  grossPay: Yup.number()
    .typeError('Gross pay must be a number')
    .required('Gross pay is required')
    .min(0, 'Gross pay cannot be negative'),
  deductions: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Deduction name is required'),
        amount: Yup.number()
          .typeError('Deduction amount must be a number')
          .required('Deduction amount is required')
          .min(0, 'Deduction amount cannot be negative')
      })
    )
    .required('Deductions are required'),
  allowances: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Allowance name is required'),
        amount: Yup.number()
          .typeError('Allowance amount must be a number')
          .required('Allowance amount is required')
          .min(0, 'Allowance amount cannot be negative')
      })
    )
    .required('Allowances are required'),
  claims: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Claim name is required'),
        amount: Yup.number()
          .typeError('Claim amount must be a number')
          .required('Claim amount is required')
          .min(0, 'Claim amount cannot be negative')
      })
    )
    .required('Claims are required'),
  netPay: Yup.number().required('Net Pay are required').min(0, 'Net Pay cannot be negative'),
  bankName: Yup.string().required('Bank name is required'),
  accountName: Yup.string().required('Account name is required'),
  accountNumber: Yup.string().required('Account number is required'),
  branch: Yup.string().required('Branch is required')
});

export const addTimesheetValidationSchema = Yup.object().shape({
  date: Yup.string().required('Date is required'),
  startTime: Yup.object()
    .shape({
      hours: Yup.string().required('Start time hours are required'),
      minutes: Yup.string().required('Start time minutes are required'),
      period: Yup.string().required('Start time period (AM/PM) is required')
    })
    .required(),
  endTime: Yup.object()
    .shape({
      hours: Yup.string().required('End time hours are required'),
      minutes: Yup.string().required('End time minutes are required'),
      period: Yup.string().required('End time period (AM/PM) is required')
    })
    .required(),
  hours: Yup.object()
    .shape({
      hours: Yup.number().required('Hours are required'),
      minutes: Yup.number().required('Minutes are required')
    })
    .required(),
  hourlyRate: Yup.number().required('Hourly rate is required'),
  timesheetAmount: Yup.number().required('Amount is required').min(0.1, 'Amount must be at least 0.01')
});
