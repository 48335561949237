import React, { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import defaultPic from '../assets/images/Default_profilepic.png';
import { ArrowRight2 } from 'iconsax-react';
import { officeStatusOptions } from '../constant/dropdownConstOptions';
import { useDispatch } from 'react-redux';
import { updateUserOfficeStatus } from '../actions/employeeAction';

const HeaderProfileDropdown = ({
  name,
  id,
  value,
  user,
  menuPlacement = 'auto',
  isClearable = false,
  isSearchable = false,
  isDisabled = false
}) => {
  const dispatch = useDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    };

    // Add event listener for clicks outside of dropdown
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const customStyles = {
    control: (provided) => ({
      display: 'none'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      marginTop: '0.5rem',
      width: '140px',
      left: 0
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? '#3C50E0' : provided.backgroundColor
    })
  };

  const handleLoadOptions = (inputValue, callback) => {
    const filteredOptions = officeStatusOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(filteredOptions);
  };

  const handleChange = (selectedOption) => {
    console.log('selectedOption: ', selectedOption);
    const newStatus = { officeStatus: selectedOption?.value}
    dispatch(updateUserOfficeStatus(newStatus, user._id))
  };

  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={`flex items-center p-2.5 hover:bg-gray-100 cursor-pointer`}
      >
        <span
          style={{
            display: 'inline-block',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: data.color,
            marginRight: '8px'
          }}
        ></span>
        <span>{data.label}</span>
      </div>
    );
  };

  const officeStatus = () => {
    if (!user || !user.officeStatus) {
      return 'bg-red-500';
    }
  
    switch (user.officeStatus) {
      case 'Available':
        return 'bg-green-500';
      case 'Out of Office':
        return 'bg-orange-500';
      default:
        return 'bg-red-500';
    }
  }

  console.log('officeStatus: ', user.officeStatus)

  return (
    <div ref={dropdownRef}>
      <div
        className="flex items-center justify-start gap-x-2 bg-white h-11 pl-2 pr-3 rounded-full shadow-lg cursor-pointer"
        onClick={() => setMenuIsOpen(!menuIsOpen)}
      >
        <span className="h-8 w-8 rounded-full relative">
          <img
            src={user?.avatar ? user?.avatar : defaultPic}
            alt="User"
            className="w-full h-full rounded-full object-cover"
          />
          <div className={`absolute border-[1px] h-2.5 w-2.5 ${officeStatus()} rounded-full bottom-0 right-0`}></div>
        </span>

        <span className="hidden text-right lg:block">
          <span className="block text-sm font-medium text-black/40">{user?.fullName}</span>
        </span>

        <ArrowRight2 color="rgba(0, 0, 0, 0.4)" variant="TwoTone" size={14} />
      </div>
      <AsyncSelect
        cacheOptions
        defaultOptions
        value={value}
        onChange={(selectedOption) => {
          handleChange(selectedOption);
          setMenuIsOpen(false);
        }}
        loadOptions={handleLoadOptions}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        id={id}
        menuPlacement={menuPlacement}
        styles={customStyles}
        menuIsOpen={menuIsOpen}
        onMenuClose={() => setMenuIsOpen(false)}
        className="w-full text-sm"
        components={{ Option: customOption }}
      />
    </div>
  );
};

export default HeaderProfileDropdown;
