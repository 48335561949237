import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { getCurrentEmployee } from './employeeAction';
import { fetchDashboardDetails, getAllCompanies, getCompanyById } from './companyAction';
import { createApplyLeaveNotification, createApplyMedicalLeaveNotification, createApplyNoPayLeaveNotification, createSubmitClaimNotification, createSubmitTimesheetNotification } from './notificationAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_PAYSLIPS = 'FETCH_PAYSLIPS';
export const FETCH_LEAVES = 'FETCH_LEAVES';
export const FETCH_USER_LOGOUT = 'FETCH_USER_LOGOUT';

export const fetchPaySlips = (data) => ({
  type: FETCH_PAYSLIPS,
  payload: data
});

export const fetchLeaves = (data) => ({
  type: FETCH_PAYSLIPS,
  payload: data
});

export const fetchUserLogout = () => ({
  type: FETCH_USER_LOGOUT
})

export const userLogin = (inputData, navigate) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/employee-login`, inputData);

      if (response.status === 200) {
        console.log('res: ', response);

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);

        await dispatch(getCurrentEmployee(response.data.employee.userId));
        ToastBar.success(response.data.successMsg[0]);

        const role = response.data.employee.role;

        if (role === "Admin") {
          await dispatch(getCompanyById({ companyId: response?.data?.employee?.companyId }));
          await dispatch(getAllCompanies());
          navigate('/dashboard');
        }
        else if (role === "Manager") {
          await dispatch(getCompanyById({ companyId: response?.data?.employee?.companyId }));
          navigate('/manager/dashboard');
        }
        else if (role === "Employee") {
          await dispatch(getCompanyById({ companyId: response?.data?.employee?.companyId }));
          navigate('/user/dashboard');
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      console.error("No refresh token found");
      throw new Error("No refresh token found");
    }

    console.log("Attempting to refresh token...");
    const response = await axios.post(`${apiUrl}/user/refresh-token`, { refreshToken });

    if (response.status === 200) {
      console.log("Token successfully refreshed");
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return response.data.token;
    } else {
      console.error("Failed to refresh token, non-200 response");
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error;
  }
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  console.log("Processing queue, error:", error, "token:", token);

  failedQueue.forEach(prom => {
    if (error) {
      console.log("Rejecting pending request due to error");
      prom.reject(error);
    } else {
      console.log("Resolving pending request with new token");
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // If the error is a 401 Unauthorized and is due to the access token
    if (error.response && error.response.status === 401) {
      console.log("Handling 401 error");

      // If already in the process of refreshing, add the request to the failedQueue
      if (isRefreshing) {
        console.log("Already refreshing, waiting...");
        
        return new Promise((resolve, reject) => {
          // Set up a timeout mechanism to prevent infinite waiting
          const timeout = setTimeout(() => {
            console.error("Timeout waiting for refresh to complete, logging out user...");
            reject(new Error("Timeout waiting for refresh to complete"));
            performLogout(); // Log out the user if refresh takes too long
          }, 2000); // 1 seconds timeout

          // Add the request to the queue
          failedQueue.push({
            resolve: (token) => {
              clearTimeout(timeout);
              resolve(token);
            },
            reject: (err) => {
              clearTimeout(timeout);
              reject(err);
            }
          });
        })
          .then(token => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch(err => {
            console.log("Failed request due to refresh token expiry, logging out");
            performLogout(); // Log out user if refresh fails
            return Promise.reject(err);
          });
      }

      // Mark that we are trying to refresh the token
      originalRequest._retry = true;
      isRefreshing = true;

      try {
        console.log("Initiating refresh...");
        const newAccessToken = await refreshAccessToken();
        console.log("Refresh successful");

        // Process all pending requests with the new access token
        processQueue(null, newAccessToken);

        // Set the new token to the original request and retry it
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        console.error("Refresh token failed:", refreshError);

        // If refresh token fails, reject all waiting requests and log out the user
        processQueue(refreshError, null);
        performLogout();
        return Promise.reject(refreshError);
      } finally {
        console.log("Resetting isRefreshing");
        isRefreshing = false; // Reset refresh state to allow future refresh attempts
      }
    }

    // If the error is not due to token expiry, reject it as usual
    return Promise.reject(error);
  }
);


export const performLogout = () => {
  try {
    console.log("Performing logout...");
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  } catch (error) {
    console.error("Error clearing localStorage during logout: ", error);
  } finally {
    setTimeout(() => {
      window.location.href = '/login'; // Ensure redirection happens immediately
    }, 0);
  }
};

export const userLogout = (navigate) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/employee-logout`, {}, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('res: ', response);
        ToastBar.success(response.data.successMsg[0]);
        performLogout();
        if (navigate) navigate('/login');
      }
    } catch (error) {
      console.error('Error during logout: ', error);
      performLogout();
      if (navigate) navigate('/login');
    }
  };
};

export const getUserPayslips = (inputData, setLoader) => {
  console.log(inputData);

  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/user/get-payslips`, {
        params: inputData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('payslips res: ', response.data);
        dispatch(fetchPaySlips(response.data));
        setLoader(false);
        // ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        dispatch(fetchPaySlips([]));
        setLoader(false);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        dispatch(fetchPaySlips([]));
        setLoader(false);
        ToastBar.warning(error.response.data.errorMsg[0]);
      }
    }
  };
};

export const getUserDashboard = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/user/get-user-dashboard`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('api res: ', response.data);
        dispatch(fetchDashboardDetails(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};