import axios from 'axios';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_LEAVE_REPORTS = 'FETCH_LEAVE_REPORTS';
export const FETCH_MEDICAL_REPORTS = 'FETCH_MEDICAL_REPORTS';
export const FETCH_CLAIM_REPORTS = 'FETCH_CLAIM_REPORTS';
export const FETCH_TIMESHEET_REPORTS = 'FETCH_TIMESHEET_REPORTS';
export const FETCH_NO_PAY_LEAVE_REPORTS = 'FETCH_NO_PAY_LEAVE_REPORTS';
export const FETCH_PAST_EMPLOYEES_REPORTS = 'FETCH_PAST_EMPLOYEES_REPORTS';
export const FETCH_CURRENT_EMPLOYEES_REPORTS = 'FETCH_CURRENT_EMPLOYEES_REPORTS';

export const fetchLeaveReports = (data) => ({
  type: FETCH_LEAVE_REPORTS,
  payload: data
});

export const fetchNoPayLeaveReports = (data) => ({
  type: FETCH_NO_PAY_LEAVE_REPORTS,
  payload: data
});

export const fetchMedicalReports = (data) => ({
  type: FETCH_MEDICAL_REPORTS,
  payload: data
});

export const fetchClaimReports = (data) => ({
  type: FETCH_CLAIM_REPORTS,
  payload: data
});

export const fetchTimesheetReports = (data) => ({
  type: FETCH_TIMESHEET_REPORTS,
  payload: data
});

export const fetchPastEmployeesReports = (data) => ({
  type: FETCH_PAST_EMPLOYEES_REPORTS,
  payload: data
});

export const fetchCurrentEmployeesReports = (data) => ({
  type: FETCH_CURRENT_EMPLOYEES_REPORTS,
  payload: data
});

export const getLeavesReport = (inputValue) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/report/get-leaves-report`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('reports: ', response.data);
        dispatch(fetchLeaveReports(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getMedicalsReport = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/report/get-medicals-report`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('reports: ', response.data);
        dispatch(fetchMedicalReports(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getClaimsReport = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/report/get-claims-report`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('reports: ', response.data);
        dispatch(fetchClaimReports(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getTimesheetsReport = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/report/get-timesheets-report`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('reports: ', response.data);
        dispatch(fetchTimesheetReports(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getNoPayLeavesReport = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/report/get-no-pay-leaves-report`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('reports: ', response.data);
        dispatch(fetchNoPayLeaveReports(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getPastEmployeesReport = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/report/get-past-employees`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('reports: ', response.data);
        dispatch(fetchPastEmployeesReports(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getCurrentEmployeesReport = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/report/get-current-employees`, {
        params: inputValue,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('reports: ', response.data);
        dispatch(fetchCurrentEmployeesReports(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};