import React from 'react';
import AsyncSelect from 'react-select/async';
import { useCountries } from 'use-react-countries';

const CountryCodeDropdown = ({
  value,
  onChange,
  name,
  id,
  placeholder = 'Select',
  menuPlacement = 'bottom',
  isClearable = true,
  isSearchable = true
}) => {
  const { countries } = useCountries();

  const newCountries = countries.map((country) => ({
    label: country.countryCallingCode,
    value: country.countryCallingCode,
    flag: country.flags.svg,
    name: country.name
  }));

  const sortedCountries = newCountries.sort((a, b) => {
    if (a.name === 'Singapore') return -1;
    if (b.name === 'Singapore') return 1;
    return a.name.localeCompare(b.name);
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 48,
      backgroundColor: '#F6F6F9',
      borderColor: state.isFocused ? '#3C50E0' : 'transparent',
      borderWidth: 1.5,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? '#3C50E0' : 'transparent'
      }
    }),
    option: (provided) => ({
      ...provided,
      width: '260px',
      overflowY: 'hidden'
    }),
    menu: (provided) => ({
      ...provided,
      width: '260px',
      overflowX: 'hidden'
    }),
    menuList: (provided) => ({
      ...provided,
      width: '260px',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    })
  };

  const handleLoadOptions = (inputValue, callback) => {
    const newOptions = sortedCountries.map((country) => ({
      label: (
        <div className="flex items-center justify-between gap-x-1">
          <span className="text-base flex flex-row items-center gap-x-1">
            <img src={country?.flag} alt={country?.name} className='w-4 h-3 mr-2'/> <span className="text-sm">{country.name}</span>
          </span>
          {country.value}
        </div>
      ),
      value: country.value,
      name: country.name
    }));
    callback(newOptions);
  };

  const handleFilterOption = (option, rawInput) => {
    const input = rawInput.toLowerCase();
    return option.data.name.toLowerCase().includes(input) || option.data.value.toLowerCase().includes(input);
  };

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions
      value={value ? { label: value, value: value } : null}
      onChange={onChange}
      loadOptions={handleLoadOptions}
      filterOption={handleFilterOption}
      isDisabled={false}
      isClearable={isClearable}
      isSearchable={isSearchable}
      name={name}
      id={id}
      menuPlacement={menuPlacement}
      styles={customStyles}
      placeholder={placeholder}
      className="w-44 text-sm"
    />
  );
};

export default CountryCodeDropdown;
