import {
  FETCH_CLAIM_REPORTS,
  FETCH_CURRENT_EMPLOYEES_REPORTS,
  FETCH_LEAVE_REPORTS,
  FETCH_MEDICAL_REPORTS,
  FETCH_NO_PAY_LEAVE_REPORTS,
  FETCH_PAST_EMPLOYEES_REPORTS,
  FETCH_TIMESHEET_REPORTS
} from '../actions/reportAction';
import { FETCH_USER_LOGOUT } from '../actions/userAction';

const initialState = {
  leaves: [],
  noPayLeaves: [],
  medicals: [],
  claims: [],
  timesheets: [],
  pastEmployees: [],
  currentEmployees: []
};

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEAVE_REPORTS:
      return {
        ...state,
        leaves: action.payload.leavesReport
      };
    case FETCH_NO_PAY_LEAVE_REPORTS:
      return {
        ...state,
        noPayLeaves: action.payload.noPayLeavesReport
      };
    case FETCH_MEDICAL_REPORTS:
      return {
        ...state,
        medicals: action.payload.medicalsReport
      };
    case FETCH_CLAIM_REPORTS:
      return {
        ...state,
        claims: action.payload.claimsReport
      };
    case FETCH_TIMESHEET_REPORTS:
      return {
        ...state,
        timesheets: action.payload.timesheetsReport
      };

    case FETCH_PAST_EMPLOYEES_REPORTS:
      return {
        ...state,
        pastEmployees: action.payload.pastEmployees
      };

    case FETCH_CURRENT_EMPLOYEES_REPORTS:
      return {
        ...state,
        currentEmployees: action.payload.currentEmployees
      };

    case FETCH_USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default reportsReducer;
