import { useDispatch, useSelector } from 'react-redux';
import SuperAdminLayout from '../superadminLayout';
import { useFormik } from 'formik';
import { createAdminSchema } from '../../../validations/validationSchemas';
import { useEffect, useMemo, useState } from 'react';
import SuperAdminDropdown from '../superadminDropdown';
import {
  addAdmin,
  fetchAllAdmins,
  fetchAllCompanies,
  getAllAdmins,
  getAllCompaniesByOrganization,
  getAllOrganization,
  setCurrentCompany,
  setCurrentOrganization
} from '../../../actions/superadminAction';
import { Eye, EyeSlash } from 'iconsax-react';
import empty from '../../../assets/images/empty.png';
import AdminList from './adminList';

const SuperAdminCreateAdmin = () => {
  const dispatch = useDispatch();

  const [isAdd, setAdd] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [verifyPasswordVisible, setVerifyPasswordVisible] = useState(false);

  const companies = useSelector((state) => state.superAdmin?.companies || []);
  const admins = useSelector((state) => state.superAdmin?.admins || []);
  const organizations = useSelector((state) => state.superAdmin?.organizations || []);
  const currentOrganization = useSelector((state) => state.superAdmin?.currentOrganization);
  const currentCompany = useSelector((state) => state.superAdmin?.currentCompany);

  console.log('currentOrganization: ', currentOrganization);
  console.log('currentCompany: ', currentCompany);
  console.log('companies: ', companies);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleVerifyPasswordVisibility = () => {
    setVerifyPasswordVisible(!verifyPasswordVisible);
  };

  const formik = useFormik({
    initialValues: {
      organizationId: '',
      companyId: '',
      fullName: '',
      email: '',
      password: '',
      verifyPassword: '',
      role: 'Admin'
    },
    validationSchema: createAdminSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(addAdmin(values, setAdd, formik.resetForm, currentCompany?.companyId));
    }
  });

  console.log('formik: ', formik.values);

  useEffect(() => {
    dispatch(setCurrentCompany({}));
    dispatch(getAllOrganization());
  }, [dispatch]);

  // const handleGetCompanies = (organizationId) => {
  //   console.log(organizationId);
  //   if (organizationId) {
  //     dispatch(getAllCompaniesByOrganization(organizationId));
  //   }
  // };

  useEffect(() => {
    if (currentOrganization?.organizationId) {
      formik?.setFieldValue('organizationId', currentOrganization?.organizationId);
    } else {
      formik.setFieldValue('organizationId', '');
    }
  }, [currentOrganization]);


  const companyOption = useMemo(() => {
    return (
      companies?.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      })) || []
    );
  }, [companies]);

  const organizationOption =
    organizations?.map((organization) => ({
      organizationId: organization?._id,
      label: organization?.organizationName,
      value: organization?.organizationName
    })) || [];

  const handleOrganizationChange = (option) => {
    console.log('option: ', option);
    if (option === null) {
      formik.setFieldValue('organizationId', '');
      formik.setFieldValue('companyId', '');
      dispatch(setCurrentOrganization({}));
      dispatch(setCurrentCompany({}));
      dispatch(fetchAllCompanies([]));
    } else {
      formik.setFieldValue('organizationId', option.organizationId);
      formik.setFieldTouched('organizationId', true, false);
      setAdd(false)
      formik.resetForm();
      dispatch(setCurrentOrganization(option));
      dispatch(setCurrentCompany({}));
      dispatch(getAllCompaniesByOrganization(option?.organizationId));
    }
  };

  const handleCompanyChange = (option) => {
    if (option === null) {
      formik.setFieldValue('companyId', '');
      dispatch(setCurrentCompany({}));
      dispatch(fetchAllAdmins([]));
    } else {
      formik.setFieldValue('companyId', option.companyId);
      formik.setFieldTouched('companyId', true, false);
      dispatch(setCurrentCompany(option));
      dispatch(getAllAdmins(option?.companyId));
    }
  };

  console.log('companyOption: ', companyOption)

  return (
    <SuperAdminLayout pageTitle={'Create New Admin'} page={'Super Admin'} subPage={'Create New Admin'}>
      <div className="w-full bg-white rounded-xl p-6 min-h-[80vh]">
        <div className="flex justify-between items-center">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row w-full items-center">
            <div className="w-1/3">
              <div className="mb-2.5 block text-black font-medium">Select Organization</div>
              <SuperAdminDropdown
                options={organizationOption}
                value={
                  currentOrganization?.organizationId
                    ? organizationOption.find((option) => option.organizationId === currentOrganization.organizationId)
                    : null // Ensures dropdown is empty if no organization is selected
                }
                onChange={handleOrganizationChange}
                placeholder="Select Organization"
                name="organizationId"
                id="organizationId"
              />
            </div>
            <div className="w-1/3">
              <div className="mb-2.5 block text-black font-medium text-sm">Select Company</div>
              <SuperAdminDropdown
                options={companyOption}
                // value={companyOption.find((option) => option.label === formik.values.companyId)}
                value={
                  currentCompany && Object.keys(currentCompany).length > 0
                    ? companyOption.find((option) => option.companyId === formik.values.companyId)
                    : null // Ensures dropdown is empty if no organization is selected
                }
                onChange={handleCompanyChange}
                placeholder="Select Company"
                name="companyId"
                id="companyId"
              />
            </div>
          </div>
          <button
            className={`${isAdd ? 'bg-red-600' : 'bg-orange-600'} px-5 py-3 w-40 text-white rounded-xl font-bold cursor-pointer disabled:bg-gray-500`}
            onClick={() => {
              if (isAdd) {
                formik.resetForm();
              } else {
                formik?.setFieldValue('organizationId', currentOrganization?.organizationId);
                formik?.setFieldValue('companyId', currentCompany?.companyId);
              }
              setAdd(!isAdd);
            }}
            disabled={!currentCompany?.companyId || !currentOrganization?.organizationId}
          >
            {isAdd ? 'Cancel' : '+ Add Admin'}
          </button>
        </div>
        {!currentOrganization?.organizationId && (
          <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
            <img src={empty} alt="empty data" />
            <div className="mt-5 text-purple1 font-bold text-xl">Select Organization</div>
          </div>
        )}
        {currentOrganization?.organizationId && !currentCompany?.companyId && (
          <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
            <img src={empty} alt="empty data" />
            <div className="mt-5 text-purple1 font-bold text-xl">Select Company</div>
          </div>
        )}
        {isAdd && (
          <form onSubmit={formik.handleSubmit} className="w-full">
            <div className="bg-white p-6 rounded-2xl shadow-lg mt-5 w-[90%]">
              <h4 className="text-2xl font-bold mb-5 text-black">Create Admin</h4>

              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
              <div className="w-full lg:w-1/3">
                  <label className="mb-2.5 block text-black font-medium text-sm">Full Name</label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="Enter Full Name"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight h-12 px-5 text-black disabled:text-gray2 outline-none transition focus:border-orange-600 active:border-orange-600 disabled:cursor-default disabled:bg-whiter"
                    onChange={formik.handleChange}
                    value={formik.values.fullName}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/3">
                  <label className="mb-2.5 block text-black font-medium text-sm">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight h-12 px-5 text-black disabled:text-gray2 outline-none transition focus:border-orange-600 active:border-orange-600 disabled:cursor-default disabled:bg-whiter"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500 text-sm">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/3">
                  <div className="mb-2.5 block text-black font-medium text-sm">Role</div>
                  <input
                    type="text"
                    id="role"
                    name="role"
                    placeholder="Enter Role"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight h-12 px-5 text-black disabled:text-gray2 outline-none transition focus:border-orange-600 active:border-orange-600 disabled:cursor-default disabled:bg-gray-300"
                    onChange={formik.handleChange}
                    value={formik.values.role}
                    disabled
                  />
                  {formik.touched.role && formik.errors.role ? (
                    <div className="text-red-500 text-sm">{formik.errors.role}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/3 relative">
                  <label className="mb-2.5 block text-black font-medium text-sm">Password</label>
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    id="password"
                    name="password"
                    placeholder="Enter password"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight h-12 px-5 text-black disabled:text-gray2 outline-none transition focus:border-orange-600 active:border-orange-600 disabled:cursor-default disabled:bg-whiter"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  <div
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-[52px] right-4 flex items-center cursor-pointer"
                  >
                    {passwordVisible ? <Eye size="24" color="gray" /> : <EyeSlash size="24" color="gray" />}
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red-500 text-sm">{formik.errors.password}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/3 relative">
                  <label className="mb-2.5 block text-black font-medium text-sm">Verify Password</label>
                  <input
                    type={verifyPasswordVisible ? 'text' : 'password'}
                    id="verifyPassword"
                    name="verifyPassword"
                    placeholder="Verify password"
                    className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight h-12 px-5 text-black disabled:text-gray2 outline-none transition focus:border-orange-600 active:border-orange-600 disabled:cursor-default disabled:bg-whiter"
                    onChange={formik.handleChange}
                    value={formik.values.verifyPassword}
                  />
                  <div
                    onClick={toggleVerifyPasswordVisibility}
                    className="absolute inset-y-[52px] right-4 flex items-center cursor-pointer"
                  >
                    {verifyPasswordVisible ? <Eye size="24" color="gray" /> : <EyeSlash size="24" color="gray" />}
                  </div>
                  {formik.touched.verifyPassword && formik.errors.verifyPassword ? (
                    <div className="text-red-500 text-sm">{formik.errors.verifyPassword}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/3 relative"></div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-orange-600 hover:bg-orange-600/90 text-white font-bold rounded-lg px-4 py-2"
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        )}
        {currentOrganization?.organizationId && currentCompany?.companyId && admins?.length > 0 ? (
          <div className="mt-10">
            <AdminList allAdmins={admins} />
          </div>
        ) : currentCompany?.companyId && (
          <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
            <img src={empty} alt="empty data" />
            <div className="mt-5 text-purple1 font-bold text-xl">No Admins for this Company</div>
          </div>
        )}
      </div>
    </SuperAdminLayout>
  );
};

export default SuperAdminCreateAdmin;
