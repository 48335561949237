import { useEffect, useState } from 'react';
import Layout from '../../../layout/layout';
import GeneratedEmployeePaymentDetails from './generatedEmployeePayementDetails';
import InitialGeneratePayroll from './initialGeneratePayroll';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentPayroll } from '../../../actions/payrollAction';
import CompanyDropdown from '../../../components/companyDropdown';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';

const GeneratePayroll = () => {
  const dispatch = useDispatch();

  const payroll = useSelector((state) => state?.payroll);
  const companies = useSelector((state) => state.company?.companies || []);
  const company = useSelector((state) => state.company?.company);

  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    if (company?._id) {
      dispatch(getCurrentPayroll(company._id));
    }
  }, [company]);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    if (selectedValue) {
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };

  console.log('company ', company);
  console.log('payroll?.currentPayroll ', payroll);

  return (
    <Layout pageTitle={'Generate Payroll'} page={'Payroll'} subPage={'Generate Payroll'}>
      <div className="w-full lg:w-1/2 mb-6">
        <label className="mb-2.5 block text-black font-bold">Select Company</label>
        {companyOptions.length > 0 && (
          <CompanyDropdown
            value={companyOptions.find((option) => option.value === company?.companyName)}
            onChange={handleCompanyChange}
            options={companyOptions}
            isClearable={false}
            placeholder="Select Company"
            name="company"
            id="company"
          />
        )}
      </div>
      <div className="min-h-[50vh]">
      {Object.keys(payroll?.currentPayroll || {}).length === 0 ?  (
          <InitialGeneratePayroll companyId={company._id} />
        ) : (
          <GeneratedEmployeePaymentDetails currentPayroll={payroll?.currentPayroll} />
        )}
      </div>
    </Layout>
  );
};

export default GeneratePayroll;
