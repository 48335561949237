import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  //const currentId = useSelector((state) => state.currentId.currentId); // Adjust according to your state structure
  const user = useSelector((state) => state?.employee?.user);
  const [authUser, setAuthUser] = useState(null);

  console.log("auth userId", user._id)
  
  useEffect(() => {
    if (user._id) {
      setAuthUser({ id: user._id });
    } else {
      setAuthUser(null);
    }
    console.log("Inside useEffect - authUser: ", { id: user._id }); // Log inside useEffect
  }, [user]);


  return (
    <AuthContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};