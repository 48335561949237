import { useState, useRef } from 'react';
import { payrollCutOffSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import { updatePublicHoliday } from '../../../actions/companyAction';
import { ToastBar } from '../../../components/toastbar';
import { DocumentUpload, MinusCirlce } from 'iconsax-react';
import ICAL from 'ical.js';

const EditPublicHoliday = ({ company, onClose }) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);

    const [publicHolidayFile, setPublicHolidayFile] = useState(null);
    const [eventDetails, setEventDetails] = useState([]);

    const handleUploadFile = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setPublicHolidayFile(file)

        const reader = new FileReader();

        if (file.type === 'text/calendar') {
            reader.readAsText(file);
        } else {
            ToastBar.error('Please upload a valid .ics file');
        }

        reader.onload = (event) => {
            const fileContent = event.target.result;
            const jcalData = ICAL.parse(fileContent);
            const comp = new ICAL.Component(jcalData);
            const events = comp.getAllSubcomponents('vevent');

            const extractedEvents = events.map(event => {
                const ph = event.getFirstPropertyValue('summary');
                const startDate = event.getFirstPropertyValue('dtstart').toJSDate();
                const endDate = event.getFirstPropertyValue('dtend').toJSDate();

                return {
                    ph,
                    startDate: startDate.toDateString(),
                    endDate: endDate.toDateString(),
                };
            });

            setEventDetails(extractedEvents);
        };
    };

    const handleClearFile = () => {
        setPublicHolidayFile(null);
        setEventDetails([]);
        fileInputRef.current.value = '';
    };

    const handleSubmit = () => {
        if (eventDetails.length === 0) {
            ToastBar.error('Please upload a valid .ics file');
            return;
        }

        // Dispatch updatePublicHoliday with the event details
        dispatch(updatePublicHoliday({ events: eventDetails, companyId: company?._id }, onClose));
    };

    const handleCancel = () => {
        handleClearFile();
        onClose();
    };

    return (
        <div className="rounded-sm border border-transparent w-full">
            <div className="p-6">
                {/* Display parsed event details */}
                <h4 className="text-base text-black font-bold mb-2">Edit Public Holidays:</h4>
                {eventDetails.length > 0 && (
                    <div className="mb-4">
                        
                        <ul className="list-disc pl-5">
                            {eventDetails.map((event, index) => (
                                <li key={index} className="mb-2">
                                    <div className="text-sm font-normal text-black">
                                        <strong>{event.ph}</strong>
                                        <br />
                                        <span>
                                            Start Date: {event.startDate}
                                        </span>
                                        <br />
                                        <span>
                                            End Date: {event.endDate}
                                        </span>
                                        <br />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="mb-5 flex flex-col gap-0 lg:gap-6 lg:flex-row items-center">
                    <div className="w-full lg:w-1/2 mt-5">
                        <label className="mb-2.5 block text-black font-bold">Upload .ics File</label>
                        <div className="relative flex items-center">
                            <input
                                ref={fileInputRef}
                                type="file"
                                accept=".ics"
                                onChange={handleUploadFile}
                                style={{ display: 'none' }}
                            />
                            <input
                                type="text"
                                placeholder="No file chosen"
                                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 pr-12 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                                value={publicHolidayFile ? publicHolidayFile.name : ''}
                                readOnly
                                onClick={() => fileInputRef.current.click()}
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                {publicHolidayFile ? (
                                    <MinusCirlce
                                        onClick={handleClearFile}
                                        color="#C84040"
                                        variant="Bold"
                                        size={20}
                                        className="cursor-pointer"
                                        title="Clear"
                                    />
                                ) : (
                                    <DocumentUpload
                                        onClick={() => fileInputRef.current.click()}
                                        color="#555555"
                                        variant="Bold"
                                        size={20}
                                        className="cursor-pointer"
                                        title="Upload file"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end w-full gap-x-2">
                    <button
                        type="button"
                        onClick={handleCancel}
                        className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditPublicHoliday;
