import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from '../../../components/DatePicker';
import TimePicker from '../../../components/timePicker';
import { addTimesheetValidationSchema } from '../../../validations/validationSchemas';
import { addTimesheet } from '../../../actions/timesheetAction';
import { calculateAmount, calculateDuration } from '../../../constant/functions';
import { ToastBar } from '../../../components/toastbar';

const AddTimesheets = ({ toggleFormVisibility, employee, companyId }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      date: Date.now(),
      startTime: { hours: '09', minutes: '00', period: 'AM' },
      endTime: { hours: '06', minutes: '00', period: 'PM' },
      hours: { hours: 8, minutes: 0 },
      hourlyRate: employee?.salaryDetails?.hourlyRate?.toFixed(2) || 0,
      timesheetAmount: "0.00"
    },
    validationSchema: addTimesheetValidationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log('values: ', values);
      if(employee?.approver?.approverId){
        dispatch(addTimesheet(employee?._id, companyId, values, toggleFormVisibility, resetForm));
      } else {
        ToastBar.warning('Approver Not Assigned.');
        return;
      }
    }
  });

  useEffect(() => {
    if (formik.values.startTime?.hours && formik.values.endTime?.hours) {
      const duration = calculateDuration(formik.values.startTime, formik.values.endTime);
      formik.setFieldValue('hours', duration);
    }
  }, [formik.values.startTime, formik.values.endTime]);

  useEffect(() => {
    const { hours, minutes } = formik.values.hours;
    const hourlyRate = formik.values.hourlyRate;
    const timesheetAmount = calculateAmount(hours, minutes, hourlyRate);

    formik.setFieldValue('timesheetAmount', timesheetAmount.toFixed(2));
  }, [formik.values.hours, formik.values.hourlyRate]);

  return (
    <div className="bg-white p-6 rounded-2xl shadow-lg mt-5">
      <h4 className="text-2xl font-bold mb-5 text-black">Add Timesheet</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-7 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Date</label>
            <DatePicker
              value={formik.values.date}
              onChange={(date) => {
                formik.setFieldValue('date', date);
              }}
            />
            {formik.touched.date && formik.errors.date ? (
              <div className="text-red-500 text-sm">{formik.errors.date}</div>
            ) : null}
          </div>

          <div className="w-full lg:w-1/2"></div>
        </div>
        <div className="mb-5 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Starting Time</label>
            <TimePicker
              time={formik.values.startTime || {}}
              onTimeChange={(hours, minutes, period) => {
                formik.setFieldValue('startTime', { hours, minutes, period });
              }}
            />
            {formik.touched.startTime?.hours && formik.errors.startTime?.hours ? (
              <div className="text-red-500 text-sm">{formik.errors.startTime.hours}</div>
            ) : null}

            {formik.touched.startTime?.minutes && formik.errors.startTime?.minutes ? (
              <div className="text-red-500 text-sm">{formik.errors.startTime.minutes}</div>
            ) : null}

            {formik.touched.startTime?.period && formik.errors.startTime?.period ? (
              <div className="text-red-500 text-sm">{formik.errors.startTime.period}</div>
            ) : null}
          </div>
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">End Time</label>
            <TimePicker
              time={formik.values.endTime || {}}
              onTimeChange={(hours, minutes, period) => {
                formik.setFieldValue('endTime', { hours, minutes, period });
              }}
            />
            {formik.touched.endTime?.hours && formik.errors.endTime?.hours ? (
              <div className="text-red-500 text-sm">{formik.errors.endTime.hours}</div>
            ) : null}

            {formik.touched.endTime?.minutes && formik.errors.endTime?.minutes ? (
              <div className="text-red-500 text-sm">{formik.errors.endTime.minutes}</div>
            ) : null}

            {formik.touched.endTime?.period && formik.errors.endTime?.period ? (
              <div className="text-red-500 text-sm">{formik.errors.endTime.period}</div>
            ) : null}
          </div>
        </div>
        <div className="mb-5 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Number of Hours</label>
            <input
              type="text"
              placeholder="Hours"
              className="w-full rounded-xl text-sm border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
              name="hours"
              onChange={formik.handleChange}
              value={`${formik.values.hours?.hours} hours ${formik.values.hours?.minutes} minutes`}
              disabled
            />
            {formik.touched.hours?.hours && formik.errors.hours?.hours ? (
              <div className="text-red-500 text-sm">{formik.errors.hours?.hours}</div>
            ) : null}
            {formik.touched.hours?.minutes && formik.errors.hours?.minutes ? (
              <div className="text-red-500 text-sm">{formik.errors.hours?.minutes}</div>
            ) : null}
          </div>
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Hourly Rate ({employee?.salaryDetails?.currency})</label>
            <input
              type="number"
              placeholder="Hourly Rate"
              className="w-full rounded-xl text-sm border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
              name="hourlyRate"
              onChange={formik.handleChange}
              value={formik.values.hourlyRate}
              // disabled={!employee?.salaryDetails?.ot || !employee?.salaryDetails?.hourlyRate}
            />
            {formik.touched.hourlyRate && formik.errors.hourlyRate ? (
              <div className="text-red-500 text-sm">{formik.errors.hourlyRate}</div>
            ) : null}
          </div>
        </div>
        <div className="mb-5 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Amount ({employee?.salaryDetails?.currency})</label>
            <input
              type="number"
              placeholder="Amount"
              className="w-full rounded-xl text-sm border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-grayMedium"
              name="timesheetAmount"
              onChange={formik.handleChange}
              value={formik.values.timesheetAmount}
              disabled
            />
            {formik.touched?.timesheetAmount && formik.errors?.timesheetAmount ? (
              <div className="text-red-500 text-sm">{formik.errors?.timesheetAmount}</div>
            ) : null}
          </div>
          <div className="w-full lg:w-1/2"></div>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-red1 hover:bg-red1/90 text-white font-semibold rounded-md px-4 py-2 mr-2"
            onClick={toggleFormVisibility}
          >
            Cancel
          </button>
          <button type="submit" className="bg-purple1 hover:bg-purple1/90 text-white font-bold rounded-lg px-4 py-2">
            Submit Timesheet
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTimesheets;
