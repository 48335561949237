import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/dropdown';
import { applyMedicalSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import { ToastBar } from '../../../components/toastbar';
import { editMedicalLeave } from '../../../actions/medicalAction';
import { DocumentUpload, MinusCirlce } from 'iconsax-react';

const EditMedicalLeave = ({ medical, singleEmployee, onCancel, employeeId, companyId, company }) => {
  console.log('edit medical: ', medical);
  const dispatch = useDispatch();

  const leaveDetails = singleEmployee.medicalLeaveDetails.medicals.find((item) => item.medicalType === medical.medicalType);
  console.log('LeaveDetails: ', leaveDetails)

  const fileInputRef = useRef(null);
  const [medicalFile, setMedicalFile] = useState(medical?.medicalFile || null);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [balanceDays, setBalanceDays] = useState(leaveDetails?.balanceDays);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [isInitialized, setInitialized] = useState(false);

  console.log('balanceDays: ', balanceDays)

  const formik = useFormik({
    initialValues: {
      medicalType: medical?.medicalType || '',
      fromDate: medical?.fromDate || '',
      toDate: medical?.toDate || '',
      medicalFile: medical?.medicalFile
    },
    validationSchema: applyMedicalSchema,
    onSubmit: (values) => {
      handleEditMedicalLeave(values);
    }
  });

  useEffect(() => {
    if(isInitialized  && medical?.status === 2){
      setBalanceDays(balanceDays + numberOfDays)
    }
  }, [singleEmployee, isInitialized])

  useEffect(() => {
    if (formik.values.fromDate && formik.values.toDate) {
      calculateNumberOfDays(formik.values.fromDate, formik.values.toDate);

      if (!isDateChanged) {
        setIsDateChanged(true);
      }

      if (!isInitialized) {
        setInitialized(true)
      }
    }
  }, [formik.values.fromDate, formik.values.toDate]);

  const calculateNumberOfDays = (fromDate, toDate) => {
    if (!fromDate || !toDate) return;

    let currentDate = new Date(fromDate);
    const endDate = new Date(toDate);
    let dayCount = 0;

    // Get the list of public holiday dates as an array of startDates
    const publicHolidayDates = company?.publicHolidays?.map(holiday => new Date(holiday.startDate).toDateString()) || [];

    // Loop through each date in the range
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();

      // Check if the current date is a weekend or a public holiday
      if (dayOfWeek !== 0 && dayOfWeek !== 6 && !publicHolidayDates.includes(currentDate.toDateString())) {
        dayCount++;
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (isDateChanged && dayCount > balanceDays) {
      ToastBar.warning('The number of days exceeds the balance days available.');
    }

    setNumberOfDays(dayCount);
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMedicalFile(file);
      formik.setFieldValue('medicalFile', file);
    }
  };

  const handleClearFile = () => {
    setMedicalFile(null);
    formik.setFieldValue('medicalFile', null);
    fileInputRef.current.value = '';
  };

  // const handleEditMedicalLeave = async (values) => {
  //   const leaveApplication = {
  //     medicalType: values.medicalType,
  //     medicalCode: medical.medicalCode,
  //     fromDate: values.fromDate,
  //     toDate: values.toDate,
  //     numberOfDays,
  //     status: 'Pending',
  //     _id: medical._id,
  //     medicalFile: medicalFile,
  //     companyId: companyId,
  //     employeeId: employeeId
  //   };

  //   console.log(leaveApplication, employeeId);

  //   const result = await dispatch(editMedicalLeave(leaveApplication));
  //   if (result.success) {
  //     onCancel(); // Close the edit form
  //   }
  // };

  const handleEditMedicalLeave = async (values) => {
    if (numberOfDays > balanceDays) {
      ToastBar.warning('The number of days exceeds the balance days available.');
      return;
    }

    let formData;

    if (medicalFile instanceof File) {
      formData = new FormData();
      formData.append('medicalType', values.medicalType);
      formData.append('medicalCode', medical.medicalCode);
      formData.append('_id', medical._id);
      formData.append('fromDate', values.fromDate);
      formData.append('toDate', values.toDate);
      formData.append('numberOfDays', numberOfDays);
      formData.append('status', medical.status);
      formData.append('companyId', companyId);
      formData.append('employeeId', employeeId);
      formData.append('file', medicalFile, `medicalFile_${employeeId}`);
    } else {
      formData = {
        medicalType: values.medicalType,
        medicalCode: medical.medicalCode,
        fromDate: values.fromDate,
        toDate: values.toDate,
        numberOfDays,
        status: medical.status,
        _id: medical._id,
        companyId,
        employeeId,
        medicalFile: medicalFile || "",  // Use the existing file URL
      };
    }

    const result = await dispatch(editMedicalLeave(formData));
    console.log(result);
    if (result.success) {
      onCancel()
    }
  };


  return (
    <div className="bg-white p-6 rounded-2xl shadow-lg">
      <h4 className="text-2xl font-bold mb-5 text-black">Edit Medical Leave</h4>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-5 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">Select Leave Type</label>
            <Dropdown
              options={[{ label: medical.medicalType, value: medical.medicalCode }]}
              value={{ label: formik.values.medicalType, value: medical.medicalCode }}
              onChange={(option) => {
                formik.setFieldValue('medicalType', option.label);
                formik.setFieldTouched('medicalType', true, false);
              }}
              placeholder="Select Leave Type"
              name="medicalType"
              id="medicalType"
            />
            {formik.touched.medicalType && formik.errors.medicalType ? (
              <div className="text-red-500 text-sm">{formik.errors.medicalType}</div>
            ) : null}
          </div>
          <div className="w-full lg:w-1/2"></div>
        </div>
        <div className="mb-7 flex flex-col gap-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">From Date</label>
            <DatePicker
              value={formik.values.fromDate}
              onChange={(date) => {
                formik.setFieldValue('fromDate', date);
                formik.setFieldTouched('fromDate', true, false);
              }}
            />
            {formik.touched.fromDate && formik.errors.fromDate ? (
              <div className="text-red-500 text-sm">{formik.errors.fromDate}</div>
            ) : null}
          </div>

          <div className="w-full lg:w-1/2">
            <label className="mb-2.5 block text-black font-bold">To Date</label>
            <DatePicker
              value={formik.values.toDate}
              onChange={(date) => {
                formik.setFieldValue('toDate', date);
                formik.setFieldTouched('toDate', true, false);
              }}
            />
            {formik.touched.toDate && formik.errors.toDate ? (
              <div className="text-red-500 text-sm">{formik.errors.toDate}</div>
            ) : null}
          </div>
        </div>
        <div className="mb-6 mt-6">
          <div className="block text-base text-black font-bold">
            Number of Days: <span className="font-normal">{numberOfDays}</span>
          </div>
        </div>
        <label className="mb-2.5 block text-black font-bold">Upload Document (Optional)</label>
        <div className="w-full lg:w-1/2 relative flex items-center">
          <input
            ref={fileInputRef}
            type="file"
            accept=".jpeg, .jpg, .png, .pdf"
            onChange={handleUploadFile}
            style={{ display: 'none' }}
          />
          <input
            type="text"
            placeholder="No file chosen"
            className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 pr-12 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
            value={medicalFile ? (medicalFile || medicalFile.name) : ''}
            readOnly
            onClick={() => fileInputRef.current.click()}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {medicalFile ? (
              <MinusCirlce
                onClick={handleClearFile}
                color="#C84040"
                variant="Bold"
                size={20}
                className="cursor-pointer"
                title="Clear"
              />
            ) : (
              <DocumentUpload
                onClick={() => fileInputRef.current.click()}
                color="#555555"
                variant="Bold"
                size={20}
                className="cursor-pointer"
                title="Upload file"
              />
            )}
          </div>
        </div>
        {formik.touched.claimFile && formik.errors.claimFile ? (
          <div className="text-red-500 text-sm">{formik.errors.claimFile}</div>
        ) : null}

        <div className="flex justify-end">
          <button
            type="button"
            className="bg-red1 hover:bg-red1/90 text-white font-semibold rounded-md px-4 py-2 mr-2"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button type="submit" className="bg-purple1 hover:bg-purple1/90 text-white font-bold rounded-lg px-4 py-2">
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditMedicalLeave;
