import { useFormik } from 'formik';
import { employeeAllowanceSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import { updateAllowance } from '../../../actions/allowanceAction';

const EditAllowance = ({ allowance, allowanceOptions, onClose, employee }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      _id: allowance?._id,
      allowanceId: allowance?.allowanceId || '',
      allowanceType: allowance?.allowanceType || '',
      allowanceCode: allowance?.allowanceCode || '',
      allowanceAmount: allowance?.allowanceAmount || '',
      allowanceFrequency: allowance?.allowanceFrequency || 'Monthly',
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      status: allowance?.status
    },
    validationSchema: employeeAllowanceSchema,
    onSubmit: async (values) => {
      console.log('update value: ', values);
      dispatch(updateAllowance(values, onClose));
    }
  });

  const handleFloatChange = (value, fieldName) => {
    const formattedValue = parseFloat(value).toFixed(2);
    formik.setFieldValue(fieldName, formattedValue);
  };

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Allowance Type</label>
              <Dropdown
                value={allowanceOptions.find((option) => option.allowanceType === formik.values.allowanceType)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    formik.setValues({
                      ...formik.values,
                      allowanceType: selectedValue.value,
                      allowanceCode: selectedValue.allowanceCode,
                      allowanceId: selectedValue.allowanceId
                    });
                  }
                }}
                options={allowanceOptions}
                isClearable={false}
                placeholder="Select Allowance"
                name="allowanceType"
                id="allowanceType"
                isDisabled
              />
              {formik.touched.allowanceType && formik.errors.allowanceType ? (
                <div className="text-red-500 text-sm">{formik.errors.allowanceType}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Amount ({employee?.salaryDetails?.currency})</label>
              <input
                type="number"
                placeholder="Amount"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="allowanceAmount"
                onChange={formik.handleChange}
                onBlur={(e) => handleFloatChange(e.target.value, 'allowanceAmount')}
                value={formik.values.allowanceAmount}
              />
              {formik.touched.allowanceAmount && formik.errors.allowanceAmount ? (
                <div className="text-red-500 text-sm">{formik.errors.allowanceAmount}</div>
              ) : null}
            </div>
          </div>
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Allowance Frequency</label>
              {/* <Dropdown
                value={frequencyOptions.find((option) => option.value === formik.values.frequency)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) formik.setFieldValue('frequency', selectedValue.value);
                }}
                options={frequencyOptions}
                isClearable={false}
                placeholder="Select Frequency"
                name="frequency"
                id="frequency"
                menuPlacement="top"
              /> */}
              <input
                placeholder="Frequency"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="allowanceFrequency"
                onChange={formik.handleChange}
                value={formik.values.allowanceFrequency}
                disabled={true}
              />
              {formik.touched.allowanceFrequency && formik.errors.allowanceFrequency ? (
                <div className="text-red-500 text-sm">{formik.errors.allowanceFrequency}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2"></div>
          </div>
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditAllowance;
