import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import empty from '../../../assets/images/empty.png';
import { useNavigate } from 'react-router-dom';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import { DirectSend, Eye, Clock, TickCircle, FolderCross } from 'iconsax-react';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import PayrollTableHeader from './payrollTableHeader';
import PaginationFooter from '../../../components/paginationFooter';
import PaginationHeader from '../../../components/paginationHeader';
import DefaultPic from '../../../assets/images/Default_profilepic.png';
import {
  deletePayroll,
  submitApproval,
  approvePayroll,
  confirmPayroll,
  rejectPayroll
} from '../../../actions/payrollAction';
import * as XLSX from 'xlsx';
import { ToastBar } from '../../../components/toastbar';
import { format } from 'date-fns';
import DeleteConfirmationModal from '../../../components/deleteConfirmationModal';

const GeneratedEmployeePaymentDetails = ({ currentPayroll }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state?.employee?.user);
  const companies = useSelector((state) => state.company?.companies || []);
  const company = useSelector((state) => state.company?.company);

  const [sortConfig, setSortConfig] = useState({
    key: 'employee.fullName',
    direction: 'ascending'
  });
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);

  console.log('user', user);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    if (selectedValue) {
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };

  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedEmployees([]);
    } else {
      setSelectedEmployees(sortedEmployees);
    }
    setSelectAll(!selectAll);
  };

  const getFieldValue = (obj, path) => {
    switch (path) {
      case 'employee.salary[0].netSalary':
        return obj?.salary?.[0]?.netSalary || 0;
      case 'employee.cpf[0].CPFContribution':
        return obj?.cpf?.[0]?.CPFContribution || 0;
      case 'employee.allowances[0].totalAllowance':
        return obj?.allowances?.[0]?.totalAllowance || 0;
      case 'employee.claims[0].totalClaimAmount':
        return obj?.claims?.[0]?.totalClaimAmount || 0;
      case 'employee.deductions[0].totalDeductionAmount':
        return obj?.deductions?.[0]?.totalDeductionAmount || 0;
      case 'employee.bonuses[0].totalBonusAmount':
        return obj?.bonuses?.[0]?.totalBonusAmount || 0;
      case 'employee.noPayLeaves[0].totalNoPayLeaveAmount':
        return obj?.noPayLeaves?.[0]?.totalNoPayLeaveAmount || 0;
      default:
        return obj?.employee?.fullName?.toLowerCase() || '';
    }
  };

  const sortedEmployees = useMemo(() => {
    if (!currentPayroll?.payrollDetails?.length) {
      return [];
    }

    let sortableGeneratedEmployees = [...currentPayroll?.payrollDetails];

    sortableGeneratedEmployees.sort((a, b) => {
      const fieldA = getFieldValue(a, sortConfig.key);
      const fieldB = getFieldValue(b, sortConfig.key);

      if (typeof fieldA === 'number' && typeof fieldB === 'number') {
        return sortConfig.direction === 'ascending' ? fieldA - fieldB : fieldB - fieldA;
      } else {
        return sortConfig.direction === 'ascending' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      }
    });

    return sortableGeneratedEmployees;
  }, [currentPayroll, sortConfig]);

  console.log('sorted employee: ', sortedEmployees);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const handleCheckboxChange = (employee) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(employee)
        ? prevSelected.filter((selected) => selected !== employee)
        : [...prevSelected, employee]
    );
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedEmployees.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedEmployees.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleSubmitApproval = (currentPayrollId) => {
    dispatch(submitApproval(currentPayrollId, company?._id));
  };

  const handleApprovePayroll = (currentPayrollId) => {
    dispatch(approvePayroll(currentPayrollId, company?._id));
  };

  const handleConfirmPayroll = (currentPayrollId, payRollCutOffDate) => {
    dispatch(confirmPayroll(currentPayrollId, payRollCutOffDate, company?._id));
  };

  const handleRejectPayroll = (currentPayrollId) => {
    dispatch(rejectPayroll(currentPayrollId, company?._id));
  };

  const handleDeletePayroll = () => {
    dispatch(deletePayroll(currentPayroll, setIsConfirmationModalOpen));
  };

  const downloadExcel = () => {
    try {
      const formattedUsers = currentPayroll?.payrollDetails?.map((employee) => {
        const formatCurrency = (amount, currencySymbol = employee?.salary[0]?.currency) => {
          if (amount == null) return `${currencySymbol} 0.00`;
          return `${currencySymbol} ${amount.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        };
        console.log('employee: ', employee);
        return {
          'Full Name': employee?.employee?.fullName || '',
          Designation: employee?.employee?.employmentDetails?.designationName || '',
          Department: employee?.employee?.employmentDetails?.departmentName || '',
          'Basic Salary': formatCurrency(employee?.salary[0]?.basicSalary || 0),
          'Employee CPF Percentage (%)': employee?.salary[0]?.employeeCPFPercentage || 0,
          'Employer CPF Percentage (%)': employee?.salary[0]?.employerCPFPercentage || 0,
          'Employee CPF': formatCurrency(employee?.salary[0]?.employeeCPF || 0),
          'Employer CPF': formatCurrency(employee?.salary[0]?.employerCPF || 0),
          'CPF Total Employee': formatCurrency(employee?.cpf[0]?.employeeCPF || 0),
          'CPF Total Employer': formatCurrency(employee?.cpf[0]?.employerCPF || 0),
          'CPF Contribution': formatCurrency(employee?.cpf[0]?.CPFContribution || 0),
          'Net Salary': formatCurrency(employee?.salary[0]?.netSalary || 0),
          'Total Deductions': formatCurrency(employee?.deductions[0]?.totalDeductionAmount || 0),
          'Total Allowances': formatCurrency(employee?.allowances[0]?.totalAllowance || 0),
          'Net Allowances': formatCurrency(employee?.allowances[0]?.netAllowanceAmount || 0),
          'Total Claims': formatCurrency(employee?.bonuses[0]?.totalClaimAmount || 0),
          'Total Bonuses': formatCurrency(employee?.bonuses[0]?.totalBonusAmount || 0),
          'Net Bonuses': formatCurrency(employee?.bonuses[0]?.netBonusAmount || 0),
          'Total No Pay Leaves': formatCurrency(employee?.noPayLeaves[0]?.totalNoPayLeaveAmount || 0),
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(formattedUsers);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Payroll');
      // Adjust column width
      const maxLength = {};
      formattedUsers.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          maxLength[key] = Math.max(maxLength[key] || 0, length);
        });
      });
      
      const colWidths = Object.entries(maxLength).map(([key, length]) => {
        if (key === 'Employee CPF Percentage (%)') return { wch: 25 };
        else if (key === 'Employer CPF Percentage (%)') return { wch: 25 };
        else if (key === 'Total No Pay Leaves') return { wch: 20 };
        return { wch: length + 5 };
      });      
      worksheet['!cols'] = colWidths;
      const filename = `${currentPayroll?.month}-${currentPayroll?.year}_generated_payroll_employees.xlsx`;
      XLSX.writeFile(workbook, filename);
      ToastBar.success('Excel Download Successful');
    } catch (error) {
      console.log(error);
      ToastBar.error('Error Downloading Excel');
    }
  };

  console.log('currentPayroll: ', currentPayroll)

  return (
    <div className="h-full">
      <PayrollTableHeader currentPayroll={currentPayroll} />
      <div className="bg-white min-h-full p-6 flex flex-col justify-between rounded-2xl">
        <div className="flex flex-col h-full">
          <div className="w-full flex justify-between mb-4 items-center">
            <div className="text-2xl font-bold text-black">Employee Payment Details</div>
            <div className="flex gap-x-3 items-center">
              <a onClick={downloadExcel} className="cursor-pointer" title="Download Payroll Employees">
                <FontAwesomeIcon icon={faFileExcel} size="2x" color="#3CA455" />
              </a>
              {currentPayroll?.status === 2 && (
                <button
                  type="button"
                  onClick={() => handleSubmitApproval(currentPayroll._id)}
                  className="bg-purple1 hover:bg-purple1/90 text-white font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3"
                >
                  <DirectSend color="#ffffff" variant="Outline" size={25} />
                  <span>Submit for Approval</span>
                </button>
              )}
              {/* {currentPayroll?.status === 5 && (
                <button
                  type="button"
                  onClick={() => handleSubmitApproval(currentPayroll?._id)}
                  className="bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3"
                >
                  <Clock color="#ffffff" variant="Outline" size={25} />
                  <span>Pending Approval</span>
                </button>
              )} */}
              {currentPayroll?.status === 5 && (
                <button
                  type="button"
                  onClick={() => handleRejectPayroll(currentPayroll?._id)}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3"
                >
                  <FolderCross color="#ffffff" variant="Outline" size={25} />
                  <span>Reject Payroll</span>
                </button>
              )}
              {currentPayroll?.status === 5 && (
                <button
                  type="button"
                  onClick={() => handleApprovePayroll(currentPayroll?._id)}
                  className= "bg-green1 hover:bg-green1/90 text-white font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3"
                >
                  <TickCircle color="#ffffff" variant="Outline" size={25} />
                  <span>Approve Payroll</span>
                </button>
              )}
              {currentPayroll?.status === 6 && (
                <button
                  type="button"
                  onClick={() => handleConfirmPayroll(currentPayroll._id, currentPayroll.payRollCutOffDate)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-xl px-6 h-12 text-right flex items-center gap-x-3"
                >
                  <TickCircle color="#ffffff" variant="Outline" size={25} />
                  <span>Confirm Payroll</span>
                </button>
              )}
            </div>
          </div>

          {currentEntries?.length > 0 ? (
            <div>
              <PaginationHeader
                entriesPerPage={entriesPerPage}
                handleEntriesPerPageChange={handleEntriesPerPageChange}
              />
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-10 rounded-s-xl">
                      <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                    </th>
                    <th className="h-20 text-center w-12">Photo</th>
                    <th onClick={() => handleSort('employee.fullName')} className="cursor-pointer">
                      Full Name
                      <FontAwesomeIcon icon={getSortIcon('employee.fullName')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('employee.salary[0].netSalary')} className="cursor-pointer">
                      Net Salary
                      <FontAwesomeIcon icon={getSortIcon('employee.salary[0].netSalary')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('employee.cpf[0].CPFContribution')} className="cursor-pointer">
                      CPF Contribution
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.cpf[0].CPFContribution')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th onClick={() => handleSort('employee.allowances[0].totalAllowance')} className="cursor-pointer">
                      Total Allowance
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.allowances[0].totalAllowance')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th onClick={() => handleSort('employee.claims[0].totalClaimAmount')} className="cursor-pointer">
                      Total Claims
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.claims[0].totalClaimAmount')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th onClick={() => handleSort('employee.bonuses[0].totalBonusAmount')} className="cursor-pointer">
                      Total Bonuses
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.bonuses[0].totalBonusAmount')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th
                      onClick={() => handleSort('employee.deductions[0].totalDeductionAmount')}
                      className="cursor-pointer"
                    >
                      Total Deductions
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.deductions[0].totalDeductionAmount')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th
                      onClick={() => handleSort('employee.noPayLeaves[0].totalNoPayLeaveAmount')}
                      className="cursor-pointer"
                    >
                      Total No Pay Leaves
                      <FontAwesomeIcon
                        icon={getSortIcon('employee.noPayLeaves[0].totalNoPayLeaveAmount')}
                        size="sm"
                        className="ml-1"
                      />
                    </th>
                    <th className="cursor-pointer px-4 rounded-e-xl w-24">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((employee, index) => {
                    return (
                      <tr className={`h-20 text-sm text-black`} key={index}>
                        <td className="h-20 text-center">
                          <input
                            type="checkbox"
                            checked={selectedEmployees.includes(employee)}
                            onChange={() => handleCheckboxChange(employee)}
                          />
                        </td>
                        <td className="h-20 w-12 text-center">
                          <div className="flex justify-center items-center">
                            <img
                              src={employee?.employee?.avatar ? employee?.employee?.avatar : DefaultPic}
                              alt="profile pic"
                              className="w-8 h-8 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td title={employee?.employee?.fullName} className="h-20 text-sm text-black text-center">
                          <span>
                            {/* {employee?.employee?.fullName?.slice(0, 16)}
                            {employee?.employee?.fullName?.length > 16 ? '...' : ''} */}
                            {employee?.employee?.fullName}
                          </span>
                        </td>
                        <td className="h-20 text-center text-purple1 font-bold">
                          {new Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(employee?.salary?.[0]?.netSalary)}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.cpf[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.cpf[0]?.CPFContribution)
                            : 'N/A'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.allowances[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.allowances[0]?.totalAllowanceAmount)
                            : '0.00'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.claims[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.claims[0]?.totalClaimAmount)
                            : '0.00'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.bonuses[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.bonuses[0]?.totalBonusAmount)
                            : '0.00'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.deductions[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.deductions[0]?.totalDeductionAmount)
                            : '0.00'}
                        </td>
                        <td className="h-20 text-center">
                          {employee?.noPayLeaves[0]
                            ? new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(employee?.noPayLeaves[0]?.totalNoPayLeaveAmount)
                            : '0.00'}
                        </td>
                        <td className="h-20 w-24 flex justify-center items-center">
                          <button
                            className="hover:bg-blue-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
                            onClick={() =>
                              navigate(`/payroll/edit-payroll-employee`, { state: { employee, currentPayroll } })
                            }
                            title="View"
                          >
                            <Eye color="#000000" variant="Bold" size={20} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Generated Employees</div>
            </div>
          )}
        </div>
        {currentEntries.length > 0 && (
          <PaginationFooter
            sortedList={sortedEmployees}
            pageNumbers={pageNumbers}
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            indexOfFirstEntry={indexOfFirstEntry}
            indexOfLastEntry={indexOfLastEntry}
          />
        )}
      </div>
      {currentPayroll?.status === 2 && (
        <div className="w-full py-4 flex justify-end">
          <button
            type="button"
            onClick={() => setIsConfirmationModalOpen(true)}
            className="flex w-48 justify-center items-center rounded-xl bg-red1 h-12 text-sm font-bold text-white hover:bg-red1/90"
          >
            Delete Payroll
          </button>
        </div>
      )}
      <DeleteConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        handleDelete={() => handleDeletePayroll()}
        title={'payroll'}
      />
    </div>
  );
};

export default GeneratedEmployeePaymentDetails;
