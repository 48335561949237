import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Dropdown from '../../../components/dropdown';
import {
  employmentStatusOptions,
  employmentType,
  roleOptions,
  workingHourTypeOptions
} from '../../../constant/dropdownConstOptions';
import DatePicker from '../../../components/DatePicker';
import { employmentDetailsSchema } from '../../../validations/validationSchemas';
import { editEmploymentDetails } from '../../../actions/employeeAction';
import { Edit2, Eye, EyeSlash } from 'iconsax-react';
import ApproveEmploymentDetailsModal from './approveEmploymentDetailsModal';

const EmploymentDetails = ({ employee, company }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // const branchOptions =
  //   company?.branches?.map((branch) => ({
  //     label: `${branch?.branchName} - ${branch?.branchCode}`,
  //     value: branch?.branchName,
  //     branchName: branch?.branchName,
  //     branchCode: branch?.branchCode,
  //     branchId: branch?._id
  //   })) || [];

  console.log(employee?.company?._id);

  const departmentOptions =
    company?.departments?.map((department) => ({
      label: `${department?.departmentName} - ${department?.departmentCode}`,
      value: department?.departmentName,
      departmentName: department?.departmentName,
      departmentCode: department?.departmentCode,
      departmentId: department?._id
    })) || [];

  const designationOptions =
    company?.designations?.map((designation) => ({
      label: `${designation?.designationName} - ${designation?.designationCode}`,
      value: designation?.designationName,
      designationName: designation?.designationName,
      designationCode: designation?.designationCode,
      designationId: designation?._id
    })) || [];

  const formik = useFormik({
    initialValues: {
      _id: employee?._id,
      companyId: employee?.company?._id,
      // branchName: employee?.employmentDetails?.branchName || '',
      // branchCode: employee?.employmentDetails?.branchCode || '',
      //branchId: employee?.employmentDetails?.branchId || '',
      departmentCode: employee?.employmentDetails?.departmentCode || '',
      departmentName: employee?.employmentDetails?.departmentName || '',
      departmentId: employee?.employmentDetails?.departmentId || '',
      designationCode: employee?.employmentDetails?.designationCode || '',
      designationName: employee?.employmentDetails?.designationName || '',
      designationId: employee?.employmentDetails?.designationId || '',
      empType: employee?.employmentDetails?.empType || '',
      empStatus: employee?.employmentDetails?.empStatus || '',
      role: employee?.role || '',
      startDate: employee?.employmentDetails?.startDate || '',
      endDate: employee?.employmentDetails?.endDate || ''
    },
    validationSchema: employmentDetailsSchema,
    onSubmit: async (values) => {
      // console.log('edit employment: ', values);
      // dispatch(editEmploymentDetails(values, setEdit));
      setShowModal(true);
    }
  });

  const handleConfirm = () => {
    dispatch(editEmploymentDetails({ ...formik.values, password }, setEdit));
    setShowModal(false); // Close modal after confirm
    setPassword('');
  };

  // console.log('branchOptions: ', branchOptions);
  console.log('departmentOptions: ', departmentOptions);
  console.log('designationOptions: ', designationOptions);

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="relative flex flex-col items-start">
          <div className="font-bold text-black text-2xl">Employment Details</div>
        </div>
        <div className="p-6 relative flex flex-col gap-x-6 lg:flex-row">
          <div className="w-full lg:w-1/2">
            {/* <div className="text-sm text-black/40 font-bold">Branch Name</div>
            <div className="text-base text-black font-normal mb-3">
              {employee?.employmentDetails?.branchName} -{employee?.employmentDetails?.branchCode}
            </div> */}
            <div className="text-sm text-black/40 font-bold">Department Name</div>
            {employee?.employmentDetails?.departmentName ? (
              <div className="text-base text-black font-normal mb-3">
                {employee?.employmentDetails?.departmentName} - {employee?.employmentDetails?.departmentCode}
              </div>
            ) : (
              <div className="mb-3" />
            )}
            <div className="text-sm text-black/40 font-bold">Designation</div>
            <div className="text-base text-black font-normal mb-3">{employee?.employmentDetails?.designationName}</div>
            <div className="text-sm text-black/40 font-bold">Role</div>
            <div className="text-base text-black font-normal mb-3">{employee?.role}</div>
          </div>

          <div className="w-full lg:w-1/2">
            <div className="text-sm text-black/40 font-bold">Employment Type</div>
            {employee?.employmentDetails?.empStatus ? (
              <div className="text-base text-black font-normal mb-3">
                {employee?.employmentDetails?.empType} ( {employee?.employmentDetails?.empStatus} )
              </div>
            ) : (
              <div className="mb-3" />
            )}
            <div className="text-sm text-black/40 font-bold">Start Date</div>
            <div className="text-base text-black font-normal mb-3">
              {employee?.employmentDetails?.startDate
                ? new Date(parseInt(employee?.employmentDetails?.startDate)).toLocaleDateString('en-GB')
                : ''}{' '}
            </div>
            <div className="text-sm text-black/40 font-bold">End Date</div>
            <div className="text-base text-black font-normal mb-3">
              {employee?.employmentDetails?.startDate
                ? employee?.employmentDetails?.endDate
                  ? new Date(parseInt(employee?.employmentDetails?.endDate)).toLocaleDateString('en-GB')
                  : 'Current'
                : ''}
            </div>
          </div>
          {!edit && (
            <button
              onClick={() => setEdit(true)}
              type="button"
              className="absolute right-4 top-6 hover:bg-green-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
            >
              <Edit2 color="#16a34a" variant="Bold" size={20} />
            </button>
          )}
        </div>
      </div>
      {edit && (
        <div className="rounded-xl bg-white shadow-lg w-full">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-6">
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                {/* <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Branch</label>
                  <Dropdown
                    value={branchOptions.find((option) => option.branchName === formik.values.branchName)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) {
                        formik.setValues({
                          ...formik.values,
                          branchName: selectedValue.value,
                          branchCode: selectedValue.branchCode,
                          branchId: selectedValue.branchId
                        });
                      }
                    }}
                    options={branchOptions}
                    isClearable={false}
                    placeholder="Select Branch"
                    name="branchName"
                    id="branchName"
                  />
                  {formik.touched.branchName && formik.errors.branchName ? (
                    <div className="text-red-500 text-sm">{formik.errors.branchName}</div>
                  ) : null}
                </div> */}
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Department</label>
                  <Dropdown
                    value={departmentOptions.find((option) => option.departmentName === formik.values.departmentName)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) {
                        formik.setValues({
                          ...formik.values,
                          departmentName: selectedValue.value,
                          departmentCode: selectedValue.departmentCode,
                          departmentId: selectedValue.departmentId
                        });
                      }
                    }}
                    options={departmentOptions}
                    isClearable={false}
                    placeholder="Select Department"
                    name="departmentName"
                    id="department"
                  />
                  {formik.touched.departmentName && formik.errors.departmentName ? (
                    <div className="text-red-500 text-sm">{formik.errors.departmentName}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Designation</label>
                  <Dropdown
                    value={designationOptions.find((option) => option.value === formik.values.designationName)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) {
                        formik.setValues({
                          ...formik.values,
                          designationName: selectedValue.value,
                          designationCode: selectedValue.designationCode,
                          designationId: selectedValue.designationId
                        });
                      }
                    }}
                    options={designationOptions}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select Designation"
                    name="designationName"
                    id="designationName"
                  />
                  {formik.touched.designationName && formik.errors.designationName ? (
                    <div className="text-red-500 text-sm">{formik.errors.designationName}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Employment Type</label>
                  <Dropdown
                    value={employmentType.find((option) => option.value === formik.values.empType)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('empType', selectedValue.value);
                    }}
                    options={employmentType}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select Employment Type"
                    name="empType"
                    id="empType"
                  />
                  {formik.touched.empType && formik.errors.empType ? (
                    <div className="text-red-500 text-sm">{formik.errors.empType}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Employment Status</label>
                  <Dropdown
                    value={employmentStatusOptions.find((option) => option.value === formik.values.empStatus)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('empStatus', selectedValue.value);
                    }}
                    options={employmentStatusOptions}
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select Employment Status"
                    name="empStatus"
                    id="empStatus"
                  />
                  {formik.touched.empStatus && formik.errors.empStatus ? (
                    <div className="text-red-500 text-sm">{formik.errors.empStatus}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Role</label>
                  <Dropdown
                    value={roleOptions.find((option) => option.value === formik.values.role)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) formik.setFieldValue('role', selectedValue.value);
                    }}
                    options={roleOptions}
                    isClearable={false}
                    placeholder="Select Role"
                    name="role"
                    id="role"
                  />
                  {formik.touched.role && formik.errors.role ? (
                    <div className="text-red-500 text-sm">{formik.errors.role}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Start Date</label>
                  <DatePicker
                    value={formik.values.startDate}
                    onChange={(date) => formik.setFieldValue('startDate', date)}
                  />
                  {formik.touched.startDate && formik.errors.startDate ? (
                    <div className="text-red-500 text-sm">{formik.errors.startDate}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">End Date</label>
                  <DatePicker
                    value={formik.values.endDate}
                    onChange={(date) => formik.setFieldValue('endDate', date)}
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div className="text-red-500 text-sm">{formik.errors.endDate}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end w-full gap-x-2">
                <button
                  type="button"
                  onClick={() => setEdit(false)}
                  className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {showModal && (
        <ApproveEmploymentDetailsModal onCancel={() => setShowModal(false)} onConfirm={handleConfirm}>
          {/* Pass the password input inside the modal */}
          <div className="relative">
            <label className="mb-1 block text-black font-medium text-sm">Password</label>
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Enter password"
              className="w-full rounded-2xl border-[1px] placeholder:text-white/70 border-grayDark bg-transparent py-3 px-5 text-white lg:text-black outline-none transition lg:focus:border-primary lg:active:border-primary disabled:cursor-default disabled:bg-whiter"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute inset-y-12 right-4 flex items-center cursor-pointer"
            >
              {passwordVisible ? <Eye size="24" color="gray" /> : <EyeSlash size="24" color="gray" />}
            </div>
          </div>
        </ApproveEmploymentDetailsModal>
      )}
    </div>
  );
};

export default EmploymentDetails;
