import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { editApprovingDetails, getApprovers } from '../../../actions/employeeAction';
import { approvingDetailsSchema } from '../../../validations/validationSchemas';
import { Edit2 } from 'iconsax-react';
import Dropdown from '../../../components/dropdown';

const ApprovingDetails = ({ employee }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);

  const approvers = useSelector((state) => state?.employee?.approvers) || [];

  console.log(approvers)

  useEffect(() => {
    dispatch(getApprovers(employee?.employmentDetails?.departmentCode, employee?.company?._id, employee?._id, employee?.role));
  }, [dispatch]);

  const approverOptions =
    approvers?.map((approver) => ({
      label: approver?.fullName,
      value: approver?._id,
      departmentName: approver?.employmentDetails?.departmentName,
      departmentCode: approver?.employmentDetails?.departmentCode,
      designationName: approver?.employmentDetails?.designationName,
      designationCode: approver?.employmentDetails?.designationCode,
      role: approver?.role
    })) || [];

  const formik = useFormik({
    initialValues: {
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      fullName: '',
      approverId: '',
      departmentName: '',
      departmentCode: '',
      designationName: '',
      designationCode: '',
      role: '',
    },
    validationSchema: approvingDetailsSchema,
    onSubmit: async (values) => {
      console.log('edit approver: ', values);
      dispatch(editApprovingDetails(values, setEdit));
    }
  });

  return (
    <div className="bg-white w-full rounded-xl p-6">
      <div className="w-full">
        <div className="relative flex flex-col items-start">
          <div className="font-bold text-black text-2xl">Approver Details</div>
        </div>
        <div className="p-6 relative">
          <div className="w-full lg:w-1/2">
            <div className="text-sm text-black/40 font-bold">Name</div>
            <div className="text-base text-black font-normal mb-3">{employee?.approver?.fullName}</div>
            <div className="text-sm text-black/40 font-bold">Department</div>
            <div className="text-base text-black font-normal mb-3">{employee?.approver?.departmentName}</div>
            <div className="text-sm text-black/40 font-bold">Designation</div>
            <div className="text-base text-black font-normal mb-3">{employee?.approver?.designationName}</div>
          </div>
          {!edit && (
            <button
              onClick={() => setEdit(true)}
              type="button"
              className="absolute right-4 top-6 hover:bg-green-400 p-1.5 w-8 h-8 rounded-md flex justify-center shadow-md"
            >
              <Edit2 color="#16a34a" variant="Bold" size={20} />
            </button>
          )}
        </div>
      </div>
      {edit && (
        <div className="rounded-xl bg-white shadow-lg w-full">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-6">
              <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                <div className="w-full lg:w-1/2">
                  <label className="mb-2.5 block text-black font-bold">Approving Manager</label>
                  <Dropdown
                    value={approverOptions.find((option) => option.fullName === formik.values.fullName)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) {
                        formik.setValues({
                          ...formik.values,
                          approverId: selectedValue.value,
                          fullName: selectedValue.label,
                          departmentName: selectedValue.departmentName,
                          departmentCode: selectedValue.departmentCode,
                          designationName: selectedValue.designationName,
                          designationCode: selectedValue.designationCode,
                          role: selectedValue.role
                        });
                      }
                    }}
                    options={approverOptions}
                    isClearable={false}
                    placeholder="Select Approving Manager"
                    name="fullName"
                    id="fullName"
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2"></div>
              </div>
            </div>
            <div className="p-6">
              <div className="flex justify-end w-full gap-x-2">
                <button
                  type="button"
                  onClick={() => setEdit(false)}
                  className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ApprovingDetails;
