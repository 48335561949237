import {
  FETCH_ALL_ADMINS,
  FETCH_ALL_COMPANY,
  FETCH_ALL_ORGANIZATION,
  FETCH_CURRENT_SUPER_ADMIN,
  SET_CURRENT_COMPANY,
  SET_CURRENT_ORGANIZATION
} from '../actions/superadminAction';
import { FETCH_USER_LOGOUT } from '../actions/userAction';

const initialState = {
  organizations: [],
  companies: [],
  admins: [],
  superAdmin: {},
  currentOrganization: {},
  currentCompany: {}
};

export const superAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_ORGANIZATION:
      return {
        ...state,
        organizations: action.payload.organizations
      };

    case FETCH_ALL_COMPANY:
      return {
        ...state,
        companies: action.payload.companies
      };

    case FETCH_ALL_ADMINS:
      return {
        ...state,
        admins: action.payload.adminEmployees
      };

    case FETCH_CURRENT_SUPER_ADMIN:
      return {
        ...state,
        superAdmin: action.payload.superAdmin
      };

    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: action.payload
      };

    case SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload
      };

    case FETCH_USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default superAdminReducer;
