import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { fetchPendingMedicals, getAllEmployees, getCurrentEmployee, getEmployee } from './employeeAction';
import { createApplyMedicalLeaveNotification, createApproveMedicalLeaveNotification, createCancelMedicalNotification, createRejectMedicalLeaveNotification } from './notificationAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const addMedicalLeave = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/add-medical-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteMedicalLeave = (inputData, setIsConfirmationModalOpen) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/delete-medical-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
        setIsConfirmationModalOpen(false);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
        setIsConfirmationModalOpen(false);
      }
    }
  };
};

export const updateMedicalLeave = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/update-medical-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const applyMedicalLeave = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/apply-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(employeeId));
        dispatch(createApplyMedicalLeaveNotification(inputValue, response.data.medicalHistoryId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const editMedicalLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/edit-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelMedicalLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/cancel-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        dispatch(createCancelMedicalNotification(inputValue))
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const getPendingMedicals = (companyId) => {
  console.log(companyId)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/medical/get-pending-medicals`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('res: ', response.data);
        dispatch(fetchPendingMedicals(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingMedicalsByApprover = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/medical/get-pending-medicals-by-approver`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('res: ', response.data);
        dispatch(fetchPendingMedicals(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const applyMedicalLeaveByUser = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/apply-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        dispatch(createApplyMedicalLeaveNotification(inputValue, response.data.medicalHistoryId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const editMedicalLeaveByUser = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/edit-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelMedicalLeaveByUser = (inputValue, employeeId) => {
  console.log('inputValue: ', inputValue);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/cancel-medical-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(employeeId));
        dispatch(createCancelMedicalNotification(inputValue))
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const approveMedical = (medicals, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/approve-medical`, medicals, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingMedicals(companyId));
        dispatch(createApproveMedicalLeaveNotification(medicals));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectMedical = (leaves, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/reject-medical`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingMedicals(companyId));
        dispatch(createRejectMedicalLeaveNotification(leaves));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveMedicalByApprover = (medicals) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/approve-medical`, medicals, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingMedicalsByApprover());
        dispatch(createApproveMedicalLeaveNotification(medicals));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectMedicalByApprover = (medicals) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/medical/reject-medical`, medicals, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingMedicalsByApprover());
        dispatch(createRejectMedicalLeaveNotification(medicals));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};