import { useEffect, useMemo, useState } from 'react';
import Layout from '../../../layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { getLeavesReport, getNoPayLeavesReport } from '../../../actions/reportAction';
import { faSort, faSortDown, faSortUp, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import empty from '../../../assets/images/empty.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import Dropdown from '../../../components/dropdown';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import Datepicker from 'react-tailwindcss-datepicker';
import * as XLSX from 'xlsx';
import { ToastBar } from '../../../components/toastbar';
import { getStatus } from '../../../constant/functions';

const NoPayLeavesReport = () => {
  const dispatch = useDispatch();
  const today = new Date();
  const thirtyDaysBefore = new Date();
  thirtyDaysBefore.setDate(today.getDate() - 30);

  const [sortConfig, setSortConfig] = useState({
    key: 'appliedDate',
    direction: 'descending'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: thirtyDaysBefore,
    endDate: today
  });

  const companies = useSelector((state) => state.company?.companies || []);
  const company = useSelector((state) => state.company?.company);
  const noPayLeavesReport = useSelector((state) => state.reports?.noPayLeaves) || [];

  useEffect(() => {
    const inputValue = {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      companyId: company?._id
    };
    dispatch(getNoPayLeavesReport(inputValue));
  }, [dispatch, company, dateRange]);

  useEffect(() => {
    dispatch(getAllCompanies());
  }, [dispatch]);

  const sortedNoPayLeaves = useMemo(() => {
    if (!noPayLeavesReport.length) {
      return [];
    }

    let sortableNoPayLeaves = noPayLeavesReport.flatMap((leave) =>
      leave.noPayLeaves.map((leaves) => ({
        ...leaves,
        employee: leave.employee
      }))
    );

    sortableNoPayLeaves.sort((a, b) => {
      const fieldA = sortConfig.key.split('.').reduce((obj, key) => obj[key], a);
      const fieldB = sortConfig.key.split('.').reduce((obj, key) => obj[key], b);

      const valueA = typeof fieldA === 'string' ? fieldA.toLowerCase() : String(fieldA || '');
      const valueB = typeof fieldB === 'string' ? fieldB.toLowerCase() : String(fieldB || '');

      if (sortConfig.direction === 'ascending') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    return sortableNoPayLeaves;
  }, [noPayLeavesReport, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 4:
        return { label: 'Processing', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 2:
        return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
      case 3:
        return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
      case 5:
        return { label: 'Confirmed', bgColor: 'bg-blue-500', textColor: 'text-blue-500' };
      default:
        return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedNoPayLeaves.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedNoPayLeaves.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    if (selectedValue) {
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };

  const handleRangeChange = (newValue) => {
    console.log('datePickerValue:', newValue);
    setDateRange(newValue);
  };

  const downloadExcel = () => {
    try {
      if (!sortedNoPayLeaves.length) {
        ToastBar.warning('No Leaves Found');
        return;
      }

      const formattedLeaves = sortedNoPayLeaves?.map((leave) => {
        const formatCurrency = (amount, currencySymbol = leave?.employee?.salaryDetails?.currency) => {
          if (amount == null) return `${currencySymbol} 0.00`;
          return `${currencySymbol} ${amount.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
        };

        const formattedFromDate = !Number.isNaN(new Date(leave.fromDate).getTime())
          ? format(new Date(leave.fromDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedToDate = !Number.isNaN(new Date(leave.toDate).getTime())
          ? format(new Date(leave.toDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedAppliedDate = !Number.isNaN(new Date(leave.appliedDate).getTime())
          ? format(new Date(leave.appliedDate), 'dd-MM-yyyy HH:mm')
          : 'Invalid Date';

        return {
          'Full Name': leave?.employee?.fullName || '',
          'No Pay Leave Type': leave?.noPayLeaveType,
          'From Date': formattedFromDate,
          'To Date': formattedToDate,
          'Num of Days': leave?.numberOfDays,
          'Daily Rate': formatCurrency(leave?.dailyRate),
          'Total Amount': formatCurrency(leave?.totalAmount),
          'Applied Date': formattedAppliedDate,
          'Status': getStatus(leave?.status),
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(formattedLeaves);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'No Pay Leaves Report');

      const maxLength = {};
      formattedLeaves.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          maxLength[key] = Math.max(maxLength[key] || 0, length);
        });
      });
      const colWidths = Object.entries(maxLength).map(([key, length]) => {
        if (key === 'Num of Days') return { wch: 12 };
        else if (key === 'No Pay Leave Type') return { wch: 16 }
        else if (key === 'Daily Rate') return { wch: 12 }
        else if (key === 'Total Amount') return { wch: 12 }
        return { wch: length + 2 };
      });
      worksheet['!cols'] = colWidths;

      const filename = `Generate_No_Pay_Leaves_Report.xlsx`;
      XLSX.writeFile(workbook, filename);
      ToastBar.success('Excel Download Successful');
    } catch (error) {
      console.log(error);
      ToastBar.error('Error Downloading Excel');
    }
  };

  return (
    <Layout pageTitle={'No Pay Leaves Report'} page={'Reports'} subPage={'No Pay Leaves Report'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl min-h-[70vh] h-full overflow-y-auto">
        <div>
          <div className="flex flex-col gap-4 gap-x-4 w-full">
            <div className="w-full lg:w-1/2">
              <label className="block text-black font-semibold mb-0.5">Select Company</label>
              {companyOptions.length > 0 && (
                <Dropdown
                  value={companyOptions?.find((option) => option.value === company?.companyName)}
                  onChange={handleCompanyChange}
                  options={companyOptions}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Select Company"
                  name="company"
                  id="company"
                />
              )}
            </div>
            <div className="flex flex-row items-end gap-x-5">
              <div className="w-72">
                <label className="block text-black font-semibold mb-0.5">Select Range</label>
                <Datepicker
                  primaryColor="indigo"
                  popoverDirection="down"
                  value={dateRange}
                  onChange={handleRangeChange}
                  displayFormat="DD-MM-YYYY"
                  inputClassName="w-full px-4 rounded-lg bg-grayLight text-sm h-12 cursor-pointer"
                />
              </div>
              <div onClick={downloadExcel} className="cursor-pointer" title="Download Payroll Employees">
                <FontAwesomeIcon icon={faFileExcel} className="text-[42px]" color="#3CA455" />
              </div>
            </div>
          </div>
          {sortedNoPayLeaves.length > 0 ? (
            <div className="-mt-14">
              <div className="flex justify-end items-end  my-5">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
                    <th onClick={() => handleSort('employee.fullName')} className="cursor-pointer">
                      Full Name
                      <FontAwesomeIcon icon={getSortIcon('employee.fullName')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('leaveType')} className="cursor-pointer">
                      No Pay Leave Type
                      <FontAwesomeIcon icon={getSortIcon('leaveType')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('fromDate')} className="cursor-pointer">
                      From Date
                      <FontAwesomeIcon icon={getSortIcon('fromDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('toDate')} className="cursor-pointer">
                      To Date
                      <FontAwesomeIcon icon={getSortIcon('toDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('numberOfDays')} className="cursor-pointer">
                      Num of Days
                      <FontAwesomeIcon icon={getSortIcon('numberOfDays')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('appliedDate')} className="cursor-pointer">
                      Applied Date
                      <FontAwesomeIcon icon={getSortIcon('appliedDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4 rounded-e-xl">
                      Status
                      <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1 " />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((leave, index) => {
                    const formattedFromDate = !Number.isNaN(new Date(leave.fromDate).getTime())
                      ? format(new Date(leave.fromDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedToDate = !Number.isNaN(new Date(leave.toDate).getTime())
                      ? format(new Date(leave.toDate), 'dd-MM-yyyy')
                      : 'Invalid Date';

                    const formattedAppliedDate = !Number.isNaN(new Date(leave.appliedDate).getTime())
                      ? format(new Date(leave.appliedDate), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date';

                    const { label, bgColor, textColor } = getStatusLabel(leave.status);

                    return (
                      <tr className={`h-20 text-sm text-black`} key={index}>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={leave?.employee?.avatar ? leave?.employee?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center" title={leave.employee.fullName}>
                          {leave.employee.fullName}
                        </td>
                        <td className="h-20 text-center">{leave.noPayLeaveType}</td>
                        <td className="h-20 text-center">{formattedFromDate}</td>
                        <td className="h-20 text-center">{formattedToDate}</td>
                        <td className="h-20 text-center">{leave.numberOfDays}</td>
                        <td className="h-20 text-center">{formattedAppliedDate}</td>
                        <td className="h-20 w-24">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            company?.companyName && (
              <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
                <img src={empty} alt="empty data" />
                <div className="mt-5 text-purple1 font-bold text-xl">No Pay Leaves</div>
              </div>
            )
          )}
          {!company?.companyName && (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Company Selected</div>
            </div>
          )}
        </div>
        {currentEntries.length > 0 && (
          <div className="flex">
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedNoPayLeaves.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${
                          pageNumber === currentPage
                            ? 'bg-purple1 text-white rounded-xl font-bold'
                            : 'text-black bg-oppty-green-5'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default NoPayLeavesReport;
