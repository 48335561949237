import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { getAllEmployees } from './employeeAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const addAllowance = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/allowance/add-allowance`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateAllowance = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/allowance/update-allowance`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteAllowance = (inputData, setIsConfirmationModalOpen) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/allowance/delete-allowance`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};