import { payrollCutOffSchema } from '../../../validations/validationSchemas';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { updatePayrollCutoff } from '../../../actions/companyAction';
import { dayOptions } from '../../../constant/dropdownConstOptions';
import Dropdown from '../../../components/dropdown';

const EditPayrollCutoff = ({ company, onClose }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      companyId: company?._id,
      day: company?.payRollCutOff
    },
    enableReinitialize: true,
    validationSchema: payrollCutOffSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(updatePayrollCutoff(values, onClose));
    }
  });

  return (
    <div className="rounded-sm border border-transparent min-w-[600px]">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-0 lg:gap-6 lg:flex-row items-center">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Day</label>
              <Dropdown
                value={dayOptions.find((option) => option.value === formik.values.day)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    formik.setValues({
                      ...formik.values,
                      day: selectedValue.value
                    });
                  }
                }}
                options={dayOptions}
                isClearable={false}
                placeholder="Select Day"
                name="day"
                id="day"
              />
              {formik.touched.day && formik.errors.day ? (
                <div className="text-red-500 text-sm">{formik.errors.day}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2">
              <div className="mt-2 lg:mt-6 text-black font-bold">Of the month</div>
            </div>
          </div>
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditPayrollCutoff;
