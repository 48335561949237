import React from 'react';
import HrLogo from '../../assets/logo/hrLogoBlue2.png'

const SuperAdminHeader = ({ page, subPage, pageTitle }) => {
  return (
    <header className="sticky top-0 z-[40] flex w-full drop-shadow-1">
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex flex-col items-start justify-start">
          {page && (
            <div className="text-sm text-black/40">
              {page} / {subPage}
            </div>
          )}
          <div className="text-2xl sm:text-[32px] font-[900] mt-2 text-black">{pageTitle}</div>
        </div>

        <div className="flex items-center gap-3">
          <div className="flex items-center justify-start gap-x-2 h-11 pl-2 pr-3 rounded-full shadow-lg" to="#">
            <span className="h-5 w-5">
              <img src={HrLogo} alt="User" className='w-full h-full'/>
            </span>

            <span className="hidden text-right lg:block">
              <span className="block text-sm font-medium text-black/40 mt-0.5">Super Admin</span>
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SuperAdminHeader;
