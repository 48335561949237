import { useDispatch, useSelector } from 'react-redux';
import SuperAdminLayout from '../superadminLayout';
import { useFormik } from 'formik';
import { createCompanySchema } from '../../../validations/validationSchemas';
import { useEffect, useState } from 'react';
import SuperAdminDropdown from '../superadminDropdown';
import {
  addCompany,
  fetchAllCompanies,
  getAllCompaniesByOrganization,
  getAllOrganization,
  setCurrentOrganization
} from '../../../actions/superadminAction';
import empty from '../../../assets/images/empty.png';
import CompanyList from './companyList';

const SuperAdminCreateCompany = () => {
  const dispatch = useDispatch();

  const [isAdd, setAdd] = useState(false);

  const organizations = useSelector((state) => state.superAdmin?.organizations || []);
  const companies = useSelector((state) => state.superAdmin?.companies || []);
  const currentOrganization = useSelector((state) => state.superAdmin?.currentOrganization);

  console.log('currentOrganization: ', currentOrganization);

  const formik = useFormik({
    initialValues: {
      organizationId: '',
      companyName: ''
    },
    validationSchema: createCompanySchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(addCompany(values, setAdd, formik.resetForm));
    }
  });

  console.log('formik: ', formik.values);

  useEffect(() => {
    dispatch(getAllOrganization());
    dispatch(getAllCompaniesByOrganization(currentOrganization?.organizationId));
  }, [dispatch]);

  useEffect(() => {
    if (currentOrganization?.organizationId) {
      formik?.setFieldValue('organizationId', currentOrganization?.organizationId);
    } else {
      formik.setFieldValue('organizationId', '');
    }
  }, [currentOrganization]);

  const organizationOption =
    organizations?.map((organization) => ({
      organizationId: organization?._id,
      label: organization?.organizationName,
      value: organization?.organizationName
    })) || [];

  const handleOrganizationChange = (option) => {
    console.log('option: ', option);
    if(option === null) {
      formik.setFieldValue('organizationId', '');
      dispatch(setCurrentOrganization({}));
      dispatch(fetchAllCompanies([]))
    } else {
      formik.setFieldValue('organizationId', option.organizationId);
      formik.setFieldTouched('organizationId', true, false);
      dispatch(setCurrentOrganization(option));
      dispatch(getAllCompaniesByOrganization(option?.organizationId));
    }
  };

  return (
    <SuperAdminLayout pageTitle={'Create New Company'} page={'Super Admin'} subPage={'Create New Company'}>
      <div className="w-full bg-white rounded-xl p-6 min-h-[80vh]">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex items-center justify-between">
            <div className="w-full lg:w-1/3">
              <div className="mb-2.5 block text-black font-medium">Select Organization</div>
              <SuperAdminDropdown
                options={organizationOption}
                value={
                  currentOrganization?.organizationId
                    ? organizationOption.find((option) => option?.organizationId === currentOrganization?.organizationId)
                    : '' // Ensures dropdown is empty if no organization is selected
                }
                onChange={handleOrganizationChange}
                placeholder="Select Organization"
                name="organizationId"
                id="organizationId"
              />
            </div>
            <button
              type="button"
              className={`${isAdd ? 'bg-red-600' : 'bg-orange-600'} px-5 py-2 w-fit text-white rounded-xl font-bold cursor-pointer disabled:bg-gray-500`}
              onClick={() => {
                formik.resetForm();
                formik?.setFieldValue('organizationId', currentOrganization?.organizationId);
                setAdd(!isAdd);
              }}
              disabled={!currentOrganization?.organizationId}
            >
              {isAdd ? 'Cancel' : '+ Add Company'}
            </button>
          </div>
          {!currentOrganization?.organizationId && (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">Select Organization</div>
            </div>
          )}
          {isAdd && (
            <div className="md:max-w-[500px] w-full">
              <div className="bg-white p-6 rounded-2xl shadow-lg mt-5">
                <h4 className="text-2xl font-bold mb-5 text-black">Create Company</h4>
                <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full">
                    <label className="mb-2.5 block text-black font-medium">Company Name</label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      placeholder="Enter Company Name"
                      className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight h-12 px-5 text-black disabled:text-gray2 outline-none transition focus:border-orange-600 active:border-orange-600 disabled:cursor-default disabled:bg-whiter"
                      onChange={formik.handleChange}
                      value={formik.values.companyName}
                    />
                    {formik.touched.companyName && formik.errors.companyName ? (
                      <div className="text-red-500 text-sm">{formik.errors.companyName}</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-orange-600 hover:bg-orange-600/90 text-white font-bold rounded-lg px-4 py-2"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          )}
          {companies?.length > 0 ? (
            <div className="mt-10">
              <CompanyList allCompanies={companies} />
            </div>
          ) : currentOrganization && Object.keys(currentOrganization).length > 0 && (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Companies for this Organization</div>
            </div>
          )}
        </form>
      </div>
    </SuperAdminLayout>
  );
};

export default SuperAdminCreateCompany;
