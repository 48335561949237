import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import { useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { ToastBar } from '../../../components/toastbar';
import { format } from 'date-fns';
import { getStatus } from '../../../constant/functions';

const EmployeeList = ({ allEmployees, company }) => {
  const [sortConfig, setSortConfig] = useState({
    key: 'fullName',
    direction: 'ascending'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const sortedEmployees = useMemo(() => {
    if (!allEmployees) return [];

    let sortableEmployees = [...allEmployees];
    sortableEmployees.sort((a, b) => {
      let aValue, bValue;

      if (sortConfig.key === 'fullName') {
        aValue = `${a.fullName}`.toLowerCase();
        bValue = `${b.fullName}`.toLowerCase();
      } else if (sortConfig?.key === 'employmentDetails.designationName') {
        aValue = `${a.employmentDetails?.designationName || ''}`.toLowerCase();
        bValue = `${b.employmentDetails?.designationName || ''}`.toLowerCase();
      } else if (sortConfig?.key === 'employmentDetails.departmentName') {
        aValue = `${a.employmentDetails?.departmentName || ''}`.toLowerCase();
        bValue = `${b.employmentDetails?.departmentName || ''}`.toLowerCase();
      } else {
        return 0;
      }

      if (sortConfig.direction === 'ascending') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });

    return sortableEmployees;
  }, [allEmployees, sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedEmployees.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedEmployees.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const convertMinutesToTimeFormat = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  const downloadExcel = (employee) => {
    try {
      console.log('employee: ', employee);
      if (!employee) {
        ToastBar.warning('No Employee Found');
        return;
      }

      const formatCurrency = (amount, currencySymbol = employee?.salaryDetails?.currency) => {
        if (amount == null) return `${currencySymbol} 0.00`;
        return `${currencySymbol} ${amount.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      };

      const leaveHistory = employee?.leaveDetails?.leavesHistory || [];
      const medicalHistory = employee?.medicalLeaveDetails?.medicalsHistory || [];
      const claimHistory = employee?.claimDetails?.claimsHistory || [];
      const timesheetHistory = employee?.timesheetDetails?.timesheetHistory || [];
      const noPayLeaveHistory = employee?.noPayLeaveDetails?.noPayLeavesHistory || [];
      const payrolls = employee?.payrolls;

      const formattedEmployee = [
        {
          'Emp ID': employee?.empId,
          'Full Name': employee?.fullName || '',
          Designation: employee?.employmentDetails?.designationName,
          Department: employee?.employmentDetails?.departmentName,
          Email: employee?.email,
          Citizenship: employee?.citizenship,
          Country: employee?.country,
          Mobile: `${employee?.areaCode}${employee?.mobile}`
        }
      ];

      const formattedLeaveHistory = [];

      leaveHistory.forEach((leave) => {
        const formattedFromDate = !Number.isNaN(new Date(leave.fromDate).getTime())
          ? format(new Date(leave.fromDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedToDate = !Number.isNaN(new Date(leave.toDate).getTime())
          ? format(new Date(leave.toDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedAppliedDate = !Number.isNaN(new Date(leave.appliedDate).getTime())
          ? format(new Date(leave.appliedDate), 'dd-MM-yyyy HH:mm')
          : 'Invalid Date';

        formattedLeaveHistory.push({
          'Leave Type': leave?.leaveType || '',
          'Leave Code': leave?.leaveCode || '',
          'From Date': formattedFromDate,
          'To Date': formattedToDate,
          'Num of Days': leave?.numberOfDays || 0,
          'Applied Date': formattedAppliedDate,
          Status: getStatus(leave?.status) || ''
        });
      });

      const formattedMedicalHistory = [];

      medicalHistory.forEach((medical) => {
        const formattedFromDate = !Number.isNaN(new Date(medical.fromDate).getTime())
          ? format(new Date(medical.fromDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedToDate = !Number.isNaN(new Date(medical.toDate).getTime())
          ? format(new Date(medical.toDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedAppliedDate = !Number.isNaN(new Date(medical.appliedDate).getTime())
          ? format(new Date(medical.appliedDate), 'dd-MM-yyyy HH:mm')
          : 'Invalid Date';

        formattedMedicalHistory.push({
          'Medical Type': medical?.medicalType || '',
          'Medical Code': medical?.medicalCode || '',
          'From Date': formattedFromDate,
          'To Date': formattedToDate,
          'Num of Days': medical?.numberOfDays || 0,
          'Applied Date': formattedAppliedDate,
          Status: getStatus(medical?.status) || ''
        });
      });

      const formattedClaimHistory = [];

      claimHistory.forEach((claim) => {
        const formattedClaimDate = !Number.isNaN(new Date(claim.claimDate).getTime())
          ? format(new Date(claim.claimDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedCreatedDate = !Number.isNaN(new Date(claim.createdDate).getTime())
          ? format(new Date(claim.createdDate), 'dd-MM-yyyy HH:mm')
          : 'Invalid Date';

        formattedClaimHistory.push({
          'Claim Type': claim?.claimType || '',
          'Claim Code': claim?.claimCode || '',
          'Claim Date': formattedClaimDate,
          'Claim Amount': formatCurrency(claim?.claimAmount) || '0.00',
          'Created Date': formattedCreatedDate,
          Status: getStatus(claim?.status) || ''
        });
      });

      const formattedTimesheetHistory = [];

      timesheetHistory.forEach((timesheet) => {
        const formattedCreatedDate = !Number.isNaN(new Date(timesheet.createdDate).getTime())
          ? format(new Date(timesheet.createdDate), 'dd-MM-yyyy HH:mm')
          : 'Invalid Date';

        formattedTimesheetHistory.push({
          'Start Time': convertMinutesToTimeFormat(timesheet.startTime),
          'End Time': convertMinutesToTimeFormat(timesheet.endTime),
          'Hourly Rate': formatCurrency(timesheet?.hourlyRate),
          Amount: formatCurrency(timesheet?.amount) || '0.00',
          'Created Date': formattedCreatedDate,
          Status: getStatus(timesheet?.status) || ''
        });
      });

      const formattedNoPayLeaveHistory = [];

      noPayLeaveHistory.forEach((leave) => {
        const formattedFromDate = !Number.isNaN(new Date(leave.fromDate).getTime())
          ? format(new Date(leave.fromDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedToDate = !Number.isNaN(new Date(leave.toDate).getTime())
          ? format(new Date(leave.toDate), 'dd-MM-yyyy')
          : 'Invalid Date';

        const formattedAppliedDate = !Number.isNaN(new Date(leave.appliedDate).getTime())
          ? format(new Date(leave.appliedDate), 'dd-MM-yyyy HH:mm')
          : 'Invalid Date';

        formattedNoPayLeaveHistory.push({
          'No Pay Leave Type': leave?.noPayLeaveType || '',
          'No Pay Leave Code': leave?.noPayLeaveCode || '',
          'From Date': formattedFromDate,
          'To Date': formattedToDate,
          'Num of Days': leave?.numberOfDays || 0,
          'Applied Date': formattedAppliedDate,
          Status: getStatus(leave?.status) || ''
        });
      });

      const formattedPayrolls = payrolls.map((payroll) => [
        [payroll?.year + ' ' + payroll?.month],
        [
          'Basic Salary',
          'Employer CPF Percentage',
          'Employer CPF',
          'Employee CPF Percentage',
          'Employee CPF',
          'Gross Salary',
          'Net Salary',
          'Total CPF Contribution'
        ],
        [
          formatCurrency(payroll?.payrollDetails?.salary?.[0]?.basicSalary) || '0.00',
          payroll?.payrollDetails?.salary?.[0]?.employerCPFPercentage || 0,
          formatCurrency(payroll?.payrollDetails?.salary?.[0]?.employerCPF) || '0.00',
          payroll?.payrollDetails?.salary?.[0]?.employeeCPFPercentage || 0,
          formatCurrency(payroll?.payrollDetails?.salary?.[0]?.employeeCPF) || '0.00',
          formatCurrency(payroll?.payrollDetails?.salary?.[0]?.grossSalary) || '0.00',
          formatCurrency(payroll?.payrollDetails?.salary?.[0]?.netSalary) || '0.00',
          formatCurrency(payroll?.payrollDetails?.salary?.[0]?.totalCPFContribution) || '0.00'
        ],
        [],
        ['No Pay Leave'],
        ['Total No Pay Leave Amount'],
        [formatCurrency(payroll?.payrollDetails?.noPayLeaves?.[0]?.totalNoPayLeaveAmount) || '0.00'],
        [],
        ['CPF'],
        ['Employee CPF', 'Employer CPF', 'CPF Contribution'],
        [
          formatCurrency(payroll?.payrollDetails?.cpf?.[0]?.employeeCPF) || '0.00',
          formatCurrency(payroll?.payrollDetails?.cpf?.[0]?.employerCPF) || '0.00',
          formatCurrency(payroll?.payrollDetails?.cpf?.[0]?.CPFContribution) || '0.00'
        ],
        [],
        ['Allowances'],
        [
          'Allowance Employee CPF',
          'Employee CPF Percentage',
          'Allowance Employer CPF',
          'Employer CPF Percentage',
          'Total Allowance',
          'Total Allowance CPF Contribution',
          'Net Allowance Amount'
        ],
        [
          formatCurrency(payroll?.payrollDetails?.allowances?.[0]?.allowanceEmployeeCPF) || '0.00',
          payroll?.payrollDetails?.allowances?.[0]?.employeeCPFPercentage || 0,
          formatCurrency(payroll?.payrollDetails?.allowances?.[0]?.allowanceEmployerCPF) || '0.00',
          payroll?.payrollDetails?.allowances?.[0]?.employerCPFPercentage || 0,
          formatCurrency(payroll?.payrollDetails?.allowances?.[0]?.totalAllowance) || '0.00',
          formatCurrency(payroll?.payrollDetails?.allowances?.[0]?.totalAllowanceCPFContribution) || '0.00',
          formatCurrency(payroll?.payrollDetails?.allowances?.[0]?.netAllowanceAmount) || '0.00'
        ],
        [],
        ['Deductions'],
        ['Total Deduction Amount'],
        [formatCurrency(payroll?.payrollDetails?.deductions?.[0]?.totalDeductionAmount) || '0.00'],
        [],
        ['Claims'],
        ['Total Claim Amount'],
        [formatCurrency(payroll?.payrollDetails?.claims?.[0]?.totalClaimAmount) || '0.00'],
        []
        ['Bonuses'],
        [
          'Bonus Employee CPF',
          'Employee CPF Percentage %',
          'Bonus Employer CPF',
          'Employer CPF Percentage %',
          'Total Bonus',
          'Total Bonus CPF Contribution',
          'Net Bonus Amount'
        ],
        [
          formatCurrency(payroll?.payrollDetails?.bonuses?.[0]?.bonusEmployeeCPF) || '0.00',
          payroll?.payrollDetails?.bonuses?.[0]?.employeeCPFPercentage || 0,
          formatCurrency(payroll?.payrollDetails?.bonuses?.[0]?.bonusEmployerCPF) || '0.00',
          payroll?.payrollDetails?.bonuses?.[0]?.employerCPFPercentage || 0,
          formatCurrency(payroll?.payrollDetails?.bonuses?.[0]?.netBonusAmount) || '0.00',
          formatCurrency(payroll?.payrollDetails?.bonuses?.[0]?.totalBonusCPFContribution) || '0.00',
          formatCurrency(payroll?.payrollDetails?.bonuses?.[0]?.netBonusAmount) || '0.00'
        ],
        [],
      ]);

      const flattenedPayrolls = formattedPayrolls.flat();

      const employeeSheet = XLSX.utils.json_to_sheet(formattedEmployee);
      const leaveHistorySheet = XLSX.utils.json_to_sheet(formattedLeaveHistory);
      const medicalHistorySheet = XLSX.utils.json_to_sheet(formattedMedicalHistory);
      const claimHistorySheet = XLSX.utils.json_to_sheet(formattedClaimHistory);
      const timesheetHistorySheet = XLSX.utils.json_to_sheet(formattedTimesheetHistory);
      const noPayLeaveHistorySheet = XLSX.utils.json_to_sheet(formattedNoPayLeaveHistory);
      const payrollSheet = XLSX.utils.aoa_to_sheet(flattenedPayrolls);

      payrollSheet['!cols'] = [
        { wch: 24 },
        { wch: 26 },
        { wch: 22 },
        { wch: 26 },
        { wch: 14 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 }
      ];

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, employeeSheet, 'Employee Details');
      XLSX.utils.book_append_sheet(workbook, leaveHistorySheet, 'Leave History');
      XLSX.utils.book_append_sheet(workbook, medicalHistorySheet, 'Medical Leave History');
      XLSX.utils.book_append_sheet(workbook, claimHistorySheet, 'Claim History');
      XLSX.utils.book_append_sheet(workbook, timesheetHistorySheet, 'Timesheet History');
      XLSX.utils.book_append_sheet(workbook, noPayLeaveHistorySheet, 'No Pay Leave History');
      XLSX.utils.book_append_sheet(workbook, payrollSheet, 'Payroll');

      const maxLength = {};
      formattedEmployee.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          maxLength[key] = Math.max(maxLength[key] || 0, length);
        });
      });
      const employeeColWidths = Object.entries(maxLength).map(([key, length]) => {
        if (key === 'Department') return { wch: 12 }
        else if (key === 'Full Name') return { wch: 12 }
        return { wch: length + 2 };
      });
      employeeSheet['!cols'] = employeeColWidths;

      const leaveMaxLength = {};
      formattedLeaveHistory.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          leaveMaxLength[key] = Math.max(leaveMaxLength[key] || 0, length);
        });
      });

      const leaveColWidths = Object.entries(leaveMaxLength).map(([key, length]) => {
        if (key === 'Leave Type') return { wch: 20 };
        else if (key === 'Leave Code') return { wch: 12 };
        else if (key === 'Num of Days') return { wch: 12 };
        else if (key === 'Status') return { wch: 12 };
        return { wch: length + 2 };
      });
      leaveHistorySheet['!cols'] = leaveColWidths;

      const medicalMaxLength = {};
      formattedMedicalHistory.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          medicalMaxLength[key] = Math.max(medicalMaxLength[key] || 0, length);
        });
      });

      const medicalColWidths = Object.entries(medicalMaxLength).map(([key, length]) => {
        if (key === 'Medical Type') return { wch: 20 };
        else if (key === 'Medical Code') return { wch: 12 };
        else if (key === 'Num of Days') return { wch: 12 };
        else if (key === 'Status') return { wch: 12 };
        return { wch: length + 2 };
      });
      medicalHistorySheet['!cols'] = medicalColWidths;

      const claimMaxLength = {};
      formattedClaimHistory.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          claimMaxLength[key] = Math.max(claimMaxLength[key] || 0, length);
        });
      });

      const claimColWidths = Object.entries(claimMaxLength).map(([key, length]) => {
        if (key === 'Claim Type') return { wch: 20 };
        else if (key === 'Claim Code') return { wch: 12 };
        else if (key === 'Claim Amount') return { wch: 14 };
        else if (key === 'Status') return { wch: 12 };
        return { wch: length + 2 };
      });
      claimHistorySheet['!cols'] = claimColWidths;

      const timesheetMaxLength = {};
      formattedTimesheetHistory.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          timesheetMaxLength[key] = Math.max(timesheetMaxLength[key] || 0, length);
        });
      });

      const timesheetColWidths = Object.entries(timesheetMaxLength).map(([key, length]) => {
        if (key === 'Start Time') return { wch: 14 };
        else if (key === 'End Time') return { wch: 12 };
        else if (key === 'Claim Amount') return { wch: 14 };
        else if (key === 'Status') return { wch: 12 };
        return { wch: length + 2 };
      });
      timesheetHistorySheet['!cols'] = timesheetColWidths;

      const noPayLeaveMaxLength = {};
      formattedNoPayLeaveHistory.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          noPayLeaveMaxLength[key] = Math.max(noPayLeaveMaxLength[key] || 0, length);
        });
      });

      const noPayLeaveColWidths = Object.entries(noPayLeaveMaxLength).map(([key, length]) => {
        if (key === 'No Pay Leave Type') return { wch: 22 };
        else if (key === 'No Pay Leave Code') return { wch: 18 };
        else if (key === 'Num of Days') return { wch: 12 };
        else if (key === 'Status') return { wch: 12 };
        return { wch: length + 2 };
      });
      noPayLeaveHistorySheet['!cols'] = noPayLeaveColWidths;

      const filename = `${employee?.fullName}.xlsx`;
      XLSX.writeFile(workbook, filename);
      ToastBar.success('Excel Download Successful');
    } catch (error) {
      console.log(error);
      ToastBar.error('Error Downloading Excel');
    }
  };

  return (
    <div>
      <div className="flex justify-end items-end  mb-5">
        <div className="flex items-center font-semibold text-black">
          <label htmlFor="entriesPerPage" className="mr-2">
            Show
          </label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesPerPageChange}
            className="border border-gray-300 rounded-md p-1 pl-3"
          >
            {[5, 10, 20, 50].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
          <span className="ml-2">Records</span>
        </div>
      </div>
      <table className="table-auto rounded-2xl w-full">
        <thead>
          <tr className="h-20 bg-grayLight">
            <th className="rounded-s-xl text-base font-bold text-black">ID</th>
            <th className="text-base font-bold text-black">Photo</th>
            <th onClick={() => handleSort('fullName')} className="cursor-pointer text-base font-bold text-black">
              Name
              <FontAwesomeIcon icon={getSortIcon('fullName')} size="sm" className="ml-1" />
            </th>
            <th
              onClick={() => handleSort('employmentDetails.designationName')}
              className="cursor-pointer text-base font-bold text-black"
            >
              Designation
              <FontAwesomeIcon icon={getSortIcon('employmentDetails.designationName')} size="sm" className="ml-1" />
            </th>
            <th
              onClick={() => handleSort('employmentDetails.departmentName')}
              className="text-base font-bold text-black cursor-pointer"
            >
              Department <FontAwesomeIcon icon={getSortIcon('employmentDetails.departmentName')} />
            </th>
            <th className="text-base font-bold text-black">Status</th>
            <th className="rounded-e-xl text-base font-bold text-black">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries?.map((employee, index) => {
            return (
              <tr className={`h-20`} key={index}>
                <td className="text-sm text-center text-black">{employee?.empId}</td>
                <td className="h-20">
                  <div className="flex justify-center items-center">
                    <img
                      src={employee?.avatar ? employee?.avatar : defaultPic}
                      alt="profile pic"
                      className="w-8 h-8 rounded-full object-cover"
                    />
                  </div>
                </td>
                <td className="h-20 text-sm text-center text-black">
                  <span>{employee?.fullName}</span>
                </td>
                <td className="h-20 text-center text-sm text-black">{employee?.employmentDetails?.designationName}</td>
                <td className="h-20 text-center text-sm text-black">{employee?.employmentDetails?.departmentName}</td>
                <td className="h-20 w-24">
                  <div className="flex justify-center">
                    <div className="bg-grayLight flex items-center justify-center w-fit px-1 rounded-full">
                      {employee?.status === 1 ? (
                        <div className="flex items-center gap-x-1">
                          <div className="w-2.5 h-2.5 rounded-full bg-green1"></div>
                          <span className="text-xs text-green1">Active</span>
                        </div>
                      ) : (
                        <div className="flex items-center gap-x-1">
                          <div className="w-2.5 h-2.5 rounded-full bg-gray-500"></div>
                          <span className="text-xs text-gray-400">Inactive</span>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    onClick={() => downloadExcel(employee)}
                    className="flex justify-center items-center cursor-pointer"
                    title="Download employee details"
                  >
                    <FontAwesomeIcon icon={faFileExcel} size="xl" color="#3CA455" />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex">
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing{' '}
              <span className="font-medium">
                {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedEmployees.length)}
              </span>{' '}
              records
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                disabled={currentPage === 1}
              >
                <span className="">{'<'}</span>
              </button>
              <div>
                {pageNumbers?.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={`relative items-center w-10 h-10 text-base text-center ${
                      pageNumber === currentPage
                        ? 'bg-purple1 text-white rounded-xl font-bold'
                        : 'text-black bg-oppty-green-5'
                    }`}
                  >
                    {pageNumber}
                  </button>
                ))}
              </div>
              <button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                disabled={currentPage === totalPages}
              >
                <span className="">{'>'}</span>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeList;
