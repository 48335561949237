import { ToastBar } from '../components/toastbar';

export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= 4; i++) {
    const year = currentYear - i;
    years.push({ value: year, label: year.toString() });
  }
  return years;
};

export const getMonthOptions = (year) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const months = [
    { value: 'January', label: 'January' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' }
  ];

  if (year === currentYear) {
    return months.slice(0, currentMonth + 1);
  } else {
    return months;
  }
};

const numbersToWords = {
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
  13: 'thirteen',
  14: 'fourteen',
  15: 'fifteen',
  16: 'sixteen',
  17: 'seventeen',
  18: 'eighteen',
  19: 'nineteen',
  20: 'twenty',
  30: 'thirty',
  40: 'forty',
  50: 'fifty',
  60: 'sixty',
  70: 'seventy',
  80: 'eighty',
  90: 'ninety'
};

export function convertAmountToWords(number) {
  if (number in numbersToWords) return numbersToWords[number];

  let words = '';

  if (number >= 100) {
    words += convertAmountToWords(Math.floor(number / 100)) + ' hundred';
    number %= 100;
  }

  if (number > 0) {
    if (words !== '') words += ' and ';
    if (number < 20) words += numbersToWords[number];
    else {
      words += numbersToWords[Math.floor(number / 10) * 10];
      if (number % 10 > 0) {
        words += '-' + numbersToWords[number % 10];
      }
    }
  }

  return words + ' only';
}

export const convertToMinutes = (hours, minutes, period) => {
  let totalMinutes = parseInt(hours) * 60 + parseInt(minutes);

  if (period === 'PM' && hours !== '12') {
    totalMinutes += 12 * 60;
  }

  if (period === 'AM' && hours === '12') {
    totalMinutes -= 12 * 60;
  }
  return totalMinutes;
};

export const calculateDuration = (startTime, endTime) => {
  const startMinutes = convertToMinutes(startTime.hours, startTime.minutes, startTime.period);
  const endMinutes = convertToMinutes(endTime.hours, endTime.minutes, endTime.period);

  let durationMinutes = endMinutes - startMinutes;

  if (durationMinutes < 0) {
    durationMinutes += 24 * 60;
  }

  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;

  return { hours, minutes };
};

export const getStatusLabel = (status) => {
  switch (status) {
    case 1:
      return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
    case 4:
      return { label: 'Processing', bgColor: 'bg-green-500', textColor: 'text-green-500' };
    case 2:
      return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
    case 3:
      return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
    case 5:
        return { label: 'Confirmed', bgColor: 'bg-blue-500', textColor: 'text-blue-500' };
    default:
      return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
  }
};

export const getStatus = (status) => {
  switch(status) {
    case 1:
      return 'Approved';
    case 4:
      return 'Processing';
    case 2:
      return 'Pending';
    case 3:
      return 'Cancelled';
    case 5:
      return 'Confirmed';
    case 0:
      return 'Rejected';
    default:
      return ''
  }
}

export const convertTime = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const period = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 === 0 ? 12 : hours % 12; // Handle 12-hour format
  return {
    hours: adjustedHours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
    period
  };
};

export const convertHours = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
};

export const handleFloatChange = (formik, value, fieldName) => {
  const formattedValue = parseFloat(value).toFixed(2);
  formik.setFieldValue(fieldName, formattedValue);
};

export const calculateAmount = (hours, minutes, hourlyRate) => {
  const totalHours = hours + minutes / 60;
  return totalHours * hourlyRate;
};

export const copyToClipboard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Copied to clipboard successfully!');
        ToastBar.success('Copied');
      })
      .catch((err) => {
        ToastBar.error('Failed to copy to clipboard');
        console.error('Failed to copy to clipboard:', err);
      });
  } else {
    let textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      let successful = document.execCommand('copy');
      if (successful) {
        ToastBar.success('Copied');
      } else {
        ToastBar.error('Failed to copy to clipboard');
      }
      console.log(successful ? 'Copied to clipboard successfully!' : 'Failed to copy to clipboard.');
    } catch (err) {
      ToastBar.error('Failed to copy to clipboard');
      console.error('Fallback: Failed to copy to clipboard:', err);
    }
    document.body.removeChild(textArea);
  }
};
