export const ApproveTimesheetModal = ({ onCancel, onConfirm }) => {
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
        <div className="bg-white flex flex-col w-[600px] p-12 rounded-lg shadow-lg justify-center items-center">
          <h2 className="text-base font-bold text-green1">Do you want to approve the timesheet application(s)?</h2>
          <h1 className="text-sm text-black">Once approved, the action cannot be recovered.</h1>
          <div className="flex gap-5 mt-5">
            <button
              type="button"
              onClick={() => onCancel()}
              className="bg-red1 hover:bg-red1/90 text-white text-sm font-bold h-12 w-32 rounded-xl"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => onConfirm()}
              className="bg-purple1 hover:bg-purple1/90 text-white text-sm font-bold h-12 w-32 rounded-xl"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
