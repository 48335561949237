import { useEffect, useState, useMemo } from 'react';
import { approveTimesheet, rejectTimesheet } from '../../../actions/timesheetAction';
import { getAllCompanies, getCompanyById } from '../../../actions/companyAction';
import { fetchPendingTimesheets } from '../../../actions/employeeAction';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import empty from '../../../assets/images/empty.png';
import { format } from 'date-fns';
import { ApproveTimesheetModal } from './approveTimesheetModal';
import { RejectTimesheetModal } from './rejectTimesheetModal';
import Layout from '../../../layout/layout';
import defaultPic from '../../../assets/images/Default_profilepic.png';
import { Eye } from 'iconsax-react';
import TimesheetViewModal from '../../../components/timesheetViewModal';
import Dropdown from '../../../components/dropdown';
import { getPendingTimesheets } from '../../../actions/timesheetAction';
import { ToastBar } from '../../../components/toastbar';

const TimesheetApplications = () => {
  const dispatch = useDispatch();
  const pendingTimesheets = useSelector((state) => state?.employee?.pendingTimesheets) || [];

  const [sortConfig, setSortConfig] = useState({
    key: 'createdDate',
    direction: 'descending'
  });
  const [selectedTimesheets, setSelectedTimesheets] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);

  const companies = useSelector((state) => state.company?.companies || []);
  const company = useSelector((state) => state.company?.company);

  useEffect(() => {
    dispatch(getAllCompanies());
    dispatch(fetchPendingTimesheets([]));
  }, [dispatch]);

  useEffect(() => {
    if (company?._id) {
      dispatch(getPendingTimesheets(company?._id));
    }
  }, [company, dispatch]);

  const sortedPendingTimesheets = useMemo(() => {
    if (!pendingTimesheets.length) {
      return [];
    }

    let sortableTimesheets = pendingTimesheets.flatMap((leave) =>
      leave.pendingTimesheets.map((pendingLeave) => ({
        ...pendingLeave,
        employee: leave.employee
      }))
    );

    sortableTimesheets.sort((a, b) => {
      const fieldA = sortConfig.key.split('.').reduce((obj, key) => obj[key], a);
      const fieldB = sortConfig.key.split('.').reduce((obj, key) => obj[key], b);

      const valueA = typeof fieldA === 'string' ? fieldA.toLowerCase() : String(fieldA || '');
      const valueB = typeof fieldB === 'string' ? fieldB.toLowerCase() : String(fieldB || '');

      if (sortConfig.direction === 'ascending') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

    return sortableTimesheets;
  }, [pendingTimesheets, sortConfig]);

  useEffect(() => {
    if (selectedTimesheets.length === sortedPendingTimesheets.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedTimesheets, sortedPendingTimesheets]);

  const handleCompanyChange = (selectedOption) => {
    const selectedValue = selectedOption?.value || '';
    if (selectedValue) {
      // setSelectedCompany(selectedValue);
      // setCompanyId(selectedOption?.companyId);
      dispatch(getCompanyById({ companyId: selectedOption?.companyId }));
    }
  };

  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        companyId: company?._id,
        label: company?.companyName,
        value: company?.companyName
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  const handleCheckboxChange = (leave) => {
    setSelectedTimesheets((prevSelected) =>
      prevSelected.includes(leave) ? prevSelected.filter((selected) => selected !== leave) : [...prevSelected, leave]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedTimesheets([]);
    } else {
      setSelectedTimesheets(sortedPendingTimesheets);
    }
    setSelectAll(!selectAll);
  };

  const handleApproveTimesheets = () => {
    setShowApproveModal(true);
  };

  const handleRejectTimesheets = () => {
    if (selectedTimesheets?.length > 1) {
      ToastBar.warning('Only Reject 1 application at a time')
    } else {
      setShowRejectModal(true);
    }
  };

  const confirmApproveTimesheet = () => {
    console.log(selectedTimesheets);
    dispatch(approveTimesheet(selectedTimesheets, company?._id));
    setShowApproveModal(false);
    setSelectedTimesheets([]);
    setSelectAll(false);
  };

  const onChangeReason = (rejectReason) => {
    const updatedTimesheets = [...selectedTimesheets];
  
    if (updatedTimesheets.length > 0) {
      updatedTimesheets[0] = {
        ...updatedTimesheets[0],
        rejectReason: rejectReason,
      };
    }
  
    setSelectedTimesheets(updatedTimesheets);
  };

  const confirmRejectTimesheet = () => {
    console.log(selectedTimesheets);
    dispatch(rejectTimesheet(selectedTimesheets, company?._id));
    setShowRejectModal(false);
    setSelectedTimesheets([]);
    setSelectAll(false);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return { label: 'Approved', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 4:
        return { label: 'Processing', bgColor: 'bg-green-500', textColor: 'text-green-500' };
      case 2:
        return { label: 'Pending', bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
      case 3:
        return { label: 'Cancelled', bgColor: 'bg-gray-500', textColor: 'text-gray-500' };
      case 5:
        return { label: 'Confirmed', bgColor: 'bg-blue-500', textColor: 'text-blue-500' };
      default:
        return { label: 'Rejected', bgColor: 'bg-red-500', textColor: 'text-red-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedPendingTimesheets.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedPendingTimesheets.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  console.log('currentEntries: ', currentEntries)

  return (
    <Layout pageTitle={'Pending Timesheets'} page={'Employees'} subPage={'Pending Timesheets'}>
      <div className="p-6 bg-white flex flex-col justify-between rounded-2xl h-full overflow-y-auto">
        <div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-4">
            <div className="w-full lg:w-1/2">
              {companyOptions.length > 0 && (
                <Dropdown
                  value={companyOptions?.find((option) => option.value === company?.companyName)}
                  onChange={handleCompanyChange}
                  options={companyOptions}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Select Company"
                  name="company"
                  id="company"
                />
              )}
            </div>
            <div className="flex flex-row justify-end items-end gap-x-3 pb-5">
              <button
                type="button"
                onClick={handleApproveTimesheets}
                className="h-12 w-48 bg-green1 hover:bg-green1/90 text-white font-semibold rounded-md cursor-pointer disabled:bg-gray-400"
                disabled={selectedTimesheets.length === 0 || sortedPendingTimesheets.length < 0}
              >
                Approve Timesheets
              </button>
              <button
                type="button"
                onClick={handleRejectTimesheets}
                className="h-12 w-40 bg-red1 hover:bg-red1/90 text-white font-semibold rounded-md cursor-pointer disabled:bg-gray-400"
                disabled={selectedTimesheets.length === 0 || sortedPendingTimesheets.length < 0}
              >
                Reject Timesheets
              </button>
            </div>
          </div>
          {sortedPendingTimesheets.length > 0 ? (
            <div>
              <div className="flex justify-end items-center  my-5">
                <div className="flex items-center font-semibold text-black">
                  <label htmlFor="entriesPerPage" className="mr-2">
                    Show
                  </label>
                  <select
                    id="entriesPerPage"
                    value={entriesPerPage}
                    onChange={handleEntriesPerPageChange}
                    className="border border-gray-300 rounded-md p-1 pl-3"
                  >
                    {[5, 10, 20, 50].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                  <span className="ml-2">Records</span>
                </div>
              </div>
              <table className="table-auto rounded-2xl w-full">
                <thead>
                  <tr className="h-20 bg-grayLight text-black rounded-xl">
                    <th className="h-20 text-center w-10 rounded-s-xl">
                      <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />
                    </th>
                    <th className="h-20 text-center w-16 rounded-s-xl">Photo</th>
                    <th>Full Name</th>
                    <th>Designation</th>
                    <th>Department</th>
                    <th onClick={() => handleSort('createdDate')} className="cursor-pointer">
                      Created Date <FontAwesomeIcon icon={getSortIcon('createdDate')} size="sm" className="ml-1" />
                    </th>
                    <th onClick={() => handleSort('status')} className="cursor-pointer px-4">
                      Status <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
                    </th>
                    <th className="rounded-e-xl">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries?.map((timesheet, index) => {
                    const formattedCreatedDate = !Number.isNaN(new Date(timesheet?.createdDate).getTime())
                      ? format(new Date(timesheet?.createdDate), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date';

                    const { label, textColor, bgColor } = getStatusLabel(timesheet?.status);

                    console.log("console.log(timesheet)", timesheet)

                    return (
                      <tr className={`h-20 text-sm text-black`} key={index}>
                        <td className="h-20 text-center">
                          <input
                            type="checkbox"
                            checked={selectedTimesheets.includes(timesheet)}
                            onChange={() => handleCheckboxChange(timesheet)}
                          />
                        </td>
                        <td className="text-sm font-nunito font-semibold leading-normal text-black">
                          <div className="flex justify-center items-center h-20">
                            <img
                              src={timesheet?.employee?.avatar ? timesheet?.employee?.avatar : defaultPic}
                              alt="profile pic"
                              className="w-10 h-10 rounded-full object-cover"
                            />
                          </div>
                        </td>
                        <td className="h-20 text-center">
                          {timesheet?.employee?.fullName}
                          {/* {leave?.employee?.fullName?.slice(0, 12)}
                          {leave?.employee?.fullName?.length > 12 ? '...' : ''} */}
                        </td>
                        <td className="h-20 text-center">{timesheet?.employee?.employmentDetails?.designationName}</td>
                        <td className="h-20 text-center">{timesheet?.employee?.employmentDetails?.departmentName}</td>
                        <td className="h-20 text-center">{formattedCreatedDate}</td>
                        <td className="h-20 w-26">
                          <div className="flex justify-center">
                            <div className="bg-grayLight flex items-center justify-center w-fit gap-x-1 px-1 rounded-full">
                              <div className={`w-2.5 h-2.5 rounded-full ${bgColor}`}></div>
                              <span className={`text-xs ${textColor}`}> {label}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex justify-center gap-x-2 items-center">
                            <div
                              className="flex justify-center items-center w-8 h-8 p-1.5 rounded-md hover:bg-blue-400 shadow-md"
                              onClick={() => setIsViewModalOpen(timesheet)}
                              title="View"
                            >
                              <Eye color="#6b7280" variant="Bold" size={20} className="cursor-pointer" />
                            </div>
                          </div>
                        </td>
                        <TimesheetViewModal
                          isOpen={isViewModalOpen}
                          onClose={() => setIsViewModalOpen(false)}
                          employee={isViewModalOpen?.employee}
                          timesheet={isViewModalOpen}
                        />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="w-full min-h-96 flex flex-col justify-center items-center rounded-xl">
              <img src={empty} alt="empty data" />
              <div className="mt-5 text-purple1 font-bold text-xl">No Pending Timesheets</div>
            </div>
          )}
        </div>
        {currentEntries.length > 0 && (
          <div className="flex">
            <div className="flex flex-1 items-center justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedPendingTimesheets.length)}
                  </span>{' '}
                  records
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === 1}
                  >
                    <span className="">{'<'}</span>
                  </button>
                  <div>
                    {pageNumbers?.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`relative items-center w-10 h-10 text-base text-center ${pageNumber === currentPage
                          ? 'bg-purple1 text-white rounded-xl font-bold'
                          : 'text-black bg-oppty-green-5'
                          }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    className="relative text-center border w-10 h-10 text-black/60 rounded-xl font-normal text-xl"
                    disabled={currentPage === totalPages}
                  >
                    <span className="">{'>'}</span>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}

        {showApproveModal && (
          <ApproveTimesheetModal onCancel={() => setShowApproveModal(false)} onConfirm={confirmApproveTimesheet} />
        )}
        {showRejectModal && (
          <RejectTimesheetModal onCancel={() => setShowRejectModal(false)} onConfirm={confirmRejectTimesheet} selectedTimesheet={selectedTimesheets[0]} onChangeReason={onChangeReason}/>
        )}
      </div>
    </Layout >
  );
};

export default TimesheetApplications;
