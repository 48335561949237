import React from 'react';

const TreeNodeComponent = ({ label, imageSrc, status }) => {
  const officeStatus = () => {
    if (!status) {
      return 'bg-red-500';
    }

    switch (status) {
      case 'Available':
        return 'bg-green-500';
      case 'Out of Office':
        return 'bg-orange-500';
      default:
        return 'bg-red-500';
    }
  };

  return (
    <div className="flex flex-col items-center p-2">
      <div className="w-16 h-16 mb-2 relative">
        <img src={imageSrc} alt={label} className="w-full h-full rounded-full object-cover" />
        <div className={`absolute border-[1px] h-4 w-4 ${officeStatus()} rounded-full bottom-0 right-1`}></div>
      </div>
      <div className="text-center text-base font-medium text-black">{label}</div>
    </div>
  );
};

export default TreeNodeComponent;
