import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { fetchPendingNoPayLeaves, getAllEmployees, getCurrentEmployee, getEmployee } from './employeeAction';
import { createApplyNoPayLeaveNotification, createApproveNoPayLeaveNotification, createCancelNoPayLeaveNotification, createRejectNoPayLeaveNotification } from './notificationAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const addNoPayLeave = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/add-no-pay-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateNoPayLeave = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/update-no-pay-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteNoPayLeave = (inputData, setIsConfirmationModalOpen) => {
  console.log('inputData: ', inputData)
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/delete-no-pay-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
        setIsConfirmationModalOpen(false);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
        setIsConfirmationModalOpen(false);
      }
    }
  };
};

export const applyNoPayLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/apply-no-pay-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        dispatch(createApplyNoPayLeaveNotification(inputValue, response.data.noPayLeaveHistoryId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const editNoPayLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/edit-no-pay-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelNoPayLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/cancel-no-pay-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        dispatch(createCancelNoPayLeaveNotification(inputValue))
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const getPendingNoPayLeaves = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/nopayleave/get-pending-no-pay-leaves`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingleaves res: ', response.data);
        dispatch(fetchPendingNoPayLeaves(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingNoPayLeavesByApprover = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/nopayleave/get-pending-no-pay-leaves-by-approver`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingleaves res: ', response.data);
        dispatch(fetchPendingNoPayLeaves(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveNoPayLeave = (leaves, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/approve-no-pay-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingNoPayLeaves(companyId));
        dispatch(createApproveNoPayLeaveNotification(leaves));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectNoPayLeave = (leaves, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/reject-no-pay-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingNoPayLeaves(companyId));
        dispatch(createRejectNoPayLeaveNotification(leaves));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveNoPayLeaveByApprover = (leaves) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/approve-no-pay-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingNoPayLeavesByApprover());
        dispatch(createApproveNoPayLeaveNotification(leaves));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectNoPayLeaveByApprover = (leaves) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/reject-no-pay-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingNoPayLeavesByApprover());
        dispatch(createRejectNoPayLeaveNotification(leaves));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const applyNoPayLeaveByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/apply-no-pay-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('check res: ', response.data.noPayLeaveHistoryId);
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        dispatch(createApplyNoPayLeaveNotification(inputValue, response.data.noPayLeaveHistoryId));
        ToastBar.success(response.data.successMsg[0]);
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateNoPayLeaveByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/edit-no-pay-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelNoPayLeaveByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/nopayleave/cancel-no-pay-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        dispatch(createCancelNoPayLeaveNotification(inputValue))
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};