import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { fetchPendingLeaves, getAllEmployees, getCurrentEmployee, getEmployee } from './employeeAction';
import { createApplyLeaveNotification, createApproveLeaveNotification, createCancelLeaveNotification, createRejectLeaveNotification } from './notificationAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const addLeave = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/add-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateLeave = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/update-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteLeave = (inputData, setIsConfirmationModalOpen) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/delete-leave`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
        setIsConfirmationModalOpen(false);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
        setIsConfirmationModalOpen(false);
      }
    }
  };
};

export const applyLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/apply-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        dispatch(createApplyLeaveNotification(inputValue, response.data.leaveHistoryId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const editLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/edit-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelLeave = (inputValue) => {
  console.log('inputValue: ', inputValue)

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/cancel-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getEmployee(inputValue?.employeeId));
        dispatch(createCancelLeaveNotification(inputValue))
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const getPendingLeaves = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/leave/get-pending-leaves`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingleaves res: ', response.data);
        dispatch(fetchPendingLeaves(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getPendingLeavesByApprover = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/leave/get-pending-leaves-by-approver`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('pendingleaves res: ', response.data);
        dispatch(fetchPendingLeaves(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const applyLeaveByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/apply-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('check res: ', response.data.leaveHistoryId);
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        dispatch(createApplyLeaveNotification(inputValue, response.data.leaveHistoryId));
        ToastBar.success(response.data.successMsg[0]);
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateLeaveByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/edit-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const cancelLeaveByUser = (inputValue) => {
  console.log('inputValue: ', inputValue);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/cancel-leave`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getCurrentEmployee(inputValue?.employeeId));
        dispatch(createCancelLeaveNotification(inputValue))
        return { success: true };
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { success: false };
    }
  };
};

export const approveLeave = (leaves, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/approve-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingLeaves(companyId));
        dispatch(createApproveLeaveNotification(leaves));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectLeave = (leaves, companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/reject-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingLeaves(companyId));
        dispatch(createRejectLeaveNotification(leaves));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const approveLeaveByApprover = (leaves) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/approve-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingLeavesByApprover());
        dispatch(createApproveLeaveNotification(leaves));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const rejectLeaveByApprover = (leaves) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/leave/reject-leave`, leaves, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getPendingLeavesByApprover());
        dispatch(createRejectLeaveNotification(leaves));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};