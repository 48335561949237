import axios from 'axios';
import { ToastBar } from '../components/toastbar';
import { createApproveLeaveNotification, createApproveMedicalLeaveNotification, createApproveNoPayLeaveNotification, createApproveTimesheetNotification, createRejectLeaveNotification, createRejectMedicalLeaveNotification, createRejectNoPayLeaveNotification, createRejectTimesheetNotification } from './notificationAction';
import { getPendingLeaves, getPendingLeavesByApprover } from './leaveAction';
import { getPendingMedicals, getPendingMedicalsByApprover } from './medicalAction';
import { getPendingTimesheets } from './timesheetAction';

const apiUrl = process.env.REACT_APP_APIURL;

export const FETCH_ALL_EMPLOYEES = 'FETCH_ALL_EMPLOYEES';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_SEARCH_EMPLOYEES = 'FETCH_SEARCH_EMPLOYEES';
export const FETCH_PENDING_LEAVES = 'FETCH_PENDING_LEAVES';
export const FETCH_APPROVER_MANAGERS = 'FETCH_APPROVER_MANAGERS';
export const FETCH_CURRENT_EMPLOYEE = 'FETCH_CURRENT_EMPLOYEE';
export const FETCH_PENDING_MEDICALS = 'FETCH_PENDING_MEDICALS';
export const FETCH_PENDING_TIMESHEETS = 'FETCH_PENDING_TIMESHEETS';
export const FETCH_PENDING_NO_PAY_LEAVES = 'FETCH_PENDING_NO_PAY_LEAVES';

export const fetchCurrentEmployee = (data) => ({
  type: FETCH_CURRENT_EMPLOYEE,
  payload: data
});

export const fetchEmployeeSuccess = (data) => ({
  type: FETCH_EMPLOYEE_SUCCESS,
  payload: data
});

export const fetchAllEmployees = (data) => ({
  type: FETCH_ALL_EMPLOYEES,
  payload: data
});

export const fetchSearchEmployees = (data) => ({
  type: FETCH_SEARCH_EMPLOYEES,
  payload: data
});

export const fetchPendingLeaves = (data) => ({
  type: FETCH_PENDING_LEAVES,
  payload: data
})

export const fetchPendingNoPayLeaves = (data) => ({
  type: FETCH_PENDING_NO_PAY_LEAVES,
  payload: data
})

export const fetchPendingMedicals = (data) => ({
  type: FETCH_PENDING_MEDICALS,
  payload: data
})

export const fetchPendingTimesheets = (data) => ({
  type: FETCH_PENDING_TIMESHEETS,
  payload: data
})

export const fetchApproverManagers = (data) => ({
  type: FETCH_APPROVER_MANAGERS,
  payload: data
})

export const addEmployee = (inputData, navigate, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/add-employee`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('add employee api res: ', response.data);

        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        //dispatch(fetchAllEmployees(response.data));
        resetForm();

        if (response.data && response.data?.employee?._id) {
          navigate(`/employees/${response.data?.employee?._id}`, { state: { isNewEmployee: true } })
        } else {
          console.log('New employee not found in the response');
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const addBulkEmployee = (inputData, companyId) => {
  const inputValue = {
    companyId: companyId,
    employees: inputData
  }
  console.log('inputValue: ', inputValue);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/add-bulk-employee`, inputValue, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: companyId }));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editPersonalDetails = (inputData, setEdit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-personal-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editEmploymentDetails = (inputData, setEdit) => {
  console.log(inputData.companyId)
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-employment-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        dispatch(getApprovers(inputData.departmentCode, inputData.companyId));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editSalaryDetails = (inputData, setEdit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-salary-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editCredentialDetails = (inputData, setEdit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-credential-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        // dispatch(getAllEmployees());
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editApprovingDetails = (inputData, setEdit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-approving-details`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(getAllEmployees());
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getAllEmployees = (inputData) => {
  console.log(inputData)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-all-employees`, {
        params: inputData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('api res: ', response.data);
        dispatch(fetchAllEmployees(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getEmployeeById = (inputData, setEmployee) => {
  return async () => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-employee-byid`, {
        params: inputData,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('getbyId res: ', response.data);
        setEmployee(response.data?.employee);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const addClaim = (inputData, setEdit, resetForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/add-claim`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        console.log('api res: ', response.data);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        resetForm();
        setEdit(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateClaim = (inputData, onClose) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/update-claim`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        //dispatch(fetchAllEmployees(response.data));
        onClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteClaim = (inputData, setIsConfirmationModalOpen) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/claim/delete-claim`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('api res: ', response.data);
        ToastBar.success(response.data.successMsg[0]);
        //dispatch(fetchAllEmployees(response.data));
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        setIsConfirmationModalOpen(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const searchEmployees = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/search-employees`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('search-employees: ', response.data);
        dispatch(fetchSearchEmployees(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const searchHourlyEmployees = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/search-hourly-employees`, {
        params: { companyId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('search-employees: ', response.data);
        dispatch(fetchSearchEmployees(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getCurrentEmployee = (employeeId) => {
  console.log(employeeId)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-employee-byid`, {
        params: { employeeId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('getbyId res: ', response.data);
        dispatch(fetchCurrentEmployee(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getEmployee = (employeeId) => {
  console.log(employeeId)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-employee-byid`, {
        params: { employeeId },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('getbyId res: ', response.data);
        dispatch(fetchEmployeeSuccess(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const editEmployeeProfilePic = (values, closeModal, companyId) => {
  const inputData = values;

  console.log('input value for employee: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-profile-pic`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getAllEmployees(companyId));
        closeModal();
        ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateEmployeeWorkingHourDetails = (employeeId, companyId, values, setEdit) => {
  console.log(employeeId)
  const inputData = values;

  console.log('input value for employee: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/edit-working-hours`, { employeeId, companyId, ...inputData }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getAllEmployees());
        setEdit(false);
        ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getApprovers = (departmentCode, companyId, employeeId, employeeRole) => {
  console.log("departmentCode ", departmentCode)
  console.log("companyId ", companyId)
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employee/get-approvers`, {
        params: { departmentCode, companyId, employeeId, employeeRole},
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('approvers res: ', response.data);
        dispatch(fetchApproverManagers(response.data));
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateAccessControl = (values) => {
  console.log('input values: ', values);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/update-access-control`, values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      console.log('api res: ', response.data);

      if (response.status === 200) {
        ToastBar.success(response.data.successMsg[0]);
        //dispatch
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateUserOfficeStatus = (values, userId) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/update-user-office-status`, values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('check res: ', response.data);
        dispatch(getCurrentEmployee(userId))
        ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const stopEmployee = (inputData) => {
  console.log('inputData: ', inputData);
  
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employee/stop-employee`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        dispatch(getAllEmployees({ companyId: inputData.companyId }));
        ToastBar.success(response.data.successMsg[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[0]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};