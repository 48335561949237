import { useState } from "react";

export const RejectNoPayLeaveModal = ({ onCancel, onConfirm, selectedLeave, onChangeReason }) => {
  const [touch, setTouch] = useState(false);

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-[9999]">
        <div className="bg-white flex flex-col w-full md:w-[600px] p-12 rounded-lg shadow-lg justify-center items-center">
          <h2 className="text-base font-bold text-red1 text-center">Do you want to reject the no pay leave application(s)?</h2>
          <h1 className="text-sm text-black text-center">Once reject, the action cannot be recovered.</h1>
          <div className="w-full md:w-3/4 mt-5">
            <label className="mb-2.5 block text-black font-bold">Reason</label>
            <textarea
              type="text"
              rows={6}
              placeholder="Reject Reason"
              className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
              name="rejectReason"
              onChange={(e) => onChangeReason(e.target.value)}
              value={selectedLeave?.rejectReason || ''}
            />
            {touch && !selectedLeave?.rejectReason ? (
              <div className="text-red-500 text-sm">Reason is required</div>
            ) : null}
          </div>
          <div className="flex gap-5 mt-5">
            <button
              type="button"
              onClick={() => onCancel()}
              className="bg-red1 hover:bg-red1/90 text-white text-sm font-bold h-12 w-32 rounded-xl"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                setTouch(true);
                if (selectedLeave?.rejectReason) {
                  onConfirm();
                }
              }}
              className="bg-purple1 hover:bg-purple1/90 text-white text-sm font-bold h-12 w-32 rounded-xl"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
