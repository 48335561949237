import { FETCH_NOTIFICATIONS_SUCCESS, MARK_AS_READ_SUCCESS } from '../actions/notificationAction';

const initialState = {
  notifications: []
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      return action.payload;

    case MARK_AS_READ_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification._id === action.payload._id ? action.payload : notification
        )
      };

    default:
      return state;
  }
};

export default notificationsReducer;
