import { useFormik } from 'formik';
import { employeeLeaveSchema } from '../../../validations/validationSchemas';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../components/dropdown';
import { updateLeave } from '../../../actions/leaveAction';
import { useEffect } from 'react';
import { yesOrNoOptions } from '../../../constant/dropdownConstOptions';

const EditLeave = ({ leave, leaveOptions, onClose, employee }) => {
  console.log('leave: ', leave)
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      _id: leave?._id,
      leaveId: leave?.leaveId || '',
      leaveType: leave?.leaveType || '',
      leaveCode: leave?.leaveCode || '',
      totalDays: leave?.totalDays || '',
      balanceDays: leave?.balanceDays || '',
      takenDays: leave?.takenDays || '',
      employeeId: employee?._id,
      companyId: employee?.company?._id,
      isCarryForward: leave?.isCarryForward || 0,
      carryForwardMaxDays: leave?.carryForwardMaxDays || 0,
      status: leave?.status
    },
    validationSchema: employeeLeaveSchema,
    onSubmit: async (values) => {
      console.log('update value: ', values);
      dispatch(updateLeave(values, onClose));
    }
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      takenDays: 0
    })
  }, [])

  return (
    <div className="rounded-sm border border-transparent w-full">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-6">
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Leave Type</label>
              <Dropdown
                value={leaveOptions.find((option) => option.leaveType === formik.values.leaveType)}
                onChange={(selectedOption) => {
                  const selectedValue = selectedOption || '';
                  if (selectedValue) {
                    formik.setValues({
                      ...formik.values,
                      leaveType: selectedValue.value,
                      leaveCode: selectedValue.leaveCode,
                      leaveId: selectedValue.leaveId
                    });
                  }
                }}
                options={leaveOptions}
                isClearable={false}
                placeholder="Select Leave"
                name="allowanceType"
                id="allowanceType"
                isDisabled
              />
              {formik.touched.leaveType && formik.errors.leaveType ? (
                <div className="text-red-500 text-sm">{formik.errors.leaveType}</div>
              ) : null}
            </div>
            <div className="w-full lg:w-1/2">
              <label className="mb-2.5 block text-black font-bold">Total Days</label>
              <input
                type="number"
                placeholder="Total Days"
                className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                name="totalDays"
                onChange={(e) =>
                  formik.setValues({
                    ...formik.values,
                    totalDays: e.target.value,
                    balanceDays: e.target.value,
                    takenDays: 0
                  })
                }
                value={formik.values.totalDays}
              />
              {formik.touched.totalDays && formik.errors.totalDays ? (
                <div className="text-red-500 text-sm">{formik.errors.totalDays}</div>
              ) : null}
            </div>
          </div>
          <div className="mb-5 flex flex-col gap-6 lg:flex-row">
                  <div className="w-full lg:w-1/2">
                    <label className="mb-2.5 block text-black font-bold">Carry Forward?</label>
                    <Dropdown
                      value={yesOrNoOptions.find((option) => option.value === formik.values.isCarryForward)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption ? selectedOption.value : 0;

                        if(selectedValue === 0) {
                          formik.setFieldValue('carryForwardMaxDays', 0);
                        }
                        formik.setFieldValue('isCarryForward', selectedValue);
                      }}
                      options={yesOrNoOptions}
                      isClearable={false}
                      placeholder="Is Carry Forward?"
                      name="isCarryForward"
                      id="isCarryForward"
                    />
                    {formik.touched.isCarryForward && formik.errors.isCarryForward ? (
                      <div className="text-red-500 text-sm">{formik.errors.isCarryForward}</div>
                    ) : null}
                  </div>
                  {formik.values.isCarryForward === 1 ? (
                    <div className="w-full lg:w-1/2">
                      <label className="mb-2.5 block text-black font-bold">Maximum Days</label>
                      <input
                        type="number"
                        placeholder="Carry Forward Maximum Days"
                        className="w-full rounded-xl text-sm border-[1.5px] border-transparent bg-grayLight py-3 px-5 text-black disabled:text-gray2 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
                        name="carryForwardMaxDays"
                        onChange={formik.handleChange}
                        value={formik.values.carryForwardMaxDays}
                      />
                      {formik.touched.carryForwardMaxDays && formik.errors.carryForwardMaxDays ? (
                        <div className="text-red-500 text-sm">{formik.errors.carryForwardMaxDays}</div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="w-full lg:w-1/2"></div>
                  )}
                </div>
          
          <div className="flex justify-end w-full gap-x-2">
            <button
              type="button"
              onClick={() => onClose()}
              className="flex w-28 justify-center items-center rounded-xl bg-red1 h-10 text-sm font-bold text-white hover:bg-red1/90"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-36 h-10 justify-center items-center rounded-xl bg-purple1 text-sm font-bold text-white hover:bg-purple1/90"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditLeave;
